import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Router } from '@angular/router';
import { MerchantModel } from '../../models/merchant/merchant.model';
import { MerchantService } from '../../services';
import { GetMerchantAction, ScopeMerchantAction, SelectMerchantAction } from '../actions/merchant.action';
import { catchError, tap, throwError } from 'rxjs';

export class MerchantStateModel {
  public selectedMerchant: MerchantModel;
  public hasEcommerce: boolean;
  public hasRecurring: boolean;
}

@State<MerchantStateModel>({
  name: 'merchant',
  defaults: {
    selectedMerchant: null,
    hasEcommerce: false,
    hasRecurring: false
  }
})

@Injectable()
export class MerchantState {

  constructor(private _merchantService: MerchantService,
    private _router: Router) {
  }

  @Selector()
  public static selectedMerchant(state: MerchantStateModel) {
    return state.selectedMerchant;
  }

  @Selector()
  public static selectedMerchantScope(state: MerchantStateModel) {
    return {
      hasEcommerce: state.hasEcommerce,
      hasRecurring: state.hasRecurring
    };
  }

  @Action(SelectMerchantAction)
  public selectMerchantAction(ctx: StateContext<MerchantStateModel>, action: SelectMerchantAction) {
    ctx.patchState({ selectedMerchant: action.data });
  }

  @Action(GetMerchantAction)
  public getMerchantAction(ctx: StateContext<MerchantStateModel>, action: GetMerchantAction) {
    return this._merchantService.getMerchantDetails().pipe(
      tap((data) => {
        ctx.patchState({
          selectedMerchant: data,
          hasEcommerce: data?.enableEcommerce ?? false,
          hasRecurring: data?.enableRecurring ?? false
        });
      }),
      catchError((error) => {
        console.error("Failed to get merchant details:", error);
        return throwError(() => error);
      })
    );
  }

  @Action(ScopeMerchantAction)
  public scopeMerchantAction(ctx: StateContext<MerchantStateModel>, action: ScopeMerchantAction) {
    ctx.patchState({ hasEcommerce: action.hasEcommerce ?? false });
  }

}
