import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { MerchantModel } from 'src/app/core/models/merchant/merchant.model';
import { MerchantService } from 'src/app/core/services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-merchant',
  templateUrl: './update-merchant.component.html',
  styleUrls: ['./update-merchant.component.scss']
})
export class UpdateMerchantComponent {
  public merchantDetailsForm: UntypedFormGroup;
  public merchantRates: UntypedFormGroup;
  public bankSetup: UntypedFormGroup;
  public gatewaySetup: UntypedFormGroup;
  public collectionsSettings: UntypedFormGroup;
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public modelFooter: NgbDateStruct;
  public active = 1;

  public isLoading: boolean = false;

  collectionRunTime = { hour: 13, minute: 30 };
  collectionResubmissionRunTime = { hour: 14, minute: 30 };
  meridian = true;

  public merchantModel: MerchantModel;
  public merchantId: number;

  constructor(private formBuilder: UntypedFormBuilder, private calendar: NgbCalendar,
    public merchantService: MerchantService,
    private _router: Router,
    private spinner: NgxSpinnerService) {
    const receivedMerchant = this._router.getCurrentNavigation()?.extras.state?.data;
    const receivedMerchantId = this._router.getCurrentNavigation()?.extras.state?.merchantId;
    if (receivedMerchant && receivedMerchantId) {
      this.merchantId = receivedMerchantId;
      this.merchantModel = receivedMerchant;
      this.createMerchantForm(receivedMerchant);
      this.createMerchantRatesForm(receivedMerchant);
      this.createMerchantBankSetupForm(receivedMerchant);
      this.createMerchantGatewayForm(receivedMerchant);
      this.createMerchantCollectionSettingsForm(receivedMerchant);
    }
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  createMerchantForm(merchantModel: MerchantModel) {
    this.merchantDetailsForm = this.formBuilder.group({
      merchantName: [merchantModel.merchantName],
      merchantVat: [merchantModel.merchantVat],
      merchantRegistration: [merchantModel.merchantRegistration],
      merchantAddress: [merchantModel.merchantAddress],
      accountContactName: [merchantModel.accountContactName],
      accountContactNumber: [merchantModel.accountContactNumber],
      accountContactMobile: [merchantModel.accountContactMobile],
      accountContactEmail: [merchantModel.accountContactEmail],
      merchantBankMid: [merchantModel.merchantBankMid],
      merchantSetupType: [merchantModel.merchantSetupType],
      merchantSettleUsersDirectly: [merchantModel.merchantSettleUsersDirectly],
      merchantRequiresBatchSettlementReport: [merchantModel.merchantRequiresBatchSettlementReport],
      merchantSetupFeePaid: [merchantModel.merchantSetupFeePaid],
      merchantSettlementsSuspended: [merchantModel.merchantSettlementsSuspended],
      merchantPaysByDebit: [merchantModel.merchantPaysByDebit],
      merchantSendSmsOnline: [merchantModel.merchantSendSmsOnline],
      bankName: [merchantModel.settlementBankAccount?.bankName || ''], // Default values or use a property if available
      accountNumber: [merchantModel.settlementBankAccount?.accountNumber || ''],
      accountType: [merchantModel.settlementBankAccount?.accountType || ''],
      branchCode: [merchantModel.settlementBankAccount?.branchCode || ''],
      title: [merchantModel.rootUser?.title || ''],
      firstname: [merchantModel.rootUser?.firstname || ''],
      lastname: [merchantModel.rootUser?.lastname || ''],
      emailAddress: [merchantModel.rootUser?.emailAddress || ''],
      identityNumber: [merchantModel.rootUser?.identityNumber || ''],
      contactNumber1: [merchantModel.rootUser?.contactNumber1 || ''],
      code: ['']
    });

  }

  createMerchantRatesForm(merchantModel: MerchantModel) {
    this.merchantRates = this.formBuilder.group({
      setupFee: [merchantModel.merchantFeeRates?.setupFee || 0],
      subscriptionFee: [merchantModel.merchantFeeRates?.subscriptionFee || 0],
      posRentalFee: [merchantModel.merchantFeeRates?.posRentalFee || 0],
      smsFee: [merchantModel.merchantFeeRates?.smsFee || 0],
      userFee: [merchantModel.merchantFeeRates?.userFee || 0],
      tokenisationFee: [merchantModel.merchantFeeRates?.tokenisationFee || 0],
      refundFee: [merchantModel.merchantFeeRates?.refundFee || 0],
      riskScreeningFee: [merchantModel.merchantFeeRates?.riskScreeningFee || 0],
      recurringTransactionFee: [merchantModel.merchantFeeRates?.recurringTransactionFee || 0],
      authFee: [merchantModel.merchantFeeRates?.authorisationFee || 0], // Assuming this property exists
      visaCreditCardEcommerceRate: [merchantModel.merchantFeeRates?.visaCreditCardEcommerceRate || 0],
      visaDebitCardEcommerceRate: [merchantModel.merchantFeeRates?.visaDebitCardEcommerceRate || 0],
      mastercardCreditCardEcommerceRate: [merchantModel.merchantFeeRates?.mastercardCreditCardEcommerceRate || 0],
      mastercardDebitCardEcommerceRate: [merchantModel.merchantFeeRates?.mastercardDebitCardEcommerceRate || 0],
      amexCardEcommerceRate: [merchantModel.merchantFeeRates?.amexCardEcommerceRate || 0],
      dinersCardEcommerceRate: [merchantModel.merchantFeeRates?.dinersCardEcommerceRate || 0],
      visaCreditCardPosRate: [merchantModel.merchantFeeRates?.visaCreditCardPosRate || 0],
      visaDebitCardPosRate: [merchantModel.merchantFeeRates?.visaDebitCardPosRate || 0],
      mastercardCreditCardPosRate: [merchantModel.merchantFeeRates?.mastercardCreditCardPosRate || 0],
      mastercardDebitCardPosRate: [merchantModel.merchantFeeRates?.mastercardDebitCardPosRate || 0],
      amexCardPosRate: [merchantModel.merchantFeeRates?.amexCardPosRate || 0],
      dinersCardPosRate: [merchantModel.merchantFeeRates?.dinersCardPosRate || 0],
    });
  }

  createMerchantBankSetupForm(merchantModel: MerchantModel) {
    this.bankSetup = this.formBuilder.group({
      bankMerchantId: [merchantModel.ecommerceBankSetup?.bankMerchantId || ''],
      merchantType: [merchantModel.ecommerceBankSetup?.merchantType || 'TPPP'],
      merchantShortname: [merchantModel.ecommerceBankSetup?.merchantShortname || ''],
      subMerchantIdentifier: [merchantModel.ecommerceBankSetup?.subMerchantIdentifier || ''],
      mccCode: [merchantModel.ecommerceBankSetup?.mccCode || ''],
      posBankMerchantId: [merchantModel.posBankSetup?.bankMerchantId || ''],
      posMerchantType: [merchantModel.posBankSetup?.merchantType || 'ISO'],
      posMerchantShortname: [merchantModel.posBankSetup?.merchantShortname || ''],
      posSubMerchantIdentifier: [merchantModel.posBankSetup?.subMerchantIdentifier || ''],
      posMccCode: [merchantModel.posBankSetup?.mccCode || '']
    });
  }

  createMerchantGatewayForm(merchantModel: MerchantModel) {
    this.gatewaySetup = this.formBuilder.group({
      gateway: [merchantModel.merchantEcommerceGateway?.gateway || ''],
      gatewayUrl: [merchantModel.merchantEcommerceGateway?.gatewayUrl || ''],
      gatewayKey: [merchantModel.merchantEcommerceGateway?.gatewayKey || ''],
      gatewayToken: [merchantModel.merchantEcommerceGateway?.gatewayToken || ''],
      recurringGateway: [merchantModel.merchantRecurringGateway?.gateway || 'PEP'],
      recurringGatewayUrl: [merchantModel.merchantRecurringGateway?.gatewayUrl || ''],
      recurringGatewayKey: [merchantModel.merchantRecurringGateway?.gatewayKey || ''],
      recurringGatewayToken: [merchantModel.merchantRecurringGateway?.gatewayToken || ''],
    });
  }

  createMerchantCollectionSettingsForm(merchantModel: MerchantModel) {
    const settings = merchantModel.merchantCollectionSettings;

    if (settings) {
      this.collectionsSettings = this.formBuilder.group({
        collectionRunTime: [this.convertTimeStringToObject(settings.collectionRunTime) || this.collectionRunTime],
        collectionResubmissionRunTime: [this.convertTimeStringToObject(settings.collectionResubmissionRunTime) || this.collectionResubmissionRunTime],
        collectionFrequency: [settings.collectionFrequency || ''],
        collectionDay: [settings.collectionDay || null],
        collectionAutoRun: [settings.collectionAutoRun || false],
        collectionRerunAttempts: [settings.collectionRerunAttempts || ''],
        collectionAllowDynamicPayday: [settings.collectionAllowDynamicPayday || false],
        earlyDecemberCollection: [settings.earlyDecemberCollection || false],
        earlyDecemberDay: [settings.earlyDecemberDay || null]
      });

      this.collectionRunTime = this.convertTimeStringToObject(settings.collectionRunTime);
      this.collectionResubmissionRunTime = this.convertTimeStringToObject(settings.collectionResubmissionRunTime);
    }
  }

  convertTimeStringToObject(timeString: string): { hour: number; minute: number } | null {
    // Check for "N/A" or any other invalid format
    if (!timeString || timeString === "N/A") {
      return null; // or return a default value, like { hour: 0, minute: 0 }
    }

    const [hours, minutes] = timeString.split(':').map(Number);

    // Check if parsed values are valid
    if (isNaN(hours) || isNaN(minutes)) {
      return null; // Return null for invalid formats
    }

    return { hour: hours, minute: minutes };
  }

  private setUpdatedValue<T extends keyof MerchantModel>(
    originalValue: any,
    currentValue: any,
    target: Partial<MerchantModel>,
    propertyName: T
  ): void {
    if (originalValue !== currentValue) {
      target[propertyName] = currentValue;
    }
  }

  // Utility to check if a nested object has changed
  private hasNestedChanges(original: any, current: any): boolean {
    if (!original || !current) return original !== current;

    return Object.keys(current).some(
      key => current[key] !== original[key]
    );
  }

  // Utility to extract changes from a nested object
  private getUpdatedNestedObject(original: any, current: any): any {
    const updated: any = {};

    for (const key in current) {
      if (current.hasOwnProperty(key) && current[key] !== original[key]) {
        updated[key] = current[key];
      }
    }

    return updated;
  }

  private getUpdatedMerchant(): Partial<MerchantModel> {
    const updatedMerchant: Partial<MerchantModel> = {};

    // Compare flat properties
    this.setUpdatedValue(this.merchantModel.merchantName, this.merchantDetailsForm.get('merchantName')?.value, updatedMerchant, 'merchantName');
    this.setUpdatedValue(this.merchantModel.merchantVat, this.merchantDetailsForm.get('merchantVat')?.value, updatedMerchant, 'merchantVat');
    this.setUpdatedValue(this.merchantModel.merchantRegistration, this.merchantDetailsForm.get('merchantRegistration')?.value, updatedMerchant, 'merchantRegistration');
    this.setUpdatedValue(this.merchantModel.merchantAddress, this.merchantDetailsForm.get('merchantAddress')?.value, updatedMerchant, 'merchantAddress');
    this.setUpdatedValue(this.merchantModel.accountContactName, this.merchantDetailsForm.get('accountContactName')?.value, updatedMerchant, 'accountContactName');
    this.setUpdatedValue(this.merchantModel.accountContactNumber, this.merchantDetailsForm.get('accountContactNumber')?.value, updatedMerchant, 'accountContactNumber');
    this.setUpdatedValue(this.merchantModel.accountContactMobile, this.merchantDetailsForm.get('accountContactMobile')?.value, updatedMerchant, 'accountContactMobile');
    this.setUpdatedValue(this.merchantModel.accountContactEmail, this.merchantDetailsForm.get('accountContactEmail')?.value, updatedMerchant, 'accountContactEmail');
    this.setUpdatedValue(this.merchantModel.merchantBankMid, this.merchantDetailsForm.get('merchantBankMid')?.value, updatedMerchant, 'merchantBankMid');
    this.setUpdatedValue(this.merchantModel.merchantSetupType, this.merchantDetailsForm.get('merchantSetupType')?.value, updatedMerchant, 'merchantSetupType');
    this.setUpdatedValue(this.merchantModel.merchantSettleUsersDirectly, this.merchantDetailsForm.get('merchantSettleUsersDirectly')?.value, updatedMerchant, 'merchantSettleUsersDirectly');
    this.setUpdatedValue(this.merchantModel.merchantRequiresBatchSettlementReport, this.merchantDetailsForm.get('merchantRequiresBatchSettlementReport')?.value, updatedMerchant, 'merchantRequiresBatchSettlementReport');
    this.setUpdatedValue(this.merchantModel.merchantSetupFeePaid, this.merchantDetailsForm.get('merchantSetupFeePaid')?.value, updatedMerchant, 'merchantSetupFeePaid');
    this.setUpdatedValue(this.merchantModel.merchantSettlementsSuspended, this.merchantDetailsForm.get('merchantSettlementsSuspended')?.value, updatedMerchant, 'merchantSettlementsSuspended');
    this.setUpdatedValue(this.merchantModel.merchantPaysByDebit, this.merchantDetailsForm.get('merchantPaysByDebit')?.value, updatedMerchant, 'merchantPaysByDebit');
    this.setUpdatedValue(this.merchantModel.merchantSendSmsOnline, this.merchantDetailsForm.get('merchantSendSmsOnline')?.value, updatedMerchant, 'merchantSendSmsOnline');
    this.setUpdatedValue(this.merchantModel.enableEcommerce, this.merchantDetailsForm.get('enableEcommerce')?.value, updatedMerchant, 'enableEcommerce');
    this.setUpdatedValue(this.merchantModel.enableRecurring, this.merchantDetailsForm.get('enableRecurring')?.value, updatedMerchant, 'enableRecurring');

    // Compare nested objects explicitly
    if (this.hasNestedChanges(this.merchantModel.rootUser, this.merchantDetailsForm.get('rootUser')?.value)) {
      updatedMerchant.rootUser = this.getUpdatedNestedObject(this.merchantModel.rootUser, this.merchantDetailsForm.get('rootUser')?.value);
    }

    if (this.hasNestedChanges(this.merchantModel.settlementBankAccount, this.merchantDetailsForm.get('settlementBankAccount')?.value)) {
      updatedMerchant.settlementBankAccount = this.getUpdatedNestedObject(this.merchantModel.settlementBankAccount, this.merchantDetailsForm.get('settlementBankAccount')?.value);
    }

    if (this.hasNestedChanges(this.merchantModel.merchantFeeRates, this.merchantDetailsForm.get('merchantFeeRates')?.value)) {
      updatedMerchant.merchantFeeRates = this.getUpdatedNestedObject(this.merchantModel.merchantFeeRates, this.merchantDetailsForm.get('merchantFeeRates')?.value);
    }

    if (this.hasNestedChanges(this.merchantModel.ecommerceBankSetup, this.merchantDetailsForm.get('ecommerceBankSetup')?.value)) {
      updatedMerchant.ecommerceBankSetup = this.getUpdatedNestedObject(this.merchantModel.ecommerceBankSetup, this.merchantDetailsForm.get('ecommerceBankSetup')?.value);
    }

    if (this.hasNestedChanges(this.merchantModel.posBankSetup, this.merchantDetailsForm.get('posBankSetup')?.value)) {
      updatedMerchant.posBankSetup = this.getUpdatedNestedObject(this.merchantModel.posBankSetup, this.merchantDetailsForm.get('posBankSetup')?.value);
    }

    if (this.hasNestedChanges(this.merchantModel.merchantEcommerceGateway, this.merchantDetailsForm.get('merchantEcommerceGateway')?.value)) {
      updatedMerchant.merchantEcommerceGateway = this.getUpdatedNestedObject(this.merchantModel.merchantEcommerceGateway, this.merchantDetailsForm.get('merchantEcommerceGateway')?.value);
    }

    if (this.hasNestedChanges(this.merchantModel.merchantRecurringGateway, this.merchantDetailsForm.get('merchantRecurringGateway')?.value)) {
      updatedMerchant.merchantRecurringGateway = this.getUpdatedNestedObject(this.merchantModel.merchantRecurringGateway, this.merchantDetailsForm.get('merchantRecurringGateway')?.value);
    }

    if (this.hasNestedChanges(this.merchantModel.merchantCollectionSettings, this.merchantDetailsForm.get('merchantCollectionSettings')?.value)) {
      updatedMerchant.merchantCollectionSettings = this.getUpdatedNestedObject(this.merchantModel.merchantCollectionSettings, this.merchantDetailsForm.get('merchantCollectionSettings')?.value);
    }

    // Return the updated merchant object
    return updatedMerchant;
  }


  updateMerchant() {
    const updatedMerchant = this.getUpdatedMerchant();

    // Check if there are changes before making the API call
    if (Object.keys(updatedMerchant).length > 0) {
      this.isLoading = true; // Show loading spinner
      this.spinner.show();

      this.merchantService.updateMerchant(updatedMerchant, this.merchantId).subscribe(
        (response) => {
          this.isLoading = false;
          this.spinner.hide();
          if (response.success) {
            Swal.fire({
              icon: "success",
              title: "Successful!",
              text: `Merchant ${response.merchantName} was updated successfully.`,
              confirmButtonText: "Ok"
            }).then((result) => {
              if (result.isConfirmed) {
                this._router.navigate(['/admin/merchant-list']);
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `Merchant ${response.merchantName} update failed.`
            });
          }
        },
        (error) => {
          this.isLoading = false;
          this.spinner.hide();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error
          });
        }
      );
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: 'No changes detected.'
      });
    }
  }


}
