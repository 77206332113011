<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Unsettled Transactions</h5>
                </div>
                <div class="card-body tab2-card">
                    <div class="btn-popup pull-right">
                        <a class="btn btn-primary">Refresh</a>
                    </div>
                    <div id="transactionReport" class="category-table custom-datatable transcation-datatable">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" sortable="merchantName" (sort)="onSort($event)">
                                            Merchant Name
                                        </th>
                                        <th scope="col" sortable="lastPaid" (sort)="onSort($event)">Last Paid</th>
                                        <th scope="col" sortable="visaTransactions" (sort)="onSort($event)">VISA
                                            Transactions
                                        </th>
                                        <th scope="col" sortable="visaFees" (sort)="onSort($event)">
                                            VISA Fees</th>
                                        <th scope="col" sortable="mastercardTransactions" (sort)="onSort($event)">
                                            Mastercard Transactions
                                        </th>
                                        <th scope="col" sortable="mastercardFees" (sort)="onSort($event)">
                                            Mastercard Fees</th>
                                        <th scope="col" sortable="amexTransactions" (sort)="onSort($event)">
                                            Amex Transactions
                                        </th>
                                        <th scope="col" sortable="amexFees" (sort)="onSort($event)">
                                            Amex Fees</th>
                                        <th scope="col" sortable="dinersTransactions" (sort)="onSort($event)">
                                            Diners Transactions
                                        </th>
                                        <th scope="col" sortable="dinersFees" (sort)="onSort($event)">
                                            Diners Fees</th>
                                        <th scope="col" sortable="vatFees" (sort)="onSort($event)">
                                            VAT Fees</th>
                                        <th scope="col">Suspend</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Malitas Hair Studio</td>
                                        <td>20 January 2016</td>
                                        <td>R62 231.88</td>
                                        <td>R2 489.28</td>
                                        <td>R62 231.88</td>
                                        <td>R2 489.28</td>
                                        <td>R0.00</td>
                                        <td>R0.00</td>
                                        <td>R0.00</td>
                                        <td>R0.00</td>
                                        <td>R696.99</td>
                                        <td>
                                            <a href="javascript:void(0)"><i class='fa fa-ban f-12'></i></a>&nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center p-2">
                                <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                    [pageSize]="service.pageSize" [maxSize]="10">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->