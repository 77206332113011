<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Merchant Administration</h5>
        </div>
        <div class="card-body tab2-card">
            <div *ngIf="isLoading">
                <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple"
                    [fullScreen]="false">
                    <br />
                    <br />
                    <br />
                    <p style="color: white"> Loading ... </p>
                </ngx-spinner>
            </div>
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>Merchant Profile</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="merchantDetailsForm" class="needs-validation" novalidate="">
                            <div class="row">
                                <div class="col-sm-5">
                                    <h4>Merchant Details</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="merchantName">
                                                Merchant Name</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="merchantName"
                                                id="merchantName" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="merchantRegistration">
                                                Company Reg</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="merchantRegistration"
                                                id="merchantRegistration" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="merchantVat">
                                                VAT Number</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="merchantVat"
                                                id="merchantVat" type="number" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="merchantAddress">
                                                Company Address</label>
                                        </div>
                                        <div class="col-md-7">
                                            <textarea class="form-control" formControlName="merchantAddress"
                                                id="merchantAddress" required></textarea>
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="title">Title</label>
                                        </div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select" formControlName="title">
                                                <option value="">--Select Title--</option>
                                                <option value="Mr">Mr</option>
                                                <option value="Ms">Ms</option>
                                                <option value="Mrs">Mrs</option>
                                                <option value="Dr">Dr</option>
                                                <option value="Prof">Prof</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="firstname">
                                                First Name</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="firstname"
                                                id="firstname" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="lastname">
                                                Last Name</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="lastname"
                                                id="lastname" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="identityNumber">
                                                Identity Number</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="identityNumber"
                                                id="identityNumber" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="contactNumber1">
                                                Contact Mobile</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="contactNumber1"
                                                id="contactNumber1" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="emailAddress">
                                                Email Address</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="emailAddress"
                                                id="emailAddress" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                </div>
                                <div class="col-sm-5">
                                    <h4>Bank Account Details</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="bankName">Bank
                                                Name</label></div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select" formControlName="bankName">
                                                <option value="">--Select Bank--</option>
                                                <option value="ABSA">ABSA</option>
                                                <option value="African Bank">African Bank</option>
                                                <option value="Bidvest Bank">Bidvest Bank</option>
                                                <option value="Capitec Bank">Capitec Bank</option>
                                                <option value="Discovery Bank">Discovery Bank</option>
                                                <option value="FNB">FNB</option>
                                                <option value="Grindrod Bank">Grindrod Bank</option>
                                                <option value="Investec Bank">Investec Bank</option>
                                                <option value="Nedbank">Nedbank</option>
                                                <option value="Sasfin Bank">Sasfin Bank</option>
                                                <option value="Standard Bank">Standard Bank</option>
                                                <option value="Tyme Bank">Tyme Bank</option>
                                                <option value="Ubank">Ubank</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="accountNumber">
                                                Account Number</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="accountNumber"
                                                id="accountNumber" type="number" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="accountType">Account
                                                Type</label></div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select" formControlName="accountType">
                                                <option value="">--Select Account Type--</option>
                                                <option value="Cheque/Current">Cheque/Current</option>
                                                <option value="Savings">Savings</option>
                                                <option value="Transmission">Transmission</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Branch Code</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="branchCode"
                                                id="branchCode" type="number" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <h4>Merchant Contact Person</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="accountContactName">
                                                Full Name</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="accountContactName"
                                                id="accountContactName" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="accountContactNumber">
                                                Telephone</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="accountContactNumber"
                                                id="accountContactNumber" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="accountContactMobile">
                                                Contact Mobile</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="accountContactMobile"
                                                id="accountContactMobile" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="accountContactEmail">
                                                Email Address</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="accountContactEmail"
                                                id="accountContactEmail" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <h4>Merchant Settings</h4>
                                    <div class="form-group row">
                                        <div class="col-md-7">
                                            <div class="checkbox checkbox-primary">
                                                <input id="settleUsersDirectly" type="checkbox"
                                                    formControlName="merchantSettleUsersDirectly" data-original-title=""
                                                    title="">
                                                <label for="settleUsersDirectly">Settle Users Directly</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-7">
                                            <div class="checkbox checkbox-primary">
                                                <input id="requireBatchSettlementReport" type="checkbox"
                                                    formControlName="merchantRequiresBatchSettlementReport"
                                                    data-original-title="" title="">
                                                <label for="requireBatchSettlementReport">Requires Batch Settlement
                                                    Report</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- <div class="pull-right">
                            <button type="button" class="btn btn-primary">Next</button>
                        </div> -->
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>Charges & Billing Rates</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="merchantRates" class="needs-validation" novalidate="">
                            <div class="row">
                                <div class="col-sm-5">
                                    <h4>Transaction Rates</h4>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="visaCreditCardEcommerce">VISA Credit Card
                                                Ecommerce</label></div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="visaCreditCardEcommerceRate"
                                                id="visaCreditCardEcommerce" type="text" required=""></div>
                                        <div class="valid-feedback">Please Provide a Rate.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="visaDebitCardEcommerce">VISA Debit Card
                                                Ecommerce</label></div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="visaDebitCardEcommerceRate" id="visaDebitCardEcommerce"
                                                type="number" required=""></div>
                                        <div class="valid-feedback">Please Provide a Rate.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="mastercardCreditCardEcommerce">Mastercard
                                                Credit Card Ecommerce</label></div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="mastercardCreditCardEcommerceRate"
                                                id="mastercardCreditCardEcommerce" type="text" required=""></div>
                                        <div class="valid-feedback">Please Provide a Rate.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="mastercardDebitCardEcommerce">Mastercard Debit
                                                Card Ecommerce</label></div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="mastercardDebitCardEcommerceRate"
                                                id="mastercardDebitCardEcommerce" type="text" required=""></div>
                                        <div class="valid-feedback">Please Provide a Rate.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="amexCardEcommerce">Amex Card Ecommerce</label>
                                        </div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="amexCardEcommerceRate" id="amexCardEcommerce"
                                                type="text" required=""></div>
                                        <div class="valid-feedback">Please Provide a Rate.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="dinersCardEcommerce">Diners Card
                                                Ecommerce</label></div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="dinersCardEcommerceRate" id="dinersCardEcommerce"
                                                type="text" required=""></div>
                                        <div class="valid-feedback">Please Provide a Rate.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="visaCreditCardPos">VISA Credit Card
                                                POS</label></div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="visaCreditCardPosRate" id="visaCreditCardPos"
                                                type="text" required=""></div>
                                        <div class="valid-feedback">Please Provide a Rate.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="visaDebitCardPos">VISA Debit Card POS</label>
                                        </div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="visaDebitCardPosRate" id="visaDebitCardPos" type="text"
                                                required=""></div>
                                        <div class="valid-feedback">Please Provide a Rate.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="mastercardCreditCardPos">Mastercard Credit
                                                Card POS</label></div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="mastercardCreditCardPosRate"
                                                id="mastercardCreditCardPos" type="text" required=""></div>
                                        <div class="valid-feedback">Please Provide a Rate.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="mastercardDebitCardPos">Mastercard Debit Card
                                                POS</label></div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="mastercardDebitCardPosRate" id="mastercardDebitCardPos"
                                                type="text" required=""></div>
                                        <div class="valid-feedback">Please Provide a Rate.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="amexCardPos">Amex Card POS</label></div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="amexCardPosRate" id="amexCardPos" type="text"
                                                required=""></div>
                                        <div class="valid-feedback">Please Provide a Rate.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="dinersCardPos">Diners Card POS</label></div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="dinersCardPosRate" id="dinersCardPos" type="text"
                                                required=""></div>
                                        <div class="valid-feedback">Please Provide a Rate.</div>
                                    </div>
                                </div>
                                <div class="col-sm-5">
                                    <h4>Fees</h4>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="smsFee">SMS Fee</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="smsFee"
                                                id="smsFee" type="text" required=""></div>
                                        <div class="valid-feedback">Please Provide a Fee.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="userFee">User Fee</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="userFee"
                                                id="userFee" type="text" required=""></div>
                                        <div class="valid-feedback">Please Provide a Fee.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="tokenisationFee">Tokenisation Fee</label>
                                        </div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="tokenisationFee" id="tokenisationFee" type="text"
                                                required=""></div>
                                        <div class="valid-feedback">Please Provide a Fee.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="refundFee">Refund Fee</label></div>
                                        <div class="col-md-4"><input class="form-control" formControlName="refundFee"
                                                id="refundFee" type="text" required=""></div>
                                        <div class="valid-feedback">Please Provide a Fee.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="riskScreeningFee">Risk Screening Fee</label>
                                        </div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="riskScreeningFee" id="riskScreeningFee" type="text"
                                                required=""></div>
                                        <div class="valid-feedback">Please Provide a Fee.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="recurringTransactionFee">Recurring Transaction Fee</label>
                                        </div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="recurringTransactionFee" id="recurringTransactionFee" type="text"
                                                required=""></div>
                                        <div class="valid-feedback">Please Provide a Fee.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6"><label for="authFee">Authorisation Fee</label>
                                        </div>
                                        <div class="col-md-4"><input class="form-control"
                                                formControlName="authFee" id="authFee" type="text"
                                                required=""></div>
                                        <div class="valid-feedback">Please Provide a Fee.</div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- <div class="pull-right">
                            <button type="button" class="btn btn-primary">Next</button>
                        </div> -->
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink>Bank Setup</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="bankSetup" class="needs-validation" novalidate="">
                            <div class="row">
                                <div class="col-sm-5">
                                    <h4>Ecommerce Bank Setup</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Merchant ID</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="bankMerchantId"
                                                id="bankMerchantId" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-xl-3 col-sm-4">
                                            <label>Merchant Type</label>
                                        </div>
                                        <div class="col-xl-9 col-sm-8">
                                            <div
                                                class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                <label class="d-block" for="ISO">
                                                    <input class="radio_animated" id="ISO" type="radio"
                                                        formControlName="merchantType" value="ISO">
                                                    ISO
                                                </label>
                                                <label class="d-block" for="TPPP">
                                                    <input class="radio_animated" id="TPPP" type="radio"
                                                        formControlName="merchantType" value="TPPP" checked>
                                                    TPPP
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Merchant Shortname</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="merchantShortname"
                                                id="merchantShortname" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Sub Merchant Identifier</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="subMerchantIdentifier"
                                                id="subMerchantIdentifier" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>MCC Code</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="mccCode" id="mccCode"
                                                type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                </div>
                                <div class="col-sm-5">
                                    <h4>POS Bank Setup</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Merchant ID</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="posBankMerchantId"
                                                id="posBankMerchantId" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-xl-3 col-sm-4">
                                            <label>Merchant Type</label>
                                        </div>
                                        <div class="col-xl-9 col-sm-8">
                                            <div
                                                class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                <label class="d-block" for="posISO">
                                                    <input class="radio_animated" id="posISO" type="radio"
                                                        formControlName="posMerchantType" value="ISO">
                                                    ISO
                                                </label>
                                                <label class="d-block" for="posTPPP">
                                                    <input class="radio_animated" id="posTPPP" type="radio"
                                                        formControlName="posMerchantType" value="TPPP" checked>
                                                    TPPP
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Merchant Shortname</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="posMerchantShortname"
                                                id="posMerchantShortname" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Sub Merchant Identifier</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="posSubMerchantIdentifier"
                                                id="posSubMerchantIdentifier" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>MCC Code</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="posMccCode"
                                                id="posMccCode" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4">
                    <a ngbNavLink>Gateway Setup</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="gatewaySetup" class="needs-validation" novalidate="">
                            <div class="row">
                                <div class="col-sm-6">
                                    <h4>Ecommerce</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="ecommerceGateway">Gateway</label>
                                        </div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select" formControlName="gateway"
                                                required>
                                                <option value="">--Select Gateway--</option>
                                                <option value="APS">African Payment Solutions</option>
                                                <!-- <option value="PEP">Peach Payments</option> -->
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="gatewayUrl">Gateway Url</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="gatewayUrl" id="gatewayUrl"
                                                type="text" required>
                                            <div class="valid-feedback">Please Provide a Valid URL.</div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="gatewayKey">Gateway Key</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="gatewayKey" id="gatewayKey"
                                                type="text" required>
                                            <div class="valid-feedback">Please Provide a Valid Key.</div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="gatewayToken">Gateway Token</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="gatewayToken" id="gatewayToken"
                                                type="text" required>
                                            <div class="valid-feedback">Please Provide a Valid Token.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <h4>Recurring</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="recurringGateway">Gateway</label>
                                        </div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select" formControlName="recurringGateway"
                                                required>
                                                <option value="">--Select Gateway--</option>
                                                <!-- <option value="APS">African Payment Solutions</option> -->
                                                <option value="PEP">Peach Payments</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="recurringGatewayUrl">Gateway Url</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="recurringGatewayUrl"
                                                id="recurringGatewayUrl" type="text" required>
                                            <div class="valid-feedback">Please Provide a Valid URL.</div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="recurringGatewayKey">Gateway Key</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="recurringGatewayKey"
                                                id="recurringGatewayKey" type="text" required>
                                            <div class="valid-feedback">Please Provide a Valid Key.</div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="recurringGatewayToken">Gateway Token</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="recurringGatewayToken"
                                                id="recurringGatewayToken" type="text" required>
                                            <div class="valid-feedback">Please Provide a Valid Token.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </li>
                <li [ngbNavItem]="5">
                    <a ngbNavLink>Collection Settings</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="collectionsSettings" class="needs-validation" novalidate="">
                            <div class="row">
                                <div class="col-sm-6">
                                    <h4>Configuration</h4>
                                    <!-- <div class="form-group row">
                                        <div class="col-md-6">
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox-primary-1" type="checkbox" data-original-title=""
                                                    title="">
                                                <label for="checkbox-primary-1">Use default run times</label>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Collection Run Time</label>
                                        </div>
                                        <div class="col-md-6">
                                            <ngb-timepicker formControlName="collectionRunTime" [(ngModel)]="collectionRunTime" [meridian]="meridian"></ngb-timepicker>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Collection Resubmission Run Time</label>
                                        </div>
                                        <div class="col-md-6">
                                            <ngb-timepicker formControlName="collectionResubmissionRunTime" [(ngModel)]="collectionResubmissionRunTime" [meridian]="meridian"></ngb-timepicker>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4"><label for="collectionFrequency">Collection
                                                Frequency</label></div>
                                        <div class="col-md-6">
                                            <select class="custom-select form-select" formControlName="collectionFrequency">
                                                <option value="">--Select Frequency--</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Weekly">Weekly</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Collection Day</label>
                                        </div>
                                        <div class="col-md-6">
                                            <input class="form-control" formControlName="collectionDay"
                                                id="collectionDay" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <h4></h4>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <div class="checkbox checkbox-primary">
                                                <input id="collectionAutoRun" type="checkbox" data-original-title=""
                                                    title="" formControlName="collectionAutoRun">
                                                <label for="collectionAutoRun">Collection Auto Rerun</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row" *ngIf="collectionsSettings.get('collectionAutoRun').value">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Collection Auto Rerun Attempts</label>
                                        </div>
                                        <div class="col-md-6">
                                            <input class="form-control" formControlName="collectionRerunAttempts"
                                                id="validationCustom1" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <div class="checkbox checkbox-primary">
                                                <input id="collectionAllowDynamicPayday" type="checkbox" data-original-title=""
                                                    formControlName="collectionAllowDynamicPayday" title="">
                                                <label for="collectionAllowDynamicPayday">Allow Dynamic Payday</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <div class="checkbox checkbox-primary">
                                                <input id="earlyDecemberCollection" type="checkbox" data-original-title=""
                                                formControlName="earlyDecemberCollection" title="">
                                                <label for="earlyDecemberCollection">Early December Collection</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row" *ngIf="collectionsSettings.get('earlyDecemberCollection').value">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Early December Collection Day</label>
                                        </div>
                                        <div class="col-md-6">
                                            <input class="form-control" formControlName="earlyDecemberDay"
                                                id="validationCustom1" type="text" required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Valid Coupon Code.</div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="pull-right">
                            <button type="button" class="btn btn-primary" (click)="updateMerchant()">Update</button>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->