import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CardPaymentFormResponseModel } from 'src/app/core/models/transaction/card-payment-form-response.model';
import { TransactionService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-customer-card-payment-form',
  templateUrl: './customer-card-payment-form.component.html',
  styleUrls: ['./customer-card-payment-form.component.scss']
})
export class CustomerCardPaymentFormComponent implements OnInit, OnDestroy {

  public actionLink: string = environment.portal + "/card/customer-payment-status";
  public isLoading: boolean = false;
  public isSubmitted: boolean = false;
  public subscription: Subscription;
  public transactionRequestIdentifier: string;

  constructor(private route: ActivatedRoute, private spinner: NgxSpinnerService,
    private transactionService: TransactionService) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.transactionRequestIdentifier = params.get('transactionRequestIdentifier');
      if (this.transactionRequestIdentifier != null) {
        this.loadPaymentForm(this.transactionRequestIdentifier);
      }
    });
  }

  loadPaymentForm(transactionRequestIdentifier: string) {
    this.isSubmitted = true;
    this.isLoading = true;

    this.spinner.show();

    this.transactionService.getCardPaymentForm(transactionRequestIdentifier).subscribe({
      next: (data: CardPaymentFormResponseModel) => {
        this.loadPaymentWidgetsScript(data.cardPaymentFormIdentifier);
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
      }
    })
  }

  loadPaymentWidgetsScript(cardPaymentFormIdentifier: string) {
    const wpwlOptions = {
      style: "plain",
      labels: '{submit: "Tokenise Now"}',
      showCVVHint: true,
      brandDetection: true,
      onReady: () => {
        $(".wpwl-group-cardNumber").after($(".wpwl-group-brand").detach());
        $(".wpwl-group-cvv").after($(".wpwl-group-cardHolder").detach());
        var brand = $(".wpwl-brand:first").clone().removeAttr("class").attr("class", "wpwl-brand-card wpwl-brand-custom wpwl-brand-VISA wpwl-brand-MASTER");
        $(".wpwl-brand:first").after($(brand));
        //@ts-ignore
        var imageUrl = "https://eu-test.oppwa.com/v1/static/" + wpwl.cacheVersion + "/img/brand.png";
        $(".wpwl-brand-custom").css("background-image", "url(" + imageUrl + ")");
      },
      onChangeBrand: (e) => {
        $(".wpwl-brand-custom").css("opacity", "0.3");
        $(".wpwl-brand-" + e).css("opacity", "1");
      }
    };

    // Set wpwlOptions as a global variable
    window['wpwlOptions'] = wpwlOptions;

    // Load payment widgets script
    const script = document.createElement('script');
    script.src = 'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=' + cardPaymentFormIdentifier;
    script.onload = () => {
      console.log('Payment widgets script loaded successfully.');
    };
    script.onerror = (error) => {
      console.error('Failed to load payment widgets script:', error);
    };
    document.head.appendChild(script);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
