import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { CreateMerchantComponent } from './merchants/create-merchant/create-merchant.component';
import { MerchantListComponent } from './merchants/merchant-list/merchant-list.component';
import { AdminRoutingModule } from './admin-routing.module';
import { UnpaidsComponent } from './unpaids/unpaids.component';
import { ReconSettlementReportComponent } from './reports/recon-settlement-report/recon-settlement-report.component';
import { RunBillingComponent } from './run-billing/run-billing.component';
import { AddBankSettlementComponent } from './bank-settlement/add-bank-settlement/add-bank-settlement.component';
import { ListBankStatementComponent } from './bank-settlement/list-bank-statement/list-bank-statement.component';
import { ChargebacksComponent } from './chargebacks/chargebacks.component';
import { AddPosTerminalComponent } from './pos-terminal/add-pos-terminal/add-pos-terminal.component';
import { ListPosTerminalComponent } from './pos-terminal/list-pos-terminal/list-pos-terminal.component';
import { UnsettledTransactionsComponent } from './settlements/unsettled-transactions/unsettled-transactions.component';
import { UnsettledEcommerceComponent } from './settlements/unsettled-ecommerce/unsettled-ecommerce.component';
import { UnsettledPosComponent } from './settlements/unsettled-pos/unsettled-pos.component';
import { UnsettledEcommerceDetailComponent } from './settlements/unsettled-ecommerce-detail/unsettled-ecommerce-detail.component';
import { DailySettlementComponent } from './settlements/daily-settlement/daily-settlement.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UpdateMerchantComponent } from './merchants/update-merchant/update-merchant.component';

@NgModule({
  declarations: [
    CreateMerchantComponent,
    MerchantListComponent,
    UnpaidsComponent,
    ReconSettlementReportComponent,
    RunBillingComponent,
    AddBankSettlementComponent,
    ListBankStatementComponent,
    ChargebacksComponent,
    AddPosTerminalComponent,
    ListPosTerminalComponent,
    UnsettledTransactionsComponent,
    UnsettledEcommerceComponent,
    UnsettledPosComponent,
    UnsettledEcommerceDetailComponent,
    DailySettlementComponent,
    UpdateMerchantComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    NgxSpinnerModule,
    NgbTimepickerModule
  ]
})
export class AdminModule { }
