import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { CardPaymentTransactionResponseModel } from 'src/app/core/models/transaction/card-payment-transaction-response.model';
import { TransactionService } from 'src/app/core/services';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-card-transaction-report',
  templateUrl: './card-transaction-report.component.html',
  styleUrls: ['./card-transaction-report.component.scss']
})
export class CardTransactionReportComponent implements OnInit, OnDestroy {

  public isLoading: boolean = false;

  public searchCriteriaForm: UntypedFormGroup;
  public date: { year: number, month: number };
  public active = 1;

  public cardPaymentTransactions: CardPaymentTransactionResponseModel[];

  public tableItem$: Observable<CardPaymentTransactionResponseModel[]>;
  public cardPaymentTransactions$: Observable<CardPaymentTransactionResponseModel[]>;
  public subscription: Subscription = new Subscription();
  total$: Observable<number>;

  constructor(private formBuilder: UntypedFormBuilder, public service: TableService,
    private _transactionService: TransactionService, private spinner: NgxSpinnerService) {
    this.createSearchCriteriaFormorm();

    this.cardPaymentTransactions$ = service.tableItem$;
    this.total$ = service.total$;
  }

  createSearchCriteriaFormorm() {
    this.searchCriteriaForm = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
    })
  }

  get searchRecurringReport() {
    return this.searchCriteriaForm.controls;
  }

  search() {
    const recurringReport = this.searchRecurringReport;
    let startDate = recurringReport.start_date.value;
    let endDate = recurringReport.end_date.value;


    const startDateString = "" + startDate.year + "-" + startDate.month.toString().padStart(2, '0') + "-" + startDate.day.toString().padStart(2, '0');
    const endDateString = "" + endDate.year + "-" + endDate.month.toString().padStart(2, '0') + "-" + endDate.day.toString().padStart(2, '0');

    this.getCardTransactionReport(startDateString, endDateString);
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  ngOnInit(): void {
    let currentDate = new Date(); // Assuming currentDate is defined somewhere
    let defaultDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

    this.getCardTransactionReport('2024-05-01', defaultDate);
  }

  async getCardTransactionReport(startDate: string, endDate: string) {
    this.isLoading = true;
    this.spinner.show();

    this._transactionService.getCardTransactionsReport(startDate, endDate).subscribe(data => {
      this.cardPaymentTransactions = data;
      this.isLoading = false;
      this.spinner.hide();
      this.active = 2;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.service.reset();
    }
  }

}
