<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>EFT Transaction Administration</h5>
        </div>
        <div class="card-body tab2-card">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>Capture Instruction</a>
                    <div *ngIf="isLoading">
                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple"
                            [fullScreen]="false">
                            <br />
                            <br />
                            <br />
                            <p style="color: white"> Loading ... </p>
                        </ngx-spinner>
                    </div>
                    <ng-template ngbNavContent>
                        <form [formGroup]="eftCaptureTransactionForm" class="needs-validation" novalidate="">
                            <h4></h4>
                            <div class="row">
                                <div class="col-sm-5">
                                    <h4>Debtor Details</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom0"><span>*</span>
                                                Debtor Reference</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="debtorReference"
                                                id="validationCustom0" type="text" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom0"><span>*</span>
                                                Fullname</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="debtorFullname"
                                                id="validationCustom0" type="text" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom1"><span>*</span>
                                                Identification Number</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="identificationNumber"
                                                id="validationCustom1" type="text" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom1"><span>*</span>
                                                Identification Type</label>
                                        </div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select" formControlName="identificationType">
                                              <option value="">-- Select Identification Type --</option>
                                              <option value="SAID">South African ID Number</option>
                                              <option value="SAP">South African Passport Number</option>
                                              <option value="FP">Foreign Passport Number</option>
                                            </select>
                                          </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom1"><span>*</span>
                                                Account Number</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="accountNumber"
                                                id="validationCustom1" type="text" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom1"><span>*</span>
                                                Account Type</label>
                                        </div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select" formControlName="accountType">
                                              <option value="">-- Select Account Type --</option>
                                              <option value="Cheque">Cheque</option>
                                              <option value="Savings">Savings</option>
                                              <option value="Transmission">Transmission</option>
                                            </select>
                                          </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom1"><span>*</span>
                                                Bank</label>
                                        </div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select" formControlName="bank">
                                              <option value="">-- Select Bank --</option>
                                              <option value="ABSA">ABSA</option>
                                              <option value="AfricanBank">African Bank</option>
                                              <option value="CapitecBank">Capitec Bank</option>
                                              <option value="DiscoveryBank">Discovery</option>
                                              <option value="FNB">FNB</option>
                                              <option value="Investec">Investec</option>
                                              <option value="Nedbank">Nedbank</option>
                                              <option value="SASFIN">SASFIN</option>
                                              <option value="StandardBank">Standard Bank</option>
                                            </select>
                                          </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom1"><span>*</span>
                                                Mobile Number</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="mobileNumber"
                                                id="validationCustom1" type="text" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom1"><span>*</span>
                                                Email</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="emailAddress"
                                                id="validationCustom1" type="text" required="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-5">
                                    <h4>Collection Details</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom0"><span>*</span>
                                                Merchant Reference</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="merchantReference"
                                                id="validationCustom0" type="text" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom1"><span>*</span>
                                                Collection Type</label>
                                        </div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select" formControlName="collectionType">
                                              <option value="">-- Select Collection Type --</option>
                                              <option value="SameDay">Same Day</option>
                                              <option value="OneDay">One Day</option>
                                              <option value="TwoDay">Two Day</option>
                                            </select>
                                          </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom1"><span>*</span>
                                                Collection Date</label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="input-group input-grp-p">
                                                <input class="datepicker-here form-control digits" ngbDatepicker
                                                    formControlName="collectionDate" #f="ngbDatepicker">
                                                <div class="input-group-append">
                                                    <button class="btn calendar" (click)="f.toggle()"
                                                        type="button"><i class="fa fa-calendar"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom1"><span>*</span>
                                                Collection Amount</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="collectionAmount"
                                                id="validationCustom1" type="number" min="0" required="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="pull-right">
                            <button type="button" class="btn btn-primary">Submit</button>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>Batch Upload</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="eftUploadTransactionsForm" class="needs-validation" novalidate="">
                            <h4>File Upload</h4>
                            <div class="form-group row">
                                <div class="col-xl-3 col-md-4">
                                    <label>Please upload the batch file downloaded from the template section.</label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <div class="card-body dropzone-custom p-0">
                                        <ngx-dropzone class="dropzone-border" (change)="onSelect($event)">
                                            <ngx-dropzone-label>
                                                <div class="dz-message needsclick">
                                                    <i class="fa fa-cloud-upload"></i>
                                                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                                </div>
                                            </ngx-dropzone-label>
                                            <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"
                                                (removed)="onRemove(f)">
                                                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                            </ngx-dropzone-preview>
                                        </ngx-dropzone>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="pull-right">
                            <button type="button" class="btn btn-primary">Upload</button>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->