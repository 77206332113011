import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { jwtDecode } from 'jwt-decode';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { MasqueradeRequestModel } from 'src/app/core/models/auth/masquerade-request.model';
import { RequestUserSearchParameters } from 'src/app/core/models/common/request-parameters.model';
import { UserListModel, UserSummaryModel } from 'src/app/core/models/user/user-list.model';
import { UserModel } from 'src/app/core/models/user/user.model';
import { AuthService } from 'src/app/core/services';
import { GetUsersAction } from 'src/app/core/store/actions/user.action';
import { UsersState } from 'src/app/core/store/state/users.state';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { NavService } from 'src/app/shared/service/nav.service';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class ListUserComponent implements OnInit, OnDestroy {
  public userList: UserListModel;
  public users$: Observable<UserSummaryModel[]>;

  public isLoading: boolean = false;

  public tableItem$: Observable<UserSummaryModel[]>;
  public searchText;
  total$: Observable<number>;
  public subscription: Subscription;

  @Select(UsersState.selectedUsersByMerchant) selectedUsers$: Observable<UserListModel>;
  constructor(public service: TableService, private store: Store,
    private _authService: AuthService,
    private _navService: NavService,
    private _router: Router,
    private spinner: NgxSpinnerService) {
    this.searchUserDeactivations();

    this.subscription = new Subscription();
    this.subscription = this.selectedUsers$.subscribe(data => {
      this.userList = data;
      if (this.userList != null) {
        this.users$ = service.tableItem$;
        this.total$ = service.total$;
        this.service.setUserData(this.userList);
      }
    });
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  searchUserDeactivations() {
    let requestUserSearchParameters: RequestUserSearchParameters = {
      searchTerm: "",
      limit: 50,
      offset: 0,
      sort: "",
      sortField: ""
    }

    this.store.dispatch(new GetUsersAction(requestUserSearchParameters));
  }

  ngOnInit() {
  }

  extractSubFromToken(): string | null {
    try {
      const decodedToken: any = jwtDecode(this._authService.getToken());

      return decodedToken.sub || null;
    } catch (error) {
      console.error('Invalid token:', error);
      return null;
    }
  }

  masqueradeUser(targetUsername: string) {
    this.isLoading = true;
    this.spinner.show();

    const merchantAdminUsername = this.extractSubFromToken();
    let masqueradeRequestModel: MasqueradeRequestModel = {
      superAdminUsername: merchantAdminUsername.toLowerCase(),
      targetUsername: targetUsername
    }

    this._authService.masquerade(masqueradeRequestModel).subscribe(data => {
      this.isLoading = false;
      this.spinner.hide();
      this._navService.updateMenuItems(data.role, this._authService.enableEcommerce(), this._authService.enableRecurring());
      this.refreshCurrentRoute();
    });
  }

  refreshCurrentRoute() {
    this._router.navigate(['/dashboard/default']).then(() => {
      location.reload();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}

