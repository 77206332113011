import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { BillingInvoiceItemModel } from 'src/app/core/models/billing-invoice/billing-invoice-item.model';
import { BillingInvoiceModel } from 'src/app/core/models/billing-invoice/billing-invoice.model';
import { GetBillingInvoiceAction, GetBillingInvoiceDetailAction } from 'src/app/core/store/actions/billing-invoice.action';
import { BillingInvoiceState } from 'src/app/core/store/state/billing-invoice.state';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-billing-invoice',
  templateUrl: './billing-invoice.component.html',
  styleUrls: ['./billing-invoice.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class BillingInvoiceComponent implements OnDestroy {
  public billingInvoice: BillingInvoiceModel;
  public billingInvoiceForm: UntypedFormGroup;
  public closeResult: string;
  public invoices$: Observable<BillingInvoiceItemModel[]>;
  public subscription: Subscription;
  total$: Observable<number>;

  @Select(BillingInvoiceState.selectedBillingInvoice) selectedBillingInvoices$: Observable<BillingInvoiceModel>;
  constructor(public service: TableService, private modalService: NgbModal, private _router: Router,
    private store: Store, private formBuilder: UntypedFormBuilder) {
    this.invoices$ = service.tableItem$;
    this.total$ = service.total$;

    this.subscription = new Subscription();
    this.subscription = this.selectedBillingInvoices$.subscribe(data => {
      this.billingInvoice = data;
      if (this.billingInvoice != null && this.billingInvoice.totalCount !== 0) {
        this.service.setUserData(this.billingInvoice.invoices);
      }
    });

    this.createBillingInvoiceForm();
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  get billingInvoiceControls() {
    return this.billingInvoiceForm.controls;
  }

  createBillingInvoiceForm() {
    this.billingInvoiceForm = this.formBuilder.group({
      searchText: ['']
    })
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  retrieveBillingInvoices(billingId: number) {
    this.store.dispatch(new GetBillingInvoiceAction(billingId));
  }

  searchBillingInvoice() {
    if (this.billingInvoiceControls.searchText.value) {
      var billingId = parseInt(this.billingInvoiceControls.searchText.value);

      if (billingId) {
        this.retrieveBillingInvoices(billingId)
      }
    }
  }

  ngOnInit() {
  }

  viewInvoiceDetail(invoiceNumber: number) {
    this.store.dispatch(new GetBillingInvoiceDetailAction(invoiceNumber));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}

