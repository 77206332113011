<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <div class="logo-wrapper"><img class="blur-up lazyloaded" src="assets/images/logos/c2p_hub_new.png" alt="">
            </div>
        </div>
        <div class="card-body tab2-card">
            <div *ngIf="!hasResult && !isLoading">
                <h4 class="payment">Please be patient as we attempt to retrieve your payment status...</h4>
            </div>
            <br />
            <br />
            <div *ngIf="isLoading">
                <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple"
                    [fullScreen]="false">
                    <br />
                    <br />
                    <br />
                    <p style="color: white"> Loading ... </p>
                </ngx-spinner>
            </div>
            <div *ngIf="!isLoading && hasResult && isSuccess">
                <br />
                <br />
                <br />
                <br />
                <i class="fa fa-check-circle-o fa-5x text-success payment" aria-hidden="true"></i>
                <h3 class="payment">{{ paymentStatusModel.transactionStatus }}</h3>
                <h5 class="payment">{{ paymentStatusModel.transactionResult }}</h5>
                <br />
                <br />
                <br />
            </div>
            <div *ngIf="!isLoading && hasResult && !isSuccess && !isWarning">
                <br />
                <br />
                <br />
                <br />
                <i class="fa fa-times-circle-o fa-5x text-danger payment" aria-hidden="true"></i>
                <h3 class="payment">{{ paymentStatusModel.transactionStatus }}</h3>
                <h5 class="payment">{{ paymentStatusModel.transactionResult }}</h5>
                <br />
                <br />
                <br />
            </div>
            <div *ngIf="!isLoading && hasResult && isWarning">
                <br />
                <br />
                <br />
                <br />
                <i class="fa fa-exclamation fa-5x text-warning payment" aria-hidden="true"></i>
                <h3 class="payment">{{ paymentStatusModel.transactionStatus }}</h3>
                <h5 class="payment">{{ paymentStatusModel.transactionResult }}!!!</h5>
                <br />
                <br />
                <br />
            </div>
        </div>
    </div>
</div>