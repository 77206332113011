import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, Observable } from 'rxjs';
import { TransactionSettlementRequest } from 'src/app/core/models/settlement/transaction-settlement-request.model';
import { UnsettledEcommerceSummaryModel, UnsettledEcommerceTransactionResponse } from 'src/app/core/models/settlement/unsettled-ecommerce-summary.model';
import { SettlementService } from 'src/app/core/services';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-unsettled-ecommerce-detail',
  templateUrl: './unsettled-ecommerce-detail.component.html',
  styleUrls: ['./unsettled-ecommerce-detail.component.scss']
})
export class UnsettledEcommerceDetailComponent implements OnInit, OnDestroy {

  public searchCriteriaForm: UntypedFormGroup;
  public date: { year: number, month: number };
  public active = 1;

  public tableItem$: any[];
  public subscription: Subscription;
  public searchText;
  total$: Observable<number>;

  public isLoading: boolean = false;

  public unsettledEcommerceSummaryModel: UnsettledEcommerceSummaryModel;
  public unsettledEcommerceTransactionResponses: UnsettledEcommerceTransactionResponse[] = [];

  public settledTransactionRequests: TransactionSettlementRequest[] = [];

  constructor(private formBuilder: UntypedFormBuilder,
    public service: TableService,
    private _router: Router,
    private _settlementService: SettlementService,
    private spinner: NgxSpinnerService) {
    const unsettledEcommerceSummary = this._router.getCurrentNavigation()?.extras.state?.data;
    if (unsettledEcommerceSummary) {
      this.unsettledEcommerceSummaryModel = unsettledEcommerceSummary;
      this.unsettledEcommerceTransactionResponses = this.unsettledEcommerceSummaryModel.unsettledEcommerceTransactions;
    }
  }

  ngOnInit(): void {
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  onSelect(bankReference: string) {
    const settledTransactionRequest: TransactionSettlementRequest = {
      bankReference: bankReference,
      settle: true
    };

    // Check if the bankReference already exists in the settledTransactionRequests
    const index = this.settledTransactionRequests.findIndex(
      req => req.bankReference === bankReference
    );

    if (index > -1) {
      // If the item is already selected, remove it from the list
      this.settledTransactionRequests.splice(index, 1);
    } else {
      // If the item is not selected, add it to the list
      this.settledTransactionRequests.push(settledTransactionRequest);
    }

    console.log('Current settled transactions:', this.settledTransactionRequests);
  }

  ngOnDestroy(): void {
  }

  async settleMerchant() {
    this.isLoading = true;
    this.spinner.show();

    if (this.settledTransactionRequests.length > 0) {
      this._settlementService.settleEcomerceTransactions(this.settledTransactionRequests).subscribe(data => {
        var requestCount = data.length;
        this.isLoading = false;
        this.spinner.hide();
        Swal.fire({
          icon: "success",
          title: "Merchant Transactions Settled!",
          text: `${requestCount} transaction/s have been successfully settled.`,
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            this._router.navigate(['/admin/unsettled-ecommerce']).then(() => {
              location.reload();
            });
          }
        });
      });
    } else {
      this.isLoading = false;
      this.spinner.hide();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select transactions to settle."
      });
    }
  }

}
