import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BatchCollectionScheduleComponent } from './batch-collection-schedule/batch-collection-schedule.component';
import { BatchCollectionSummaryComponent } from './batch-collection-summary/batch-collection-summary.component';
import { CardTransactionReportComponent } from './card-transaction-report/card-transaction-report.component';
import { RecurringTransactionReportComponent } from './recurring-transaction-report/recurring-transaction-report.component';
import { ReportsComponent } from './reports.component';


const routes: Routes = [
  {
    path: '',
    component: ReportsComponent,
    data: {
      title: "Reports",
      breadcrumb: "Reports"
    }
  },
  {
    path: 'batch-collection-schedule',
    component: BatchCollectionScheduleComponent,
    data: {
      title: "Batch Collection Schedule",
      breadcrumb: "Batch Collection Schedule"
    }
  },
  {
    path: 'batch-collection-summary',
    component: BatchCollectionSummaryComponent,
    data: {
      title: "Batch Collection Summary",
      breadcrumb: "Batch Collection Summary"
    }
  },
  {
    path: 'transaction-report',
    component: CardTransactionReportComponent,
    data: {
      title: "Transaction Report",
      breadcrumb: "Transaction Report"
    }
  },
  {
    path: 'recurring-transaction-report',
    component: RecurringTransactionReportComponent,
    data: {
      title: "Recurring Transaction Report",
      breadcrumb: "Recurring Transaction Report"
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
