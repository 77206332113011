import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-add-pos-terminal',
  templateUrl: './add-pos-terminal.component.html',
  styleUrls: ['./add-pos-terminal.component.scss']
})
export class AddPosTerminalComponent implements OnInit {
  public posTerminalForm: UntypedFormGroup;
  public simCardForm: UntypedFormGroup;
  public active = 1;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.createPOSTerminalForm();
    this.createSimCardForm();
  }

  createPOSTerminalForm() {
    this.posTerminalForm = this.formBuilder.group({
      status: ['']
    });
  }

  createSimCardForm() {
    this.simCardForm = this.formBuilder.group({
      simIMEI: ['']
    });
  }

  ngOnInit() {
  }

}
