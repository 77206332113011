<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Unsettled Transactions</h5>
                </div>
                <div class="card-body tab2-card">
                    <!-- <div class="btn-popup pull-right">
                        <a class="btn btn-primary">Refresh</a>
                    </div> -->
                    <div id="transactionReport" class="category-table custom-datatable transcation-datatable">
                        <div *ngIf="isLoading">
                            <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple"
                                [fullScreen]="false">
                                <br />
                                <br />
                                <br />
                                <p style="color: white"> Loading ... </p>
                            </ngx-spinner>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" sortable="merchantName" (sort)="onSort($event)">
                                            Merchant Name
                                        </th>
                                        <th scope="col" sortable="totalTransactionCount" (sort)="onSort($event)">Total Transaction Count</th>
                                        <th scope="col" sortable="totalTransactionAmount" (sort)="onSort($event)">Total Transaction Amount</th>
                                        <th scope="col">View Unsettled Ecommerce Transactions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of unsettledEcommerceSummaries">
                                        <td>{{ item.merchant }}</td>
                                        <td>{{ item.transactionCount }}</td>
                                        <td>R{{ item.transactionAmount | number:'1.2-2' }}</td>
                                        <td>
                                            <a href="javascript:void(0)" (click)="navigateToUnsettledEcommerceDetail(item)"><i class='fa fa-tasks f-12'></i></a>&nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center p-2">
                                <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                    [pageSize]="service.pageSize" [maxSize]="10">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->