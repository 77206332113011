<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Chargebacks</h5>
        </div>
        <div class="card-body tab2-card">
            <div class="account-setting deactivate-account" *ngIf="!isSelected">
                <h5 class="f-w-600">What would you like to do?</h5>
                <div class="row">
                    <div class="col">
                        <label class="d-block mb-2" for="edo-ani3">
                            <input class="radio_animated" id="edo-ani3" type="radio" name="rdo-ani1" checked="">
                            Send Chargeback notification requesting proof of transaction
                        </label>
                        <label class="d-block mb-2" for="edo-ani4">
                            <input class="radio_animated" id="edo-ani4" type="radio" name="rdo-ani1">
                            Send Chargeback deduction notification
                        </label>
                        <label class="d-block mb-0" for="edo-ani5">
                            <input class="radio_animated" id="edo-ani5" type="radio" name="rdo-ani1" checked="">
                            Send Chargeback cancellation notification (This is used when a transaction that was
                            previously chargedback, needs to be cancelled)
                        </label>
                    </div>
                </div>
                <button type="button" class="btn btn-primary" (click)="selectOption()">Next</button>
            </div>
            <div *ngIf="isSelected">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>Search Criteria</a>
                        <ng-template ngbNavContent>
                            <form [formGroup]="searchCriteriaForm" class="needs-validation user-add" novalidate="">
                                <div class="permission-block">
                                    <div class="attribute-blocks">
                                        <h5 class="f-w-600 mb-3">Filters</h5>
                                        <div class="row mb-3">
                                            <div class="col-xl-4 col-sm-6">
                                                <div
                                                    class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                    <label class="d-block" for="edo-ani1">
                                                        <input class="radio_animated" id="edo-ani1" type="radio"
                                                            name="filter" checked>
                                                        Search by Merchant
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-xl-4 col-sm-6">
                                                <div
                                                    class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                    <label class="d-block" for="edo-ani1">
                                                        <input class="radio_animated" id="edo-ani1" type="radio"
                                                            name="filter">
                                                        By User Firstname, Lastname or Mobile
                                                    </label>
                                                </div>
                                                <input class="form-control" id="validationCustom0" type="text"
                                                    required="">
                                            </div>
                                        </div>
                                        <h5 class="f-w-600 mb-3">Date Range</h5>
                                        <div class="row mb-3">
                                            <div class="col-xl-4 col-sm-6">
                                                <div class="input-group input-grp-p">
                                                    <input class="datepicker-here form-control digits" ngbDatepicker
                                                        formControlName="start_date" #f="ngbDatepicker">
                                                    <div class="input-group-append">
                                                        <button class="btn calendar" (click)="f.toggle()"
                                                            type="button"><i class="fa fa-calendar"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-sm-6">
                                                <div class="input-group input-grp-p">
                                                    <input class="datepicker-here form-control digits" ngbDatepicker
                                                        formControlName="end_date" #c="ngbDatepicker">
                                                    <div class="input-group-append">
                                                        <button class="btn calendar" (click)="c.toggle()"
                                                            type="button"><i class="fa fa-calendar"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h5 class="f-w-600 mb-3">Chargeback Type</h5>
                                        <div class="row mb-3">
                                            <div class="col-xl-6 col-sm-8">
                                                <div
                                                    class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                    <label class="d-block" for="all">
                                                        <input class="radio_animated" id="all" type="radio"
                                                            name="transaction-type" checked>
                                                            Display Chargebacks and Transactions (You can mark transactions as chargeback in this view)
                                                    </label>
                                                </div>
                                                <br />
                                                <div
                                                    class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                    <label class="d-block" for="approved">
                                                        <input class="radio_animated" id="approved" type="radio"
                                                            name="transaction-type">
                                                            Display only Chargebacks
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="pull-right">
                                <button type="button" class="btn btn-primary" (click)="search()">Search</button>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>Transactions</a>
                        <ng-template ngbNavContent>
                            <div class="btn-popup pull-right">
                                <a class="btn btn-primary">Mark all as charged back</a>
                            </div>
                            <div id="transactionReport" class="category-table custom-datatable transcation-datatable">
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    Set as Chargeback Notification
                                                </th>
                                                <th scope="col" sortable="user" (sort)="onSort($event)">User</th>
                                                <th scope="col" sortable="batch" (sort)="onSort($event)">Batch</th>
                                                <th scope="col" sortable="transactionReference" (sort)="onSort($event)">
                                                    Transaction Reference</th>
                                                <th scope="col" sortable="amount" (sort)="onSort($event)">Amount</th>
                                                <th scope="col" sortable="transactionDate" (sort)="onSort($event)">
                                                    Transaction Date</th>
                                                <th scope="col" sortable="transactionStatus" (sort)="onSort($event)">
                                                    Transaction Status</th>
                                                <th scope="col" sortable="transactionDescription"
                                                    (sort)="onSort($event)">Transaction Description</th>
                                                <th scope="col" sortable="settled" (sort)="onSort($event)">Settled</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">
                                                    <input type="checkbox" name="setAsChargebackNotification"
                                                        onclick="onSelect(item.id)">
                                                </th>
                                                <td>Brandon Sandiford</td>
                                                <td></td>
                                                <td>69_13122212</td>
                                                <td>R1.00</td>
                                                <td>2017/01/30 11:30:36 AM</td>
                                                <td>Declined</td>
                                                <td>No such issuer</td>
                                                <td>No</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-center p-2">
                                        <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                            [pageSize]="service.pageSize" [maxSize]="10">
                                        </ngb-pagination>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->