import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Select, Store as ngxStore } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AuthState, AuthStateModel } from './core/store/state/auth.state';
import { AuthenticationResponseModel } from './core/models/auth/authentication-response.model';
import { NavService } from './shared/service/nav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'multikart-backend';

  private subscription: Subscription = new Subscription();

  public isLoggedIn: boolean = false;
  public isLoading: boolean = true;
  public authenticationResponse: AuthenticationResponseModel;

  @Select(AuthState.userLoggedIn) userLoggedIn$: Observable<AuthStateModel>;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private _ngxStore: ngxStore,
    private router: Router,
    private spinner: NgxSpinnerService,
    public navServices: NavService,) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }



}
