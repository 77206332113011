import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { config } from '../app.config';
import { RequestUserSearchParameters } from '../models/common/request-parameters.model';
import { UserDeactivationModel } from '../models/user/user-deactivation.model';
import { UserListModel } from '../models/user/user-list.model';
import { UserResponseModel } from '../models/user/user-response.model';
import { UserModel } from '../models/user/user.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(@Inject(DOCUMENT) private document: any,
  private http: HttpClient) { }

  public createUser(userModel: UserModel): Observable<UserResponseModel> {
    return new Observable(subscriber => {
      this.http.post<UserResponseModel>(config.C2PAPI + '/user', userModel)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: UserResponseModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public createIntegrator(userModel: UserModel): Observable<UserResponseModel> {
    return new Observable(subscriber => {
      this.http.post<UserResponseModel>(config.C2PAPI + '/user/integrator', userModel)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: UserResponseModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getUserDeactivations(): Observable<UserDeactivationModel> {
    return new Observable(subscriber => {
      this.http.get<UserDeactivationModel>(config.C2PAPI + '/user/findByDeActivations')
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: UserDeactivationModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getUsers(requestUserSearchParameters: RequestUserSearchParameters): Observable<UserListModel> {
    return new Observable(subscriber => {
      this.http.post<UserListModel>(config.C2PAPI + '/user/findBySearch', requestUserSearchParameters)
        .pipe(
          map(response => {
            console.log(response);
            return response;
          })
        )
        .subscribe({
          next: (data: UserListModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getUsersByMerchant(): Observable<UserListModel> {
    return new Observable(subscriber => {
      this.http.get<UserListModel>(config.C2PAPI + '/user/merchant/list')
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: UserListModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getAllUsers(): Observable<UserListModel> {
    return new Observable(subscriber => {
      this.http.get<UserListModel>(config.C2PAPI + '/user/list')
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: UserListModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }
}
