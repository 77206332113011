<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <div class="logo-wrapper"><img class="blur-up lazyloaded" src="assets/images/logos/c2p_hub_new.png" alt="">
            </div>
        </div>
        <div class="card-body tab2-card">
            <div *ngIf="isLoading">
                <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple"
                    [fullScreen]="false">
                    <br />
                    <br />
                    <br />
                    <p style="color: white"> Loading ... </p>
                </ngx-spinner>
            </div>
            <form [action]="actionLink" class="paymentWidgets" data-brands="VISA MASTER AMEX" *ngIf="!isLoading">
            </form>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->