import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { BatchCollectionScheduleRequest } from 'src/app/core/models/report/batch-collection-schedule-request.model';
import { BatchCollectionScheduleItem, BatchCollectionScheduleReport } from 'src/app/core/models/report/batch-collection-schedule.model';
import { BatchCollectionSummaryRequest } from 'src/app/core/models/report/batch-collection-summary-request.model';
import { ReportService } from 'src/app/core/services';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-batch-collection-schedule',
  templateUrl: './batch-collection-schedule.component.html',
  styleUrls: ['./batch-collection-schedule.component.scss']
})
export class BatchCollectionScheduleComponent implements OnInit, OnDestroy {

  public isLoading: boolean = false;
  public searchCriteriaForm: UntypedFormGroup;
  public date: { year: number, month: number };

  public recurringCollectionScheduleItems: BatchCollectionScheduleItem[] = [];
  public tableItem$: Observable<BatchCollectionScheduleReport>;
  public batchCollectionScheduleReport$: Observable<BatchCollectionScheduleReport[]>;
  public subscription: Subscription = new Subscription();
  total$: Observable<number>;

  constructor(public service: TableService, private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService, private _reportService: ReportService, private _router: Router) {
      this.createSearchCriteriaForm();

    // this.batchCollectionScheduleReport$ = service.tableItem$;
    // this.total$ = service.total$;
  }

  createSearchCriteriaForm() {
    this.searchCriteriaForm = this.formBuilder.group({
      start_date: [''],
      end_date: ['']
    })
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  ngOnInit(): void {
    let batchCollectionScheduleRequest: BatchCollectionScheduleRequest = {}

    this.getBatchCollectionScheduleReport(batchCollectionScheduleRequest);
  }

  async getBatchCollectionScheduleReport(batchCollectionScheduleRequest: BatchCollectionScheduleRequest) {
    this.isLoading = true;
    this.spinner.show();
    this._reportService.getBatchCollectionScheduleReport(batchCollectionScheduleRequest).subscribe(data => {
      this.recurringCollectionScheduleItems = data.recurringCollectionScheduleItems;
      this.isLoading = false;
      this.spinner.hide();
    });
  }

  // this.recurringPaymentTransactions = data;
  // this.isLoading = false;
  // this.spinner.hide();
  // this.active = 2;

  navigateToBatchCollectionSummary(collectionBatchNumber: string) {
    const navigationExtras: NavigationExtras = {
      state: { data: collectionBatchNumber }
    };

    this._router.navigate(['/reports/batch-collection-summary'], navigationExtras)
      .catch(error => console.log('Navigation error:', error));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.service.reset();
    }
  }

}

