<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Merchant Details</h5>
        </div>
        <div class="card-body vendor-table">
            <div id="merchants" class="custom-datatable vendor-list">
                <div *ngIf="isLoading">
                    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
                        type="ball-scale-multiple" [fullScreen]="false">
                        <br />
                        <br />
                        <br />
                        <p style="color: white"> Loading ... </p>
                    </ngx-spinner>
                </div>
                <div class="table-responsive ">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" sortable="merchantName" (sort)="onSort($event)">Merchant Name</th>
                                <th scope="col" sortable="companyRegistrationNumber" (sort)="onSort($event)">Company
                                    Registration Number</th>
                                <th scope="col" sortable="primaryContactFullname" (sort)="onSort($event)">Primary
                                    Contact Fullname</th>
                                <th scope="col" sortable="primaryContactMobile" (sort)="onSort($event)">Primary Contact
                                    Mobile</th>
                                <th scope="col" sortable="primaryContactEmail" (sort)="onSort($event)">Primary Contact
                                    Email Address</th>
                                <th scope="col">Edit</th>
                                <th scope="col">Masquerade</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of merchants">
                                <!-- <td scope="row">
                                    <img [src]="item.vendor" class="imgTable" style="width: 20px">
                                   <span>{{item.name}}</span> 
                                </td> -->
                                <td>{{ item.merchantName }}</td>
                                <td>{{ item.merchantRegistration }}</td>
                                <td>{{ item.accountContactName }}</td>
                                <td>{{ item.accountContactNumber }}</td>
                                <td>{{ item.accountContactEmail }}</td>
                                <td>
                                    <a href="javascript:void(0)" (click)="navigateToUpdateMerchant(item, item.merchantId)"><i class='fa fa-edit f-12'></i></a>
                                </td>
                                <td>
                                    <a href="javascript:void(0)" (click)="masqueradeMerchant(item.rootUser.emailAddress, item.merchantName)"><i class="fa fa-sign-in f-12"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-center p-2">
                        <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="10">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->