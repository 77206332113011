<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Recurring Transaction Administration</h5>
        </div>
        <div class="card-body tab2-card">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>Initiate Transaction</a>
                    <div *ngIf="isLoading">
                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple"
                            [fullScreen]="false">
                            <br />
                            <br />
                            <br />
                            <p style="color: white"> Loading ... </p>
                        </ngx-spinner>
                    </div>
                    <ng-template ngbNavContent>
                        <form [formGroup]="recurringCardTransactionForm" class="needs-validation" novalidate="">
                            <h4></h4>
                            <div class="row">
                                <div class="col-sm-5">
                                    <h4>Debtor Details</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom0"><span>*</span>
                                                Name</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="debtorName"
                                                id="validationCustom0" type="text" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom1"><span>*</span>
                                                Surname</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="debtorSurname"
                                                id="validationCustom1" type="text" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom1"><span>*</span>
                                                Contact Number</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="debtorContactNumber"
                                                id="validationCustom1" type="text" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom1"><span>*</span>
                                                Email</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="debtorEmailAddress"
                                                id="validationCustom1" type="text" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom1"><span>*</span>
                                                Debtor Identifier</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="debtorIdentifier"
                                                id="validationCustom1" type="text" required="">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-5">
                                    <h4>Collection Details</h4>
                                    <div class="form-group row">
                                        <div class="col-xl-5 col-md-6">
                                            <label for="validationCustom1"><span>*</span>
                                                Collection Type</label>
                                        </div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select" formControlName="collectionType">
                                                <option value="">-- Select Collection Type --</option>
                                                <option value="Fixed">Fixed</option>
                                                <option value="Variable">Variable</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-5 col-md-6">
                                            <label for="validationCustom1"><span>*</span>
                                                Collection Frequency</label>
                                        </div>
                                        <div class="col-md-7">
                                            <select class="custom-select form-select"
                                                formControlName="collectionFrequency">
                                                <option value="">-- Select Collection Frequency --</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Weekly">Weekly</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-5 col-md-6">
                                            <label for="validationCustom1"><span>*</span>
                                                Instalment Day</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="instalmentDay"
                                                id="validationCustom1" type="number" min="0" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-5 col-md-6">
                                            <label for="validationCustom1"><span>*</span>
                                                Instalment Amount</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="instalmentAmount"
                                                id="validationCustom1" type="number" min="0" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-5 col-md-6">
                                            <label for="validationCustom1"><span>*</span>
                                                Instalment Count</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control" formControlName="instalmentCount"
                                                id="validationCustom1" type="number" min="0" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-7">
                                            <div class="checkbox checkbox-primary">
                                                <input id="collectionAutoRerun" type="checkbox"
                                                    formControlName="collectionAutoRerun" data-original-title=""
                                                    title="">
                                                <label for="collectionAutoRerun">Collection Auto Rerun</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-7">
                                            <div class="checkbox checkbox-primary">
                                                <input id="dynamicPayday" type="checkbox"
                                                    formControlName="dynamicPayday" data-original-title="" title="">
                                                <label for="dynamicPayday">Allow Dynamic Payday</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-7">
                                            <div class="checkbox checkbox-primary">
                                                <input id="earlyDecemberCollection" type="checkbox"
                                                    formControlName="earlyDecemberCollection" data-original-title=""
                                                    title="">
                                                <label for="earlyDecemberCollection">Allow Early December
                                                    Collection</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="pull-right">
                            <button type="button" class="btn btn-primary"
                                (click)="initiateRecurringTransaction()">Submit</button>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>Batch Upload</a>
                    <ng-template ngbNavContent>
                        <form class="needs-validation" novalidate>
                            <h4>File Upload</h4>
                            <div class="form-group row">
                                <div class="col-xl-3 col-md-4">
                                    <label>Please upload the batch file downloaded from the template section.</label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <div class="card-body dropzone-custom p-0">
                                        <ngx-dropzone class="dropzone-border" (change)="onSelect($event)">
                                            <ngx-dropzone-label>
                                                <div class="dz-message needsclick">
                                                    <i class="fa fa-cloud-upload"></i>
                                                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                                </div>
                                            </ngx-dropzone-label>
                                            <ngx-dropzone-preview *ngFor="let file of files" [removable]="true"
                                                (removed)="onRemove(file)">
                                                <ngx-dropzone-label>{{ file.name }} ({{ file.type }})</ngx-dropzone-label>
                                            </ngx-dropzone-preview>
                                        </ngx-dropzone>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="pull-right">
                            <button type="button" class="btn btn-primary" (click)="uploadFile()">Upload</button>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->