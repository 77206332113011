export class MerchantCollectionSettings {
    public collectionRunTime: string;
    public collectionResubmissionRunTime: string;
    public collectionDay: number;
    public collectionFrequency: string;
    public collectionAutoRun: boolean;
    public collectionRerunAttempts: number;
    public collectionAllowDynamicPayday: boolean;
    public earlyDecemberCollection: boolean;
    public earlyDecemberDay: number;
}
