import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { jwtDecode } from 'jwt-decode';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { MasqueradeRequestModel } from 'src/app/core/models/auth/masquerade-request.model';
import { RequestUserSearchParameters } from 'src/app/core/models/common/request-parameters.model';
import { UserListModel, UserSummaryModel } from 'src/app/core/models/user/user-list.model';
import { AuthService, MerchantService, UserService } from 'src/app/core/services';
import { MasqueradeAction } from 'src/app/core/store/actions/auth.action';
import { GetUsersAction } from 'src/app/core/store/actions/user.action';
import { UsersState } from 'src/app/core/store/state/users.state';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { NavService } from 'src/app/shared/service/nav.service';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {
  public userList: UserListModel;
  public users$: Observable<UserSummaryModel[]>;

  public isLoading: boolean = false;

  public tableItem$: Observable<UserSummaryModel[]>;
  public searchText;
  total$: Observable<number>;
  public subscription: Subscription;
  //  public merchants: MerchantModel[] = [];

  @Select(UsersState.selectedUsersByMerchant) selectedUsers$: Observable<UserListModel>;
  constructor(public service: TableService, private store: Store,
    private _authService: AuthService,
    private _merchantService: MerchantService,
    private _userService: UserService,
    private _router: Router,
    private spinner: NgxSpinnerService) {
    this.searchUserDeactivations();

    // this.subscription = new Subscription();
    // this.subscription = this.selectedUsers$.subscribe(data => {
    //   this.userList = data;
    //   if (this.userList != null) {
    //     this.users$ = service.tableItem$;
    //     this.total$ = service.total$;
    //     this.service.setUserData(this.userList);
    //   }
    // });
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  searchUserDeactivations() {
    let requestUserSearchParameters: RequestUserSearchParameters = {
      searchTerm: "",
      limit: 50,
      offset: 0,
      sort: "",
      sortField: ""
    }

    this.store.dispatch(new GetUsersAction(requestUserSearchParameters));
  }

  ngOnInit() {
    this.retrieveUsers();
  }

  retrieveUsers() {
    this.isLoading = true;
    this.spinner.show();
    this._userService.getAllUsers().subscribe(data => {
      this.userList = data;
      this.isLoading = false;
      this.spinner.hide();
    });
  }

  extractSubFromToken(): string | null {
    try {
      const decodedToken: any = jwtDecode(this._authService.getToken());

      return decodedToken.sub || null;
    } catch (error) {
      console.error('Invalid token:', error);
      return null;
    }
  }

  masqueradeUser(targetUsername: string, merchantName: string) {
    this.isLoading = true;
    this.spinner.show();

    const superAdminUsername = this.extractSubFromToken();

    // Create the request model
    let masqueradeRequestModel: MasqueradeRequestModel = {
      superAdminUsername: superAdminUsername,
      targetUsername: targetUsername
    };

    this._merchantService.storeMerchantName(merchantName);

    // Dispatch the MasqueradeAction to handle the masquerading logic
    this.store.dispatch(new MasqueradeAction(masqueradeRequestModel)).subscribe({
      next: () => {
        // Once the action completes successfully, stop the spinner
        this.isLoading = false;
        this.spinner.hide();
      },
      error: (err) => {
        // Handle any errors that occur during the masquerading action
        console.error('Error during masquerade:', err);
        this.isLoading = false;
        this.spinner.hide();
        // Optionally, show an error notification to the user
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}

