import { MerchantCollectionSettings } from "./merchant-collection-settings.model";

export class MerchantModel {
    public merchantName: string;
    public merchantVat: string;
    public merchantRegistration: string;
    public merchantAddress: string;
    public accountContactName: string;
    public accountContactNumber: string;
    public accountContactMobile: string;
    public accountContactEmail: string;
    public merchantBankMid: string;
    public merchantSetupType: string;
    public merchantSettleUsersDirectly: boolean;
    public merchantRequiresBatchSettlementReport: boolean;
    public merchantSetupFeePaid: boolean;
    public merchantSettlementsSuspended: boolean;
    public merchantPaysByDebit: boolean;
    public merchantSendSmsOnline: boolean;
    public enableEcommerce: boolean;
    public enableRecurring: boolean;

    public rootUser: RootUser; // Adjusted from rootUser
    public settlementBankAccount: SettlementBankAccount;
    public merchantFeeRates: MerchantFeeRates;
    public ecommerceBankSetup: EcommerceBankSetup; // New property
    public posBankSetup: PosBankSetup; // New property
    public merchantEcommerceGateway: MerchantGateway; // New property
    public merchantRecurringGateway: MerchantGateway; // New property
    public merchantCollectionSettings: MerchantCollectionSettings; // Updated type as needed
}

// Define the sub-models
export class RootUser {
    public title: string;
    public firstname: string;
    public lastname: string;
    public emailAddress: string;
    public identityNumber: string;
    public contactNumber1: string;
    public password: string; // New property
    public portalLink: string; // New property
}

export class SettlementBankAccount {
    public bankName: string;
    public accountNumber: string;
    public accountType: string;
    public branchCode: string;
}

export class MerchantFeeRates {
    public setupFee: number;
    public subscriptionFee: number;
    public posRentalFee: number;
    public smsFee: number;
    public userFee: number;
    public authorisationFee: number;
    public tokenisationFee: number; // New property
    public refundFee: number; // New property
    public riskScreeningFee: number; // New property
    public recurringTransactionFee: number;
    public visaCreditCardEcommerceRate: number;
    public visaDebitCardEcommerceRate: number;
    public mastercardCreditCardEcommerceRate: number;
    public mastercardDebitCardEcommerceRate: number;
    public amexCardEcommerceRate: number;
    public dinersCardEcommerceRate: number;
    public visaCreditCardPosRate: number;
    public visaDebitCardPosRate: number;
    public mastercardCreditCardPosRate: number;
    public mastercardDebitCardPosRate: number;
    public amexCardPosRate: number;
    public dinersCardPosRate: number;
}

export class EcommerceBankSetup {
    public bankMerchantId: string;
    public merchantType: string;
    public merchantShortname: string;
    public subMerchantIdentifier: string;
    public mccCode: string;
}

export class PosBankSetup {
    public bankMerchantId: string;
    public merchantType: string;
    public merchantShortname: string;
    public subMerchantIdentifier: string;
    public mccCode: string;
}

export class MerchantGateway {
    public gateway: string;
    public gatewayUrl: string;
    public gatewayKey: string;
    public gatewayToken: string;
}