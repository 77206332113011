import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { data } from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserResponseModel } from 'src/app/core/models/user/user-response.model';
import { UserModel } from 'src/app/core/models/user/user.model';
import { UserService } from 'src/app/core/services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  public userForm: UntypedFormGroup;
  public permissionForm: UntypedFormGroup;
  public active = 1;

  public userResponseModel: UserResponseModel;

  public isLoading: boolean = false;
  public isSubmitted: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, private userService: UserService
    , private spinner: NgxSpinnerService) {
    this.createUserForm();
    this.createPermissionForm();
  }

  createUserForm() {
    this.userForm = this.formBuilder.group({
      collectionType: new FormControl(""),
      title: new FormControl(""),
      firstname: new FormControl(""),
      lastname: new FormControl(""),
      identityNumber: new FormControl(""),
      contactNumber: new FormControl(""),
      mobile: new FormControl(""),
      email: new FormControl(""),
      bankName: new FormControl(""),
      bankAccountNumber: new FormControl(""),
      bankAccountType: new FormControl(""),
      bankBranchCode: new FormControl(""),
      authSettle: new FormControl(""),
      address: new FormControl("")
    })
  }

  get userRequestDetails() {
    return this.userForm.controls;
  }

  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    })
  }

  ngOnInit() {
  }

  createUser() {
    this.isSubmitted = true;
    this.isLoading = true;

    this.spinner.show();

    const userModel = new UserModel();
    const userRequest = this.userRequestDetails;

    userModel.userType = userRequest.userType.value;
    userModel.merchant = userRequest.merchant.value;
    userModel.title = userRequest.title.value;
    userModel.firstName = userRequest.firstname.value;
    userModel.lastName = userRequest.lastname.value;
    userModel.identityNumber = userRequest.identityNumber.value;
    userModel.contactNumber1 = userRequest.contactNumber1.value;
    userModel.mobile = userRequest.mobile.value;
    userModel.email = userRequest.email.value;
    userModel.password = Math.random().toString(36).slice(-8); // Assuming password is part of the request
    userModel.active = true;
    userModel.userBankRequest = {
      bankName: userRequest.bankName.value,
      bankAccountNumber: userRequest.bankAccountNumber.value,
      bankAccountType: userRequest.bankAccountType.value,
      bankBranchCode: userRequest.bankBranchCode.value
    };
    userModel.accountLocked = false;
    userModel.authSettle = userRequest.authSettle.value;
    userModel.waiveUserCharge = false;
    userModel.settlementsSuspended = false;
    userModel.address = userRequest.address.value;
    userModel.portalLink = "https://devclick2pay.c2p-hub.co.za/";

    this.userService.createUser(userModel).subscribe({
      next: (data: UserResponseModel) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();

        if (data.success) {
          Swal.fire({
            icon: "success",
            title: "User Created Successfully!",
            text: data.message
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data.message
          });
        }
      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorResponse
        });
      }
    });
  }

  clearUserForm() {
    this.userForm.setValue({
      collectionType: "",
      title: "",
      firstname: "",
      lastname: "",
      identityNumber: "",
      contactNumber: "",
      mobile: "",
      email: "",
      bankName: "",
      bankAccountNumber: "",
      bankAccountType: "",
      bankBranchCode: "",
      authSettle: ""
    });
  }

}
