import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { DebtorSummaryTransactionParameters } from 'src/app/core/models/common/request-parameters.model';
import { RecurringPaymentTransactionReportModel } from 'src/app/core/models/recurring/recurring-payment-transaction-report.model';
import { RecurringService } from 'src/app/core/services';
import { GetRecurringPaymentTransactionReportAction, SelectRecurringPaymentTransactionReportAction } from 'src/app/core/store/actions/recurring.action';
import { RecurringPaymentTransactionReportState, RecurringPaymentTransactionReportStateModel } from 'src/app/core/store/state/recurring-payment-transaction-report.state';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-recurring-transaction-report',
  templateUrl: './recurring-transaction-report.component.html',
  styleUrls: ['./recurring-transaction-report.component.scss']
})
export class RecurringTransactionReportComponent implements OnInit, OnDestroy {

  public isLoading: boolean = false;

  public searchCriteriaForm: UntypedFormGroup;
  public date: { year: number, month: number };
  public active = 1;

  public recurringPaymentTransactions: RecurringPaymentTransactionReportModel[] = [];
  public searchText;
  public tableItem$: Observable<RecurringPaymentTransactionReportModel[]>;
  public recurringPaymentTransactionReport$: Observable<RecurringPaymentTransactionReportModel[]>;
  public subscription: Subscription = new Subscription();
  total$: Observable<number>;

  @Select(RecurringPaymentTransactionReportState.selectedRecurringPaymentTransactionReport) selectedTransactionReport$: Observable<RecurringPaymentTransactionReportModel[]>;

  constructor(private formBuilder: UntypedFormBuilder, public service: TableService, private store: Store,
    private _recurringService: RecurringService,  private spinner: NgxSpinnerService) {
    this.createSearchCriteriaFormorm();

    this.recurringPaymentTransactionReport$ = service.tableItem$;
    this.total$ = service.total$;
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  createSearchCriteriaFormorm() {
    this.searchCriteriaForm = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
    })
  }

  retrieveTransactionReport() {
    let debtorSummaryTransactionParameters: DebtorSummaryTransactionParameters = {
      startDate: '2024-03-25',
      endDate: '2024-06-10',
    }

    this.store.dispatch(new GetRecurringPaymentTransactionReportAction(debtorSummaryTransactionParameters));
  }

  get searchRecurringReport() {
    return this.searchCriteriaForm.controls;
  }

  search() {
    const recurringReport = this.searchRecurringReport;
    let startDate = recurringReport.start_date.value;
    let endDate = recurringReport.end_date.value;

    let debtorSummaryTransactionParameters: DebtorSummaryTransactionParameters = {
      startDate: "" + startDate.year + "-" + startDate.month.toString().padStart(2, '0') + "-" + startDate.day.toString().padStart(2, '0'),
      endDate: "" + endDate.year + "-" + endDate.month.toString().padStart(2, '0') + "-" + endDate.day.toString().padStart(2, '0'),
    }

    this.getRecurringReport(debtorSummaryTransactionParameters);
  }

  ngOnInit() {
   // this.retrieveTransactionReport();
   var currentDate = new Date();

   let defaultDate = "" + currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1).toString().padStart(2, '0') + "-" + currentDate.getDate().toString().padStart(2, '0');

   let debtorSummaryTransactionParameters: DebtorSummaryTransactionParameters = {
     startDate: '2024-05-01',
     endDate: defaultDate
   }

   this.getRecurringReport(debtorSummaryTransactionParameters);
  }

  async getRecurringReport(debtorSummaryTransactionParameters: DebtorSummaryTransactionParameters) {
    this.isLoading = true;
    this.spinner.show();
    this._recurringService.getRecurringPaymentTransactionReport(debtorSummaryTransactionParameters).subscribe(data => {
      this.recurringPaymentTransactions = data;
      this.isLoading = false;
      this.spinner.hide();
      this.active = 2;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.service.reset();
    }
  }
}
