import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable, Subject } from 'rxjs';
import { config } from '../app.config';
import { AuthenticationRequestModel } from '../models/auth/authentication-request.model';
import { AuthenticationResponseModel } from '../models/auth/authentication-response.model';
import { MasqueradeRequestModel } from '../models/auth/masquerade-request.model';
import { GetMerchantAction } from '../store/actions/merchant.action';
import { StorageService } from './storage.service';
import { jwtDecode } from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public token: string;
  public loginState = new Subject<boolean>();

  constructor(private http: HttpClient, private store: Store,
    private storageService: StorageService) {
    this.isUserLoggedIn().subscribe(
      {
        next: (data) => {
          if (data == true)
            this.loadUserData();
          else
            this.loginState.next(false);

        },
        error: (data) => {
          this.loginState.next(false);
        }

      }
    )
  }

  getToken() {
    return this.token;
  }

  isAuthenticated() {
    return this.storageService.get("isUserLoggedIn") === 'true';
  }

  isSuperAdmin() {
    return this.storageService.get("role") === 'SAD';
  }

  isMerchantAdmin() {
    return this.storageService.get("role") === 'MAD';
  }

  isNormalUser() {
    return this.storageService.get("role") === 'USR';
  }

  enableEcommerce() {
    return this.storageService.get("enableEcommerce");
  }

  enableRecurring() {
    return this.storageService.get("enableRecurring");
  }

  loadUserData() {
    let that = this;
    this.loginState.next(true);
    var data: any = this.storageService.get("token");
    this.token = data;
    that.loginState.next(true);
  }

  isUserLoggedIn(): Observable<boolean> {
    return new Observable(subscriber => {
      var test = this.storageService.get("isUserLoggedIn") === 'true';
      subscriber.next(test);
    })

  }

  extractSubFromToken(token: string): string | null {
    try {
      // Decode the JWT
      const decodedToken: any = jwtDecode(token);

      // Return the 'sub' claim
      return decodedToken.sub || null;
    } catch (error) {
      console.error('Invalid token:', error);
      return null;
    }
  }


  public login(authenticationRequestModel: AuthenticationRequestModel): Observable<AuthenticationResponseModel> {
    return new Observable(subscriber => {
      this.http.post<AuthenticationResponseModel>(config.C2PAPI + '/authentication/token', authenticationRequestModel)
        .pipe(
          map(response => {
            console.log(response);
            return response;
          })
        )
        .subscribe({
          next: (data: AuthenticationResponseModel) => {
            if ((data == null) || (data == undefined))
              return;

            this.token = data.accessToken;
            this.storageService.set("token", this.token);
            this.storageService.set("isUserLoggedIn", "true");
            this.storageService.set("role", data.role);
            if (data.role == 'MAD') {
              this.store.dispatch(new GetMerchantAction());
            }

            this.loginState.next(true);
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public masquerade(masqueradeRequestModel: MasqueradeRequestModel): Observable<AuthenticationResponseModel> {
    return new Observable(subscriber => {
      this.http.post<AuthenticationResponseModel>(config.C2PAPI + '/authentication/masquerade', masqueradeRequestModel)
        .pipe(
          map(response => {
            console.log(response);
            return response;
          })
        )
        .subscribe({
          next: (data: AuthenticationResponseModel) => {
            if ((data == null) || (data == undefined))
              return;

            this.token = data.accessToken;
            this.storageService.set("token", this.token);
            this.storageService.set("isUserLoggedIn", "true");
            this.storageService.set("role", data.role);
            if (data.role == 'MAD') {
              this.store.dispatch(new GetMerchantAction());
            }

            this.loginState.next(true);
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }
}
