import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Router } from '@angular/router';
import { BillingInvoiceService } from '../../services';
import { BillingInvoiceModel } from '../../models/billing-invoice/billing-invoice.model';
import { GetBillingInvoiceAction, GetBillingInvoiceDetailAction, SelectBillingInvoiceAction, SelectBillingInvoiceDetailAction } from '../actions/billing-invoice.action';
import { BillingInvoiceDetailsModel } from '../../models/billing-invoice/billing-invoice-details.model';

export class BillingInvoiceDetailStateModel {
    public billingInvoiceDetail: BillingInvoiceDetailsModel;
}

@State<BillingInvoiceDetailStateModel>({
    name: 'billingInvoiceDetail',
    defaults: {
        billingInvoiceDetail: null
    }
})

@Injectable()
export class BillingInvoiceDetailState {

    constructor(private _billingInvoiceService: BillingInvoiceService,
        private _router: Router) {
    }

    @Selector()
    public static selectedBillingInvoiceDetail(state: BillingInvoiceDetailStateModel) {
        return state.billingInvoiceDetail;
    }

    @Action(SelectBillingInvoiceDetailAction)
    public selectBillingInvoiceDetailAction(ctx: StateContext<BillingInvoiceDetailStateModel>, action: SelectBillingInvoiceDetailAction) {
        ctx.patchState({ billingInvoiceDetail: action.data });
    }

    @Action(GetBillingInvoiceDetailAction)
    public getBillingInvoiceDetailAction(ctx: StateContext<BillingInvoiceDetailStateModel>, action: GetBillingInvoiceDetailAction) {
        return this._billingInvoiceService.getBillingInvoiceDetails(action.invoiceNumber).subscribe(data => {
            ctx.patchState({ billingInvoiceDetail: data });
            this._router.navigate(["billing/billing-invoice-detail"]);
        });
    }

}
