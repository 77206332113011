<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Bank Settlements</h5>
                </div>
                <div class="card-body tab2-card">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>Select Account</a>
                            <ng-template ngbNavContent>
                                <div class="row mb-3">
                                    <div class="col-xl-4 col-sm-6">
                                        <input type='text' class="filter-ngx form-control" placeholder='Search...'
                                            [(ngModel)]="searchText" autocomplete="off" />
                                    </div>
                                    <div class="col-xl-2 col-sm-4">
                                        <div class="btn-popup">
                                            <a class="btn btn-primary">Search</a>
                                        </div>
                                    </div>
                                </div>
                                <div id="bankSettlementAccounts"
                                    class="category-table custom-datatable transcation-datatable">
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col" sortable="accountName" (sort)="onSort($event)">
                                                        Account Name
                                                    </th>
                                                    <th scope="col">Select</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Avroy Shlain - Sandya Campbell</td>
                                                    <td>
                                                        <a><i class='fa fa-plus-circle f-12'></i></a>&nbsp;
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Ntsele BB - Brandon Sandiford</td>
                                                    <td>
                                                        <a><i class='fa fa-plus-circle f-12'></i></a>&nbsp;
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="d-flex justify-content-center p-2">
                                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                                [pageSize]="service.pageSize" [maxSize]="10">
                                            </ngb-pagination>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>Add Settlement</a>
                            <ng-template ngbNavContent>
                                <form [formGroup]="creditSettlementForm" class="needs-validation" novalidate="">
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <div class="row">
                                                <div class="col-xl-3 col-md-4">
                                                    <label for="validationCustom3">Account Name:</label>
                                                </div>
                                                <div class="col-md-7">
                                                    <h5>Avroy Shlain - Sandya Campbell</h5>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-3 col-md-4">
                                                    <label for="validationCustom3">Account Number:</label>
                                                </div>
                                                <div class="col-md-7">
                                                    <h5>4075977591</h5>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-3 col-md-4">
                                                    <label for="validationCustom3">Branch Code:</label>
                                                </div>
                                                <div class="col-md-7">
                                                    <h5>632005</h5>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-3 col-md-4">
                                                    <label for="validationCustom3">Settlement Description:</label>
                                                </div>
                                                <div class="col-md-7">
                                                    <h5>27722671490-</h5>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-3 col-md-4">
                                                    <label for="validationCustom3">Date:</label>
                                                </div>
                                                <div class="col-md-7">
                                                    <h5>2024/08/12</h5>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4">
                                                    <label for="validationCustom3">Amount to Credit:</label>
                                                </div>
                                                <div class="col-md-7"><input class="form-control"
                                                        formControlName="amountToCredit" id="validationCustom3"
                                                        type="number" min="0" required="">
                                                </div>
                                                <div class="valid-feedback">Please Provide a Bank Reference.</div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="validationCustom3"></label>
                                                </div>
                                                <div class="col-md-7">
                                                    <button type="button" class="btn btn-primary">Add</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->