import { Component } from '@angular/core';

@Component({
  selector: 'app-unsettled-pos',
  templateUrl: './unsettled-pos.component.html',
  styleUrls: ['./unsettled-pos.component.scss']
})
export class UnsettledPosComponent {

}
