import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { NavService } from "src/app/shared/service/nav.service";
import { AuthenticationResponseModel } from "../../models/auth/authentication-response.model";
import { AuthService } from "../../services";
import { LoggedInAction, LogInAction } from "../actions/auth.action";
import { ScopeMerchantAction } from "../actions/merchant.action";
import { ScopeUserAction } from "../actions/user.action";

export class AuthStateModel {
  public isLoggedIn: boolean;
  public authenticationResponse: AuthenticationResponseModel;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    isLoggedIn: false,
    authenticationResponse: null
  }
})

@Injectable()
export class AuthState {

  constructor(private _authService: AuthService,
    private navServices: NavService,
    private _router: Router,
    private _store: Store,) {
  }

  @Selector()
  public static userLoggedIn(state: AuthStateModel) {
    return state;
  }

  @Selector()
  public static authRole(state: AuthStateModel) {
    return state.authenticationResponse.role;
  }

  @Action(LoggedInAction)
  public loggedInAction(ctx: StateContext<AuthStateModel>, action: LoggedInAction) {
    return this._authService.isUserLoggedIn().subscribe(data => {
      ctx.patchState({ isLoggedIn: action.data });
    })
  }

  @Action(LogInAction)
  public loginAction(ctx: StateContext<AuthStateModel>, action: LogInAction) {
    return this._authService.login(action.authRequest).subscribe({
      next: (data) => {
        ctx.patchState({ isLoggedIn: true });
        ctx.patchState({ authenticationResponse: data });

        const scope = data.scope ? data.scope.split('|') : [];
        const enableEcommerce = scope.includes('ECOM');
        const enableRecurring = scope.includes('RECU');

        switch (data.role) {
          case 'USR':
            this._store.dispatch(new ScopeUserAction(enableEcommerce, enableRecurring));
            break;

          case 'MAD':
            this._store.dispatch(new ScopeMerchantAction(enableEcommerce, enableRecurring));
            break;

          default:
            break;
        }

        this.navServices.updateMenuItems(data.role, enableEcommerce, enableRecurring);
        if (data.role === 'MAD' || data.role === 'USR') {
          const targetRoute = enableEcommerce
            ? '/transaction-processing/process-transactions'
            : '/transaction-processing/recurring-transactions';
          this._router.navigate([targetRoute]);
        } else {
          this._router.navigate(['/admin/create-merchant']);
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }
}
