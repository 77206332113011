import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { RecurringService } from 'src/app/core/services';

@Component({
  selector: 'app-eft-transactions',
  templateUrl: './eft-transactions.component.html',
  styleUrls: ['./eft-transactions.component.scss']
})
export class EftTransactionsComponent implements OnInit, OnDestroy {

  public eftCaptureTransactionForm: UntypedFormGroup;
  public eftUploadTransactionsForm: UntypedFormGroup;
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public modelFooter: NgbDateStruct;
  public active = 1;
  files: File[] = [];

  public subscription: Subscription = new Subscription();

  public isLoading: boolean = false;
  public isSubmitted: boolean = false;
  public requestIdentifier: string;
  public selectedCollectionFrequency: string = 'Monthly';

  constructor(private formBuilder: UntypedFormBuilder,
    public recurringService: RecurringService, private spinner: NgxSpinnerService) {
    this.captureEFTransactionForm();
    this.uploadTransactionForm();
  }

  captureEFTransactionForm() {
    this.eftCaptureTransactionForm = this.formBuilder.group({
      debtorReference: [''],
      debtorFullname: [''],
      identificationNumber: [''],
      identificationType: [''],
      accountNumber: [''],
      accountType: [''],
      bank: [''],
      mobileNumber: [''],
      emailAddress: [''],
      merchantReference: [''],
      collectionType: [''],
      collectionDate: [''],
      collectionAmount: [''],
    });
  }

  uploadTransactionForm() {
    this.eftUploadTransactionsForm = this.formBuilder.group({
      products: ['']
    })
  }

  ngOnInit(): void {
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
