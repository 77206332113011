import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { catchError, map, Observable, Subject, tap, throwError } from 'rxjs';
import { config } from '../app.config';
import { AuthenticationRequestModel } from '../models/auth/authentication-request.model';
import { AuthenticationResponseModel } from '../models/auth/authentication-response.model';
import { MasqueradeRequestModel } from '../models/auth/masquerade-request.model';
import { GetMerchantAction } from '../store/actions/merchant.action';
import { StorageService } from './storage.service';
import { jwtDecode } from 'jwt-decode';
import { MasqueradingAction } from '../store/actions/auth.action';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public token: string;
  public loginState = new Subject<boolean>();
  public masqueradeState = new Subject<boolean>();

  constructor(private http: HttpClient, private store: Store,
    private storageService: StorageService) {
    const handleLoginState = (data: boolean) => {
      if (data) {
        this.loadUserData();
      } else {
        this.loginState.next(false);
      }
    };

    // Subscribe to isUserLoggedIn and isMasquerading observables
    this.isUserLoggedIn().subscribe({
      next: handleLoginState,
      error: () => this.loginState.next(false),
    });

    this.isMasquerading().subscribe({
      next: handleLoginState,
      error: () => this.loginState.next(false),
    });
  }

  getToken() {
    return this.token;
  }

  isAuthenticated() {
    return this.storageService.get("isUserLoggedIn") === 'true';
  }

  isSuperAdmin() {
    return this.storageService.get("role") === 'SAD';
  }

  isMerchantAdmin() {
    return this.storageService.get("role") === 'MAD';
  }

  isNormalUser() {
    return this.storageService.get("role") === 'USR';
  }

  enableEcommerce() {
    return this.storageService.get("enableEcommerce");
  }

  enableRecurring() {
    return this.storageService.get("enableRecurring");
  }

  merchantName() {
    return this.storageService.get("merchantHome");
  }

  loadUserData() {
    let that = this;
    this.loginState.next(true);
    var data: any = this.storageService.get("token");
    this.token = data;
    that.loginState.next(true);
  }

  isUserLoggedIn(): Observable<boolean> {
    return new Observable(subscriber => {
      var test = this.storageService.get("isUserLoggedIn") === 'true';
      subscriber.next(test);
    })
  }

  isMasquerading(): Observable<boolean> {
    return new Observable(subscriber => {
      var test = this.storageService.get("isMasquerading") === 'true';
      subscriber.next(test);
    })
  }

  extractSubFromToken(token: string): string | null {
    try {
      // Decode the JWT
      const decodedToken: any = jwtDecode(token);

      // Return the 'sub' claim
      return decodedToken.sub || null;
    } catch (error) {
      console.error('Invalid token:', error);
      return null;
    }
  }


  public login(authenticationRequestModel: AuthenticationRequestModel): Observable<AuthenticationResponseModel> {
    return new Observable(subscriber => {
      this.http.post<AuthenticationResponseModel>(config.C2PAPI + '/authentication/token', authenticationRequestModel)
        .pipe(
          map(response => {
            console.log(response);
            return response;
          })
        )
        .subscribe({
          next: (data: AuthenticationResponseModel) => {
            if ((data == null) || (data == undefined))
              return;

            this.token = data.accessToken;
            this.storageService.set("token", this.token);
            this.storageService.set("isUserLoggedIn", "true");
            this.storageService.set("role", data.role);
            if (data.role == 'MAD') {
              this.store.dispatch(new GetMerchantAction());
            }

            if (data.role == 'SAD') {
              this.storageService.set("originalToken", this.token);
            }

            this.loginState.next(true);
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public masquerade(masqueradeRequestModel: MasqueradeRequestModel): Observable<AuthenticationResponseModel> {
    return this.http.post<AuthenticationResponseModel>(config.C2PAPI + '/authentication/masquerade', masqueradeRequestModel)
      .pipe(
        tap((data: AuthenticationResponseModel) => {
          if (data && data.accessToken) {
            // Update token and store relevant information
            this.token = data.accessToken;
            this.storageService.set("token", this.token);
            this.storageService.set("isUserLoggedIn", "false"); // User is not logged in directly
            this.storageService.set("isMasquerading", "true"); // Set masquerading state
            this.storageService.set("role", data.role);

            // Dispatch GetMerchantAction only if the user has the MAD role
            if (data.role === 'MAD') {
              this.store.dispatch(new GetMerchantAction());
            }

            // Update login state
            this.loginState.next(true);
          }
        }),
        catchError((error) => {
          console.error("Error in masquerade:", error);  // Log error for debugging

          this.loginState.next(false); // Reset login state on failure

          return throwError(() => new Error('Masquerading failed. Please try again later.'));
        })
      );
  }

  public cancelMasquerade(): boolean {
    // Retrieve the original super admin token stored during the login process
    const originalToken = this.storageService.get("originalToken");
  
    if (originalToken) {
      // Set the token back to the original super admin token
      this.storageService.set("token", originalToken);
  
      // Reset masquerading state
      this.storageService.set("isMasquerading", "false");
  
      // Set the role back to Super Admin
      this.storageService.set("role", 'SAD');
  
      // Dispatch the action to update the state in NGXS
      this.store.dispatch(new MasqueradingAction(false));
  
      // Update login state
      this.loginState.next(true);
  
      return true; // Successful cancellation
    } else {
      console.error('Original token not found');
      return false; // Failure to cancel masquerade
    }
  }   
}
