import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { config } from '../app.config';
import { DebtorSummaryTransactionParameters } from '../models/common/request-parameters.model';
import { DebtorSummaryItemModel } from '../models/recurring/debtor-summary-item.model';
import { DebtorSummaryTransactionsModel } from '../models/recurring/debtor-summary-transactions.model';
import { InitiateRecurringTransactionRequestModel } from '../models/recurring/initiate-recurring-transaction-request.model';
import { InitiateRecurringTransactionResponseModel } from '../models/recurring/initiate-recurring-transaction-response.model';
import { ModifyDebtorDetailRequest } from '../models/recurring/modify-debtor-detail-request.model';
import { ModifyDebtorDetailResponse } from '../models/recurring/modify-debtor-detail-response.model';
import { PaymentFormResponseModel } from '../models/recurring/payment-form-response.model';
import { PaymentStatusResponseModel } from '../models/recurring/payment-status-response.model';
import { RecurringCardTransactionRequestModel } from '../models/recurring/recurring-card-transaction-request.model';
import { RecurringCardTransactionResponseModel } from '../models/recurring/recurring-card-transaction-response.model';
import { RecurringPaymentTransactionReportModel } from '../models/recurring/recurring-payment-transaction-report.model';
import { InitiateCardTransactionRequestModel } from '../models/transaction/initiate-card-transaction-request.model';
import { SelectDebtorAdministrationSummaryAction, SelectRecurringPaymentTransactionReportAction } from '../store/actions/recurring.action';

@Injectable({
  providedIn: 'root'
})
export class RecurringService {

  constructor(@Inject(DOCUMENT) private document: any,
  private http: HttpClient,
  private store: Store) { }

  public initiateRecurringTransaction(initiateRecurringTransactionRequestModel: InitiateRecurringTransactionRequestModel): Observable<InitiateRecurringTransactionResponseModel> {
    return new Observable(subscriber => {
      this.http.post<InitiateRecurringTransactionResponseModel>(config.C2PAPI + '/recurring/create', initiateRecurringTransactionRequestModel)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: InitiateRecurringTransactionResponseModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getDebtorSummaryTransactions(debtorSummaryTransactionParameters: DebtorSummaryTransactionParameters): Observable<DebtorSummaryTransactionsModel> {
    return new Observable(subscriber => {
      this.http.post<DebtorSummaryTransactionsModel>(config.C2PAPI + '/recurring/debtors', debtorSummaryTransactionParameters)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: DebtorSummaryTransactionsModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getPaymentFormIdentifier(paymentFormRequestIdentifier: string): Observable<PaymentFormResponseModel> {
    return new Observable(subscriber => {
      this.http.get<PaymentFormResponseModel>(config.C2PAPI + '/recurring/formIdentifier?paymentFormRequestIdentifier=' + paymentFormRequestIdentifier)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: PaymentFormResponseModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getPaymentStatus(paymentIdentifier: string): Observable<PaymentStatusResponseModel> {
    return new Observable(subscriber => {
      this.http.get<PaymentStatusResponseModel>(config.C2PAPI + '/recurring/status?paymentIdentifier=' + paymentIdentifier)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: PaymentStatusResponseModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getDebtorAdministrationSummary(debtorSummaryTransactionParameters: DebtorSummaryTransactionParameters): Observable<Array<DebtorSummaryItemModel>> {
    return new Observable(subscriber => {
      this.http.post<Array<DebtorSummaryItemModel>>(config.C2PAPI + '/recurring/debtors', debtorSummaryTransactionParameters)
        .pipe(
          map(response => response || [])
        )
        .subscribe({
          next: (data: Array<DebtorSummaryItemModel>) => {
            subscriber.next(data);
            subscriber.complete(); 
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }
        }
        );
    });
  }

  public getRecurringPaymentTransactionReport(debtorSummaryTransactionParameters: DebtorSummaryTransactionParameters): Observable<Array<RecurringPaymentTransactionReportModel>> {
    return new Observable(subscriber => {
      this.http.get<Array<RecurringPaymentTransactionReportModel>>(config.C2PAPI + '/recurring/report?startDate=' + debtorSummaryTransactionParameters.startDate + "&endDate=" + debtorSummaryTransactionParameters.endDate)
        .pipe(
          map(response => response || [])
        )
        .subscribe({
          next: (data: Array<RecurringPaymentTransactionReportModel>) => {
            subscriber.next(data);
            //subscriber.complete(); 
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }
        }
        );
    });
  }

  public processRecurringTransaction(recurringCardTransactionRequestModel: RecurringCardTransactionRequestModel): Observable<RecurringCardTransactionResponseModel> {
    return new Observable(subscriber => {
      this.http.post<RecurringCardTransactionResponseModel>(config.C2PAPI + '/recurring/process', recurringCardTransactionRequestModel)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: RecurringCardTransactionResponseModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public modifyDebtorRegistration(modifyDebtorDetailRequest: ModifyDebtorDetailRequest): Observable<ModifyDebtorDetailResponse> {
    return new Observable(subscriber => {
      this.http.put<ModifyDebtorDetailResponse>(config.C2PAPI + '/recurring/update-debtor', modifyDebtorDetailRequest)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: ModifyDebtorDetailResponse) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getRecurringDebtorTransactions(merchantCustomerReference: string): Observable<Array<RecurringPaymentTransactionReportModel>> {
    return new Observable(subscriber => {
      this.http.get<Array<RecurringPaymentTransactionReportModel>>(config.C2PAPI + '/recurring/debtor-transactions?merchantCustomerReference=' + merchantCustomerReference)
        .pipe(
          map(response => response || [])
        )
        .subscribe({
          next: (data: Array<RecurringPaymentTransactionReportModel>) => {
            subscriber.next(data);
            subscriber.complete(); 
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }
        }
        );
    });
  }

}
