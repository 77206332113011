import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-recon-settlement-report',
  templateUrl: './recon-settlement-report.component.html',
  styleUrls: ['./recon-settlement-report.component.scss']
})
export class ReconSettlementReportComponent implements OnInit, OnDestroy {

  public searchCriteriaForm: UntypedFormGroup;
  public date: { year: number, month: number };
  public active = 1;

  public tableItem$: any[];
  public subscription: Subscription;
  public searchText;
  total$: Observable<number>;

  constructor(private formBuilder: UntypedFormBuilder, public service: TableService) {

  }

  ngOnInit(): void {
    this.createSearchCriteriaForm();
  }

  createSearchCriteriaForm() {
    this.searchCriteriaForm = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
    })
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  ngOnDestroy(): void {
  }

  search() {

  }

}
