<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <div class="pull-right">
                <button type="button" class="btn btn-primary">Mark selected as paid</button>
            </div>
        </div>
        <div class="card-body tab2-card">
            <br />
            <br />
            <div id="unpaidRecords" class="category-table custom-datatable transcation-datatable">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <!-- <th scope="col" sortable="name"></th> -->
                                <th scope="col" sortable="markPaid">Mark as Paid</th>
                                <th scope="col" sortable="invoiceNumber">Invoice Number</th>
                                <th scope="col" sortable="date">Date</th>
                                <th scope="col" sortable="total">Total</th>
                                <th scope="col" sortable="merchant">Merchant</th>
                                <th scope="col" sortable="user">User</th>
                                <th scope="col">Add / Edit Note</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">
                                    <input type="checkbox" name="markPaid" onclick="onSelect(item.id)">
                                </th>
                                <td>C2P-8031</td>
                                <td>23 August 2024</td>
                                <td>R247.38</td>
                                <td>African Duet Travel</td>
                                <td>N/A</td>
                                <td>
                                    <a href="javascript:void(0)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-center p-2">
                        <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="10">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->