import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable, Subscription, tap } from 'rxjs';
import { config } from '../app.config';
import { CreateMerchantResponse } from '../models/merchant/create-merchant-response.model';
import { MerchantModel } from '../models/merchant/merchant.model';
import { SelectMerchantAction } from '../store/actions/merchant.action';
import { StorageService } from './storage.service';

@Injectable()
export class MerchantService {

  constructor(@Inject(DOCUMENT) private document: any,
  private storageService: StorageService,
    private http: HttpClient, private store: Store) { }

  public getMerchantDetails(): Observable<MerchantModel> {
    return new Observable<MerchantModel>(subscriber => {
      const subscription: Subscription = this.http.get<MerchantModel>(config.C2PAPI + '/merchant')
        .pipe(
          tap(response => console.log('Received merchant data:', response)),
        )
        .subscribe({
          next: (data: MerchantModel) => {
            if (data) {
              this.storageService.set("enableEcommerce", data.enableEcommerce.toString());
              this.storageService.set("enableRecurring", data.enableRecurring.toString());
              subscriber.next(data);
            }
          },
          error: (errorResponse: any) => {
            console.error('Error fetching merchant data:', errorResponse);
          }
        });

      // Return the subscription for potential unsubscription
      return () => {
        subscription.unsubscribe();
      };
    });
  }

  public getMerchants(): Observable<Array<MerchantModel>> {
    return new Observable(subscriber => {
      this.http.get<Array<MerchantModel>>(config.C2PAPI + '/merchant/list')
        .pipe(
          map(response => response || [])
        )
        .subscribe({
          next: (data: Array<MerchantModel>) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }
        }
        );
    });
  }

  public createMerchant(createMerchantRequest: MerchantModel): Observable<CreateMerchantResponse> {
    return new Observable(subscriber => {
      this.http.post<CreateMerchantResponse>(config.C2PAPI + '/merchant', createMerchantRequest)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: CreateMerchantResponse) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public updateMerchant(updateMerchantRequest: MerchantModel): Observable<CreateMerchantResponse> {
    return new Observable(subscriber => {
      this.http.patch<CreateMerchantResponse>(config.C2PAPI + '/merchant?merchantId=' + updateMerchantRequest.merchantId, updateMerchantRequest)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: CreateMerchantResponse) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }
}
