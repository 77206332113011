export class UserModel {
  public userType?: string;
  public merchant?: string;
  public title?: string;
  public firstName?: string;
  public lastName?: string;
  public identityNumber?: string;
  public contactNumber1?: string;
  public contactNumber?: string;
  public mobile?: string;
  public email?: string;
  public password?: string;
  public active: boolean;
  public userBankRequest?: UserBankRequest;
  public accountLocked: boolean;
  public authSettle: boolean;
  public waiveUserCharge: boolean;
  public settlementsSuspended: boolean; // Added based on your new structure
  public address: string; // Added based on your new structure
  public portalLink: string; // Added based on your new structure
}

// Define the sub-model for bank details
export class UserBankRequest {
  public bankName: string;
  public bankAccountNumber: string;
  public bankAccountType: string;
  public bankBranchCode: string;
}
