<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Subscriptions and Billings</h5>
                </div>
                <div class="card-body tab2-card">
                    <div>
                        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                            <li [ngbNavItem]="1">
                                <a ngbNavLink>Run Billing</a>
                                <ng-template ngbNavContent>
                                    <form [formGroup]="searchCriteriaForm" class="needs-validation user-add"
                                        novalidate="">
                                        <div class="permission-block">
                                            <div class="attribute-blocks">
                                                <h5 class="f-w-600 mb-3">Select Start and End dates for the Billing
                                                    Period:
                                                </h5>
                                                <div class="row mb-3">
                                                    <div class="col-xl-1 col-sm-2">
                                                        <h5>From</h5>
                                                    </div>
                                                    <div class="col-xl-4 col-sm-6">
                                                        <div class="input-group input-grp-p">
                                                            <input class="datepicker-here form-control digits"
                                                                ngbDatepicker formControlName="start_date"
                                                                #f="ngbDatepicker">
                                                            <div class="input-group-append">
                                                                <button class="btn calendar" (click)="f.toggle()"
                                                                    type="button"><i
                                                                        class="fa fa-calendar"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-1 col-sm-2">
                                                        <h5>To</h5>
                                                    </div>
                                                    <div class="col-xl-4 col-sm-6">
                                                        <div class="input-group input-grp-p">
                                                            <input class="datepicker-here form-control digits"
                                                                ngbDatepicker formControlName="end_date"
                                                                #c="ngbDatepicker">
                                                            <div class="input-group-append">
                                                                <button class="btn calendar" (click)="c.toggle()"
                                                                    type="button"><i
                                                                        class="fa fa-calendar"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="pull-right">
                                        <button type="button" class="btn btn-primary">Run Monthly Billing</button>
                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                                <a ngbNavLink>Previous Billings</a>
                                <ng-template ngbNavContent>
                                    <div class="btn-popup pull-right">
                                        <a class="btn btn-primary">Refresh</a>
                                    </div>
                                    <div id="transactionReport"
                                        class="category-table custom-datatable transcation-datatable">
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" sortable="billingPeriodId"
                                                            (sort)="onSort($event)">
                                                            Billing Period Id
                                                        </th>
                                                        <th scope="col" sortable="startDate" (sort)="onSort($event)">
                                                            Start Date</th>
                                                        <th scope="col" sortable="endDate" (sort)="onSort($event)">End
                                                            Date</th>
                                                        <th scope="col" sortable="subTotal" (sort)="onSort($event)">
                                                            Subtotal</th>
                                                        <th scope="col" sortable="vat" (sort)="onSort($event)">VAT</th>
                                                        <th scope="col" sortable="total" (sort)="onSort($event)">Total
                                                        </th>
                                                        <th scope="col">View Invoice</th>
                                                        <th scope="col">Resend Invoice</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>217</td>
                                                        <td>29 Dec 17</td>
                                                        <td>25 Jan 18</td>
                                                        <td>R27875.30</td>
                                                        <td>R3902.54</td>
                                                        <td>R31777.84</td>
                                                        <td>
                                                            <a><i class='fa fa-file-text-o f-12'></i></a>&nbsp;
                                                        </td>
                                                        <td>
                                                            <a><i class='fa fa-share-square-o f-12'></i></a>&nbsp;
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="d-flex justify-content-center p-2">
                                                <ngb-pagination [collectionSize]="(total$ | async)!"
                                                    [(page)]="service.page" [pageSize]="service.pageSize"
                                                    [maxSize]="10">
                                                </ngb-pagination>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav" class="mt-2"></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->