import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { BillingInvoiceDetailsModel } from 'src/app/core/models/billing-invoice/billing-invoice-details.model';
import { BillingInvoiceDetailState } from 'src/app/core/store/state/billing-invoice-detail.state';

@Component({
  selector: 'app-billing-invoice-detail',
  templateUrl: './billing-invoice-detail.component.html',
  styleUrls: ['./billing-invoice-detail.component.scss']
})
export class BillingInvoiceDetailComponent implements OnInit, OnDestroy {

  public billingInvoiceDetail: BillingInvoiceDetailsModel;
  public subscription: Subscription = new Subscription();

  @Select(BillingInvoiceDetailState.selectedBillingInvoiceDetail) selectedBillingInvoiceDetail$: Observable<BillingInvoiceDetailsModel>;
  constructor(private _router: Router, private store: Store) {
    this.selectedBillingInvoiceDetail$.subscribe(data => {
      this.billingInvoiceDetail = data;
      console.log(this.billingInvoiceDetail.invoiceDetails);
    })
  }


  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
