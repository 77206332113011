import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { config } from '../app.config';
import { RequestTransactionReportParameters } from '../models/common/request-parameters.model';
import { CardPaymentFormResponseModel } from '../models/transaction/card-payment-form-response.model';
import { CardPaymentStatusResponseModel } from '../models/transaction/card-payment-status-response.model';
import { CardPaymentTransactionResponseModel } from '../models/transaction/card-payment-transaction-response.model';
import { CardTransactionDetailModel } from '../models/transaction/card-transaction-detail.model';
import { CardTransactionResultModel } from '../models/transaction/card-transaction-result.model';
import { InitiateCardTransactionRequestModel } from '../models/transaction/initiate-card-transaction-request.model';
import { InitiateCardTransactionResponseModel } from '../models/transaction/initiate-card-transaction-response.model';
import { ProcessCardTransactionRequestModel } from '../models/transaction/process-card-transaction-request.model';
import { ProcessCardTransactionResponseModel } from '../models/transaction/process-card-transaction-response.model';
import { RefundCardTransactionRequestModel } from '../models/transaction/refund-card-transaction-request.model';
import { RefundCardTransactionResponseModel } from '../models/transaction/refund-card-transaction-response.model';
import { TransactionReportModel } from '../models/transaction/transaction.model';
import { SelectTransactionReportAction } from '../store/actions/transaction.action';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(@Inject(DOCUMENT) private document: any,
    private http: HttpClient,
    private store: Store) { }

  public getTransactionReport(requestTransactionReportParameters: RequestTransactionReportParameters): Observable<TransactionReportModel> {
    return new Observable(subscriber => {
      this.http.post<TransactionReportModel>(config.C2PAPI + '/transaction/report', requestTransactionReportParameters)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: TransactionReportModel) => {
            if ((data == null) || (data == undefined))
              return;

            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getCardTransactionResult(bankReference: string): Observable<CardTransactionResultModel> {
    return new Observable(subscriber => {
      this.http.get<CardTransactionResultModel>(config.C2PAPI + '/transaction/card/search?bankReference=' + bankReference)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: CardTransactionResultModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getCardTransactionDetails(transactionRequestIdentifier: string): Observable<CardTransactionDetailModel> {
    return new Observable(subscriber => {
      this.http.get<CardTransactionDetailModel>(config.C2PAPI + '/transaction/card/get?transactionRequestIdentifier=' + transactionRequestIdentifier)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: CardTransactionDetailModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public initiateCardTransaction(initiateCardTransactionRequestModel: InitiateCardTransactionRequestModel): Observable<InitiateCardTransactionResponseModel> {
    return new Observable(subscriber => {
      this.http.post<InitiateCardTransactionResponseModel>(config.C2PAPI + '/transaction/initiate', initiateCardTransactionRequestModel)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: InitiateCardTransactionResponseModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public processCardTransaction(processCardTransactionRequestModel: ProcessCardTransactionRequestModel): Observable<ProcessCardTransactionResponseModel> {
    return new Observable(subscriber => {
      this.http.post<ProcessCardTransactionResponseModel>(config.C2PAPI + '/transaction/card/processTx', processCardTransactionRequestModel)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: ProcessCardTransactionResponseModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public processRefundCardTransaction(refundCardTransactionRequestModel: RefundCardTransactionRequestModel): Observable<RefundCardTransactionResponseModel> {
    return new Observable(subscriber => {
      this.http.post<RefundCardTransactionResponseModel>(config.C2PAPI + '/transaction/card/refund', refundCardTransactionRequestModel)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: RefundCardTransactionResponseModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  //Peach Impl.
  public getCardPaymentForm(transactionRequestIdentifier: string): Observable<CardPaymentFormResponseModel> {
    return new Observable(subscriber => {
      this.http.get<CardPaymentFormResponseModel>(config.C2PAPI + '/transaction/card/form?transactionRequestIdentifier=' + transactionRequestIdentifier)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: CardPaymentFormResponseModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getCardPaymentStatus(cardPaymentFormIdentifier: string): Observable<CardPaymentStatusResponseModel> {
    return new Observable(subscriber => {
      this.http.get<CardPaymentStatusResponseModel>(config.C2PAPI + '/transaction/card/paymentStatus?cardPaymentFormIdentifier=' + cardPaymentFormIdentifier)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: CardPaymentStatusResponseModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getCardTransactionsReport(startDate: string, endDate: string): Observable<Array<CardPaymentTransactionResponseModel>> {
    return new Observable(subscriber => {
      this.http.get<Array<CardPaymentTransactionResponseModel>>(config.C2PAPI + '/transaction/report?startDate=' + startDate + "&endDate=" + endDate)
        .pipe(
          map(response => response || [])
        )
        .subscribe({
          next: (data: Array<CardPaymentTransactionResponseModel>) => {
            subscriber.next(data);
            subscriber.complete(); 
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }
        }
        );
    });
  }
}
