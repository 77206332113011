<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="profile-details text-center">
                        <!-- <img src="assets/images/dashboard/designer.jpg" alt=""
                            class="img-fluid img-90 rounded-circle blur-up lazyloaded"> -->
                        <h5 class="f-w-600 mb-0">{{ merchant.rootUser.firstname }} {{ merchant.rootUser.lastname }}</h5>
                        <span>{{ merchant.rootUser.emailAddress }}</span>
                        <h6 class="f-w-400 mb-0">Mobile: {{ merchant.rootUser.contactNumber1 }}</h6>
                        <!-- <h6 class="f-w-400 mb-0">Tel: {{ merchant.rootUser.emailAddress }}</h6> -->
                    </div>
                    <hr>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card tab2-card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink><i class="me-2" data-feather="user"></i>Company Details</a>
                            <ng-template ngbNavContent>
                                <div class="tab-pane fade show active" id="top-profile" role="tabpanel"
                                    aria-labelledby="top-profile-tab">
                                    <!-- <h5 class="f-w-600"> Profile</h5> -->
                                    <div class="table-responsive profile-table">
                                        <table class="table table-responsive">
                                            <tbody>
                                                <tr>
                                                    <td>Title:</td>
                                                    <td>{{ merchant.rootUser.title }}</td>
                                                </tr>
                                                <tr>
                                                    <td>First Name:</td>
                                                    <td>{{ merchant.rootUser.firstname }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Last Name:</td>
                                                    <td>{{ merchant.rootUser.lastname }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Company Name:</td>
                                                    <td>{{ merchant.merchantName }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Registration Number:</td>
                                                    <td>{{ merchant.merchantRegistration }}</td>
                                                </tr>
                                                <tr>
                                                    <td>VAT:</td>
                                                    <td>{{ merchant.merchantVat }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Identity Number:</td>
                                                    <td>{{ merchant.rootUser.identityNumber }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Contant Number 1:</td>
                                                    <td>{{ merchant.rootUser.contactNumber1 }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email:</td>
                                                    <td>{{ merchant.accountContactEmail }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink class="d-flex align-items-center"><i data-feather="briefcase"
                                    class="me-2"></i>Bank Account Details</a>
                            <ng-template ngbNavContent>
                                <div class="table-responsive profile-table">
                                    <p>To update your banking details for settlements and billings please email
                                        accounts@click2pay.co.za with a proof of banking letter
                                        and we will update the details accordingly.</p>
                                    <table class="table table-responsive">
                                        <tbody>
                                            <tr>
                                                <td>Bank Name:</td>
                                                <td>{{ merchant.settlementBankAccount.bankName }}</td>
                                            </tr>
                                            <tr>
                                                <td>Account Number:</td>
                                                <td>{{ merchant.settlementBankAccount.accountNumber }}</td>
                                            </tr>
                                            <tr>
                                                <td>Account Type:</td>
                                                <td>{{ merchant.settlementBankAccount.accountType }}</td>
                                            </tr>
                                            <tr>
                                                <td>Branch Code:</td>
                                                <td>{{ merchant.settlementBankAccount.branchCode }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink class="d-flex align-items-center"><i data-feather="credit-card"
                                    class="me-2"></i>Charges & Billing Rates</a>
                            <ng-template ngbNavContent>
                                <div class="table-responsive profile-table">
                                    <table class="table table-responsive">
                                        <tbody>
                                            <tr>
                                                <td>Authorisation Fee:</td>
                                                <td>R{{ merchant.merchantFeeRates.authorisationFee | number:'1.2-2' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Tokenisation Fee:</td>
                                                <td>R{{ merchant.merchantFeeRates.tokenisationFee | number:'1.2-2' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Refund:</td>
                                                <td>R{{ merchant.merchantFeeRates.refundFee | number:'1.2-2' }}</td>
                                            </tr>  
                                            <tr>
                                                <td>Risk Screening Fee:</td>
                                                <td>R{{ merchant.merchantFeeRates.riskScreeningFee | number:'1.2-2' }}</td>
                                            </tr>    
                                            <tr>
                                                <td>Recurring Transaction Fee:</td>
                                                <td>R{{ merchant.merchantFeeRates.recurringTransactionFee | number:'1.2-2' }}</td>
                                            </tr>                                
                                            <tr>
                                                <td>Subscription Fee:</td>
                                                <td>R{{ merchant.merchantFeeRates.subscriptionFee | number:'1.2-2' }}</td>
                                            </tr>
                                            <tr>
                                                <td>POS Rental Fee:</td>
                                                <td>R{{ merchant.merchantFeeRates.posRentalFee | number:'1.2-2' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Setup Fee:</td>
                                                <td>R{{ merchant.merchantFeeRates.setupFee | number:'1.2-2' }}</td>
                                            </tr>
                                            <tr>
                                                <td>User Fee:</td>
                                                <td>R{{ merchant.merchantFeeRates.userFee | number:'1.2-2' }}</td>
                                            </tr>
                                            <tr>
                                                <td>VISA Credit Card Ecommerce Transaction:</td>
                                                <td>{{ merchant.merchantFeeRates.visaCreditCardEcommerceRate * 100 | number:'1.2-2' }}%</td>
                                            </tr>
                                            <tr>
                                                <td>VISA Debit Card Ecommerce Transaction:</td>
                                                <td>{{ merchant.merchantFeeRates.visaDebitCardEcommerceRate * 100 | number:'1.2-2' }}%</td>
                                            </tr>
                                            <tr>
                                                <td>Mastercard Credit Card Ecommerce Transaction:</td>
                                                <td>{{ merchant.merchantFeeRates.mastercardCreditCardEcommerceRate * 100 | number:'1.2-2' }}%</td>
                                            </tr>
                                            <tr>
                                                <td>Mastercard Debit Card Ecommerce Transaction:</td>
                                                <td>{{ merchant.merchantFeeRates.mastercardDebitCardEcommerceRate * 100 | number:'1.2-2' }}%</td>
                                            </tr>
                                            <tr>
                                                <td>Amex Credit Card Ecommerce Transaction:</td>
                                                <td>{{ merchant.merchantFeeRates.amexCardEcommerceRate * 100 | number:'1.2-2' }}%</td>
                                            </tr>
                                            <tr>
                                                <td>Diners Credit Card Ecommerce Transaction:</td>
                                                <td>{{ merchant.merchantFeeRates.dinersCardEcommerceRate * 100 | number:'1.2-2' }}%</td>
                                            </tr>
                                            <tr>
                                                <td>VISA Credit Card POS Transaction:</td>
                                                <td>{{ merchant.merchantFeeRates.visaCreditCardPosRate * 100 | number:'1.2-2' }}%</td>
                                            </tr>
                                            <tr>
                                                <td>VISA Debit Card POS Transaction:</td>
                                                <td>{{ merchant.merchantFeeRates.visaDebitCardPosRate * 100 | number:'1.2-2' }}%</td>
                                            </tr>
                                            <tr>
                                                <td>Mastercard Credit Card POS Transaction:</td>
                                                <td>{{ merchant.merchantFeeRates.mastercardCreditCardPosRate * 100 | number:'1.2-2' }}%</td>
                                            </tr>
                                            <tr>
                                                <td>Mastercard Debit Card POS Transaction:</td>
                                                <td>{{ merchant.merchantFeeRates.mastercardDebitCardPosRate * 100 | number:'1.2-2' }}%</td>
                                            </tr>
                                            <tr>
                                                <td>Amex Credit Card POS Transaction:</td>
                                                <td>{{ merchant.merchantFeeRates.amexCardPosRate * 100 | number:'1.2-2' }}%</td>
                                            </tr>
                                            <tr>
                                                <td>Diners Credit Card POS Transaction:</td>
                                                <td>{{ merchant.merchantFeeRates.dinersCardPosRate * 100 | number:'1.2-2' }}%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </li>
                        <li *ngIf="merchant.enableRecurring" [ngbNavItem]="4">
                            <a ngbNavLink class="d-flex align-items-center"><i data-feather="codepen"
                                    class="me-2"></i>Collection Settings</a>
                            <ng-template ngbNavContent>
                                <div class="table-responsive profile-table">
                                    <table class="table table-responsive">
                                        <tbody>
                                            <tr>
                                                <td>Collection Run Time:</td>
                                                <td>{{ merchant.merchantCollectionSettings.collectionRunTime }}</td>
                                            </tr>
                                            <tr>
                                                <td>Collection Resubmission Run Time:</td>
                                                <td>{{ merchant.merchantCollectionSettings.collectionResubmissionRunTime }}</td>
                                            </tr>
                                            <tr>
                                                <td>Collection Frequency:</td>
                                                <td>{{ merchant.merchantCollectionSettings.collectionFrequency }}</td>
                                            </tr>
                                            <tr>
                                                <td>Collection Day:</td>
                                                <td>{{ merchant.merchantCollectionSettings.collectionDay }}</td>
                                            </tr>
                                            <tr>
                                                <td>Collection Auto Rerun:</td>
                                                <td>{{ merchant.merchantCollectionSettings.collectionAutoRun ? 'Yes' :
                                                    'No' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Auto Rerun Attempts:</td>
                                                <td>{{ merchant.merchantCollectionSettings.collectionRerunAttempts }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Collection Allow Dynamic Payday:</td>
                                                <td>{{ merchant.merchantCollectionSettings.collectionAllowDynamicPayday
                                                    ? 'Yes' : 'No' }}</td>
                                            </tr>
                                            <tr>
                                                <td>Allow Early December Collection:</td>
                                                <td>{{ merchant.merchantCollectionSettings.earlyDecemberCollection ?
                                                    'Yes' : 'No' }}</td>
                                            </tr>
                                            <tr *ngIf="merchant.merchantCollectionSettings.earlyDecemberCollection">
                                                <td>December Day:</td>
                                                <td>{{ dayOrdinal(merchant.merchantCollectionSettings.earlyDecemberDay)
                                                    }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->