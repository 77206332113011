import { Component, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { MerchantModel } from 'src/app/core/models/merchant/merchant.model';
import { AuthService, MerchantService } from 'src/app/core/services';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { vendorsDB } from 'src/app/shared/tables/vendor-list';
import { jwtDecode } from 'jwt-decode';
import { MasqueradeRequestModel } from 'src/app/core/models/auth/masquerade-request.model';
import { NavService } from 'src/app/shared/service/nav.service';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-merchant-list',
  templateUrl: './merchant-list.component.html',
  styleUrls: ['./merchant-list.component.scss']
})
export class MerchantListComponent {

  public searchText;
  public vendors = [];
  public isLoading: boolean = false;
  total$: Observable<number>;

  public merchants: MerchantModel[] = [];

  constructor(private formBuilder: UntypedFormBuilder, public service: TableService, 
    private _authService: AuthService,
    private _navService: NavService,
    private _merchantService: MerchantService, 
    private _router: Router,
    private spinner: NgxSpinnerService) {
    this.vendors = vendorsDB.data;
  }

  ngOnInit() {
    this.retrieveMerchants();
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  retrieveMerchants() {
    this.isLoading = true;
    this.spinner.show();
    this._merchantService.getMerchants().subscribe(data => {
      this.merchants = data;
      this.isLoading = false;
      this.spinner.hide();
    });
  }

  extractSubFromToken(): string | null {
    try {
      const decodedToken: any = jwtDecode(this._authService.getToken());

      return decodedToken.sub || null;
    } catch (error) {
      console.error('Invalid token:', error);
      return null;
    }
  }

  navigateToUpdateMerchant(merchant: MerchantModel) {
    const navigationExtras: NavigationExtras = {
      state: { data: merchant }
    };

    this._router.navigate(['/admin/update-merchant'], navigationExtras)
      .catch(error => console.log('Navigation error:', error));
  }

  masqueradeMerchant(targetUsername: string) {
    this.isLoading = true;
    this.spinner.show();

    const superAdminUsername = this.extractSubFromToken();
    let masqueradeRequestModel: MasqueradeRequestModel = {
      superAdminUsername: superAdminUsername,
      targetUsername: targetUsername
    }

    this._authService.masquerade(masqueradeRequestModel).subscribe(data => {
      this.isLoading = false;
      this.spinner.hide();
      this._navService.updateMenuItems(data.role, this._authService.enableEcommerce(), this._authService.enableRecurring());
      this.refreshCurrentRoute();
    });
  }

  refreshCurrentRoute() {
    this._router.navigate(['/dashboard/default']).then(() => {
      location.reload();
    });
  }
  

}
