import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { config } from '../app.config';
import { RequestParameters } from '../models/common/request-parameters.model';
import { PreviousSettlementModel } from '../models/settlement/previous-settlement.model';
import { TransactionSettlementRequest } from '../models/settlement/transaction-settlement-request.model';
import { TransactionSettlementResponse } from '../models/settlement/transaction-settlement-response.model';
import { UnsettledEcommerceSummaryModel } from '../models/settlement/unsettled-ecommerce-summary.model';
import { SelectPreviousSettlementAction } from '../store/actions/settlement.action';

@Injectable({
  providedIn: 'root'
})
export class SettlementService {

  constructor(@Inject(DOCUMENT) private document: any,
  private http: HttpClient,
  private store: Store) { }

  public getPreviousSettlement(requestParameters: RequestParameters): Observable<PreviousSettlementModel> {
    return new Observable(subscriber => {
      this.http.post<PreviousSettlementModel>(config.C2PAPI + '/settlement/previous_settlements', requestParameters)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: PreviousSettlementModel) => {
            if ((data == null) || (data == undefined))
              return;

            this.store.dispatch(new SelectPreviousSettlementAction(data));
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getUnsettledEcommerce(startDate: string, endDate: string): Observable<Array<UnsettledEcommerceSummaryModel>> {
    return new Observable(subscriber => {
      this.http.get<Array<UnsettledEcommerceSummaryModel>>(config.C2PAPI + '/settlement/ecommerce/unsettled?startDate=' + startDate + "&endDate=" + endDate)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: Array<UnsettledEcommerceSummaryModel>) => {
            if ((data == null) || (data == undefined))
              return;
              subscriber.next(data);
              subscriber.complete(); 
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public settleEcomerceTransactions(settledTransactionRequests: TransactionSettlementRequest[]): Observable<Array<TransactionSettlementResponse>> {
    return new Observable(subscriber => {
      this.http.post<Array<TransactionSettlementResponse>>(config.C2PAPI + '/settlement/ecommerce/settle', settledTransactionRequests)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: Array<TransactionSettlementResponse>) => {
            if ((data == null) || (data == undefined))
              return;
              subscriber.next(data);
              subscriber.complete(); 
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }
}
