import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateMerchantResponse } from 'src/app/core/models/merchant/create-merchant-response.model';
import { MerchantCollectionSettings } from 'src/app/core/models/merchant/merchant-collection-settings.model';
import { MerchantModel, RootUser, SettlementBankAccount, MerchantFeeRates, EcommerceBankSetup, PosBankSetup, MerchantGateway } from 'src/app/core/models/merchant/merchant.model';
import { MerchantService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-merchant',
  templateUrl: './create-merchant.component.html',
  styleUrls: ['./create-merchant.component.scss']
})
export class CreateMerchantComponent {
  public merchantDetailsForm: UntypedFormGroup;
  public merchantRates: UntypedFormGroup;
  public bankSetup: UntypedFormGroup;
  public gatewaySetup: UntypedFormGroup;
  public collectionsSettings: UntypedFormGroup;
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public modelFooter: NgbDateStruct;
  public active = 1;

  public isLoading: boolean = false;

  collectionRunTime = { hour: 13, minute: 30 };
  collectionResubmissionRunTime = { hour: 14, minute: 30 };
  meridian = true;

  constructor(private formBuilder: UntypedFormBuilder, private calendar: NgbCalendar,
    public merchantService: MerchantService,
    private spinner: NgxSpinnerService) {
    this.createMerchantForm();
    this.createMerchantRatesForm();
    this.createMerchantBankSetupForm();
    this.createMerchantGatewayForm();
    this.createMerchantCollectionSettingsForm();
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  createMerchantForm() {
    this.merchantDetailsForm = this.formBuilder.group({
      merchantName: [''],
      merchantVat: [''],
      merchantRegistration: [''],
      merchantAddress: [''],
      accountContactName: [''],
      accountContactNumber: [''],
      accountContactMobile: [''],
      accountContactEmail: [''],
      merchantBankMid: [''],
      merchantSetupType: [''],
      enableEcommerce: [true],
      enableRecurring: [false],
      merchantSettleUsersDirectly: [false],
      merchantRequiresBatchSettlementReport: [false],
      merchantSetupFeePaid: [false],
      merchantSettlementsSuspended: [false],
      merchantPaysByDebit: [false],
      merchantSendSmsOnline: [false],
      bankName: [''],
      accountNumber: [''],
      accountType: [''],
      branchCode: [''],
      title: [''],
      firstname: [''],
      lastname: [''],
      emailAddress: [''],
      identityNumber: [''],
      contactNumber1: [''],
      code: [''],
      contractStartDate: ['']
    });
  }

  createMerchantRatesForm() {
    this.merchantRates = this.formBuilder.group({
      setupFee: [0],
      subscriptionFee: [0],
      posRentalFee: [0],
      smsFee: [0],
      userFee: [0],
      tokenisationFee: [0],
      refundFee: [0],
      riskScreeningFee: [0],
      recurringTransactionFee: [0],
      authFee: [0],
      visaCreditCardEcommerceRate: [0],
      visaDebitCardEcommerceRate: [0],
      mastercardCreditCardEcommerceRate: [0],
      mastercardDebitCardEcommerceRate: [0],
      amexCardEcommerceRate: [0],
      dinersCardEcommerceRate: [0],
      visaCreditCardPosRate: [0],
      visaDebitCardPosRate: [0],
      mastercardCreditCardPosRate: [0],
      mastercardDebitCardPosRate: [0],
      amexCardPosRate: [0],
      dinersCardPosRate: [0],
    })
  }

  createMerchantBankSetupForm() {
    this.bankSetup = this.formBuilder.group({
      bankMerchantId: [''],
      merchantType: ['TPPP'],
      merchantShortname: [''],
      subMerchantIdentifier: [''],
      mccCode: [''],
      posBankMerchantId: [''],
      posMerchantType: ['ISO'],
      posMerchantShortname: [''],
      posSubMerchantIdentifier: [''],
      posMccCode: ['']
    });
  }

  createMerchantGatewayForm() {
    this.gatewaySetup = this.formBuilder.group({
      gateway: ['APS'],
      gatewayUrl: [''],
      gatewayKey: [''],
      gatewayToken: [''],
      recurringGateway: ['PEP'],
      recurringGatewayUrl: [''],
      recurringGatewayKey: [''],
      recurringGatewayToken: [''],
    });
  }

  createMerchantCollectionSettingsForm() {
    this.collectionsSettings = this.formBuilder.group({
      collectionRunTime: [this.collectionRunTime],
      collectionResubmissionRunTime: [this.collectionResubmissionRunTime],
      collectionFrequency: [''],
      collectionDay: [''],
      collectionAutoRun: [false],
      collectionRerunAttempts: [''],
      collectionAllowDynamicPayday: [false],
      earlyDecemberCollection: [false],
      earlyDecemberDay: ['']
    })
  }

  extractTime(time: { hour: number, minute: number }) {
    return `${time.hour}:${time.minute}`
  }

  createMerchant() {
    this.isLoading = true;
    this.spinner.show();

      const newMerchant: MerchantModel = new MerchantModel();

    // Map form values to the MerchantModel properties
    newMerchant.merchantName = this.merchantDetailsForm.get('merchantName').value;
    newMerchant.merchantVat = this.merchantDetailsForm.get('merchantVat').value.toString();
    newMerchant.merchantRegistration = this.merchantDetailsForm.get('merchantRegistration').value;
    newMerchant.merchantAddress = this.merchantDetailsForm.get('merchantAddress').value;
    newMerchant.accountContactName = this.merchantDetailsForm.get('accountContactName').value;
    newMerchant.accountContactNumber = this.merchantDetailsForm.get('accountContactNumber').value;
    newMerchant.accountContactMobile = this.merchantDetailsForm.get('accountContactMobile').value;
    newMerchant.accountContactEmail = this.merchantDetailsForm.get('accountContactEmail').value;
    newMerchant.merchantBankMid = '00000000000';
    newMerchant.merchantSetupType = 'TPPP';
    newMerchant.enableEcommerce = this.merchantDetailsForm.get('enableEcommerce').value;
    newMerchant.enableRecurring = this.merchantDetailsForm.get('enableRecurring').value;
    newMerchant.merchantSettleUsersDirectly = this.merchantDetailsForm.get('merchantSettleUsersDirectly').value;
    newMerchant.merchantRequiresBatchSettlementReport = this.merchantDetailsForm.get('merchantRequiresBatchSettlementReport').value;
    newMerchant.merchantSetupFeePaid = false;
    newMerchant.merchantSettlementsSuspended = false;
    newMerchant.merchantPaysByDebit = false;
    newMerchant.merchantSendSmsOnline = false;

    // Map RootUser
    newMerchant.rootUser = new RootUser();
    newMerchant.rootUser.title = this.merchantDetailsForm.get('title').value;
    newMerchant.rootUser.firstname = this.merchantDetailsForm.get('firstname').value;
    newMerchant.rootUser.lastname = this.merchantDetailsForm.get('lastname').value;
    newMerchant.rootUser.emailAddress = this.merchantDetailsForm.get('emailAddress').value;
    newMerchant.rootUser.identityNumber = this.merchantDetailsForm.get('identityNumber').value;
    newMerchant.rootUser.contactNumber1 = this.merchantDetailsForm.get('contactNumber1').value;
    newMerchant.rootUser.password = Math.random().toString(36).slice(-8);
    newMerchant.rootUser.portalLink = 'https://devclick2pay.c2p-hub.co.za/';

    // Map SettlementBankAccount
    newMerchant.settlementBankAccount = new SettlementBankAccount();
    newMerchant.settlementBankAccount.bankName = this.merchantDetailsForm.get('bankName').value;
    newMerchant.settlementBankAccount.accountNumber = this.merchantDetailsForm.get('accountNumber').value.toString();
    newMerchant.settlementBankAccount.accountType = this.merchantDetailsForm.get('accountType').value;
    newMerchant.settlementBankAccount.branchCode = this.merchantDetailsForm.get('branchCode').value.toString();

    // Map MerchantFeeRates
    newMerchant.merchantFeeRates = new MerchantFeeRates();
    newMerchant.merchantFeeRates.setupFee = parseFloat(this.merchantRates.get('setupFee').value) || 0;
    newMerchant.merchantFeeRates.subscriptionFee = parseFloat(this.merchantRates.get('subscriptionFee').value) || 0;
    newMerchant.merchantFeeRates.posRentalFee = parseFloat(this.merchantRates.get('posRentalFee').value) || 0;
    newMerchant.merchantFeeRates.smsFee = parseFloat(this.merchantRates.get('smsFee').value) || 0;
    newMerchant.merchantFeeRates.userFee = parseFloat(this.merchantRates.get('userFee').value) || 0;
    newMerchant.merchantFeeRates.authorisationFee = parseFloat(this.merchantRates.get('authFee').value) || 0;
    newMerchant.merchantFeeRates.tokenisationFee = parseFloat(this.merchantRates.get('tokenisationFee').value) || 0;
    newMerchant.merchantFeeRates.refundFee = parseFloat(this.merchantRates.get('refundFee').value) || 0;
    newMerchant.merchantFeeRates.riskScreeningFee = parseFloat(this.merchantRates.get('riskScreeningFee').value) || 0;
    newMerchant.merchantFeeRates.recurringTransactionFee = parseFloat(this.merchantRates.get('recurringTransactionFee').value) || 0;
    newMerchant.merchantFeeRates.visaCreditCardEcommerceRate = parseFloat(this.merchantRates.get('visaCreditCardEcommerceRate').value) || 0;
    newMerchant.merchantFeeRates.visaDebitCardEcommerceRate = parseFloat(this.merchantRates.get('visaDebitCardEcommerceRate').value) || 0;
    newMerchant.merchantFeeRates.mastercardCreditCardEcommerceRate = parseFloat(this.merchantRates.get('mastercardCreditCardEcommerceRate').value) || 0;
    newMerchant.merchantFeeRates.mastercardDebitCardEcommerceRate = parseFloat(this.merchantRates.get('mastercardDebitCardEcommerceRate').value) || 0;
    newMerchant.merchantFeeRates.amexCardEcommerceRate = parseFloat(this.merchantRates.get('amexCardEcommerceRate').value) || 0;
    newMerchant.merchantFeeRates.dinersCardEcommerceRate = parseFloat(this.merchantRates.get('dinersCardEcommerceRate').value) || 0;
    newMerchant.merchantFeeRates.visaCreditCardPosRate = parseFloat(this.merchantRates.get('visaCreditCardPosRate').value) || 0;
    newMerchant.merchantFeeRates.visaDebitCardPosRate = parseFloat(this.merchantRates.get('visaDebitCardPosRate').value) || 0;
    newMerchant.merchantFeeRates.mastercardCreditCardPosRate = parseFloat(this.merchantRates.get('mastercardCreditCardPosRate').value) || 0;
    newMerchant.merchantFeeRates.mastercardDebitCardPosRate = parseFloat(this.merchantRates.get('mastercardDebitCardPosRate').value) || 0;
    newMerchant.merchantFeeRates.amexCardPosRate = parseFloat(this.merchantRates.get('amexCardPosRate').value) || 0;
    newMerchant.merchantFeeRates.dinersCardPosRate = parseFloat(this.merchantRates.get('dinersCardPosRate').value) || 0;

    // Map EcommerceBankSetup
    newMerchant.ecommerceBankSetup = new EcommerceBankSetup();
    newMerchant.ecommerceBankSetup.bankMerchantId = this.bankSetup.get('bankMerchantId').value;
    newMerchant.ecommerceBankSetup.merchantType = this.bankSetup.get('merchantType').value;
    newMerchant.ecommerceBankSetup.merchantShortname = this.bankSetup.get('merchantShortname').value;
    newMerchant.ecommerceBankSetup.subMerchantIdentifier = this.bankSetup.get('subMerchantIdentifier').value;
    newMerchant.ecommerceBankSetup.mccCode = this.bankSetup.get('mccCode').value;

    // Map PosBankSetup
    if (this.bankSetup.get('posBankMerchantId').value) {
      newMerchant.posBankSetup = new PosBankSetup();
      newMerchant.posBankSetup.bankMerchantId = this.bankSetup.get('posBankMerchantId').value;
      newMerchant.posBankSetup.merchantType = this.bankSetup.get('posMerchantType').value;
      newMerchant.posBankSetup.merchantShortname = this.bankSetup.get('posMerchantShortname').value;
      newMerchant.posBankSetup.subMerchantIdentifier = this.bankSetup.get('posSubMerchantIdentifier').value;
      newMerchant.posBankSetup.mccCode = this.bankSetup.get('posMccCode').value;
    }

    // Map MerchantGateway
    if (this.gatewaySetup.get('gatewayKey').value) {
      newMerchant.merchantEcommerceGateway = new MerchantGateway();
      newMerchant.merchantEcommerceGateway.gateway = this.gatewaySetup.get('gateway').value;
      newMerchant.merchantEcommerceGateway.gatewayUrl = this.gatewaySetup.get('gatewayUrl').value;
      newMerchant.merchantEcommerceGateway.gatewayKey = this.gatewaySetup.get('gatewayKey').value;
      newMerchant.merchantEcommerceGateway.gatewayToken = this.gatewaySetup.get('gatewayToken').value;
    }

    // Map MerchantRecurringGateway
    if (this.gatewaySetup.get('recurringGatewayKey').value) {
      newMerchant.merchantRecurringGateway = new MerchantGateway();
      newMerchant.merchantRecurringGateway.gateway = this.gatewaySetup.get('recurringGateway').value;
      newMerchant.merchantRecurringGateway.gatewayUrl = this.gatewaySetup.get('recurringGatewayUrl').value;
      newMerchant.merchantRecurringGateway.gatewayKey = this.gatewaySetup.get('recurringGatewayKey').value;
      newMerchant.merchantRecurringGateway.gatewayToken = this.gatewaySetup.get('recurringGatewayToken').value;
    }

    // Map MerchantCollectionSettings
    newMerchant.merchantCollectionSettings = new MerchantCollectionSettings();
    newMerchant.merchantCollectionSettings.collectionRunTime = this.extractTime(this.collectionsSettings.get('collectionRunTime').value);
    newMerchant.merchantCollectionSettings.collectionResubmissionRunTime = this.extractTime(this.collectionsSettings.get('collectionResubmissionRunTime').value);
    newMerchant.merchantCollectionSettings.collectionDay = parseInt(this.collectionsSettings.get('collectionDay').value, 10) || 0;
    newMerchant.merchantCollectionSettings.collectionFrequency = this.collectionsSettings.get('collectionFrequency').value;
    newMerchant.merchantCollectionSettings.collectionAutoRun = this.collectionsSettings.get('collectionAutoRun').value;

    if (newMerchant.merchantCollectionSettings.collectionAutoRun) {
      newMerchant.merchantCollectionSettings.collectionRerunAttempts = parseInt(this.collectionsSettings.get('collectionRerunAttempts').value, 10) || 0;
    }

    newMerchant.merchantCollectionSettings.collectionAllowDynamicPayday = this.collectionsSettings.get('collectionAllowDynamicPayday').value;
    newMerchant.merchantCollectionSettings.earlyDecemberCollection = this.collectionsSettings.get('earlyDecemberCollection').value;

    if (newMerchant.merchantCollectionSettings.earlyDecemberCollection) {
      newMerchant.merchantCollectionSettings.earlyDecemberDay = parseInt(this.collectionsSettings.get('earlyDecemberDay').value, 10) || 0;
    }

    this.merchantService.createMerchant(newMerchant).subscribe({
      next: (data: CreateMerchantResponse) => {
        this.isLoading = false;
        this.spinner.hide();
        if (data.success) {
          this.clearForms();
          Swal.fire({
            icon: "success",
            title: "Successful!",
            text: `Merchant ${data.merchantName} was created successfully.`
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Merchant ${data.merchantName} creation failed.`
          });
        }
      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.spinner.hide();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorResponse
        });
      }
    });
  }

  ngOnInit() {

  }

  clearForms() {
    this.merchantDetailsForm.setValue({
      merchantName: '',
      merchantVat: '',
      merchantRegistration: '',
      merchantAddress: '',
      accountContactName: '',
      accountContactNumber: '',
      accountContactMobile: '',
      accountContactEmail: '',
      merchantBankMid: '',
      merchantSetupType: '',
      merchantSettleUsersDirectly: false,
      merchantRequiresBatchSettlementReport: false,
      merchantSetupFeePaid: false,
      merchantSettlementsSuspended: false,
      merchantPaysByDebit: false,
      merchantSendSmsOnline: false,
      bankName: '',
      accountNumber: '',
      accountType: '',
      branchCode: '',
      title: '',
      firstname: '',
      lastname: '',
      emailAddress: '',
      identityNumber: '',
      contactNumber1: '',
      code: '',
      contractStartDate: ''
    });

    this.merchantRates.setValue({
      setupFee: 0,
      subscriptionFee: 0,
      posRentalFee: 0,
      smsFee: 0,
      userFee: 0,
      tokenisationFee: 0,
      refundFee: 0,
      riskScreeningFee: 0,
      recurringTransactionFee: 0,
      authFee: 0,
      visaCreditCardEcommerceRate: 0,
      visaDebitCardEcommerceRate: 0,
      mastercardCreditCardEcommerceRate: 0,
      mastercardDebitCardEcommerceRate: 0,
      amexCardEcommerceRate: 0,
      dinersCardEcommerceRate: 0,
      visaCreditCardPosRate: 0,
      visaDebitCardPosRate: 0,
      mastercardCreditCardPosRate: 0,
      mastercardDebitCardPosRate: 0,
      amexCardPosRate: 0,
      dinersCardPosRate: 0,
    });

    this.bankSetup.setValue({
      bankMerchantId: '',
      merchantType: 'TPPP',
      merchantShortname: '',
      subMerchantIdentifier: '',
      mccCode: '',
      posBankMerchantId: '',
      posMerchantType: 'ISO',
      posMerchantShortname: '',
      posSubMerchantIdentifier: '',
      posMccCode: ''
    });

    this.gatewaySetup.setValue({
      gateway: 'APS',
      gatewayUrl: '',
      gatewayKey: '',
      gatewayToken: '',
      recurringGateway: 'PEP',
      recurringGatewayUrl: '',
      recurringGatewayKey: '',
      recurringGatewayToken: '',
    });

    this.collectionsSettings.setValue({
      collectionRunTime: this.collectionRunTime, // Retain initial value
      collectionResubmissionRunTime: this.collectionResubmissionRunTime, // Retain initial value
      collectionFrequency: '',
      collectionDay: '',
      collectionAutoRun: false,
      collectionRerunAttempts: '',
      collectionAllowDynamicPayday: false,
      earlyDecemberCollection: false,
      earlyDecemberDay: ''
    });
  }

}
