import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-unpaids',
  templateUrl: './unpaids.component.html',
  styleUrls: ['./unpaids.component.scss']
})
export class UnpaidsComponent implements OnInit, OnDestroy {

  public searchCriteriaForm: UntypedFormGroup;
  public date: { year: number, month: number };
  public active = 1;

  public tableItem$: any[];
  public subscription: Subscription;
  public searchText;
  total$: Observable<number>;

  constructor(private formBuilder: UntypedFormBuilder, public service: TableService) {

  }

  ngOnInit(): void {
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  ngOnDestroy(): void {
  }

  search() {

  }

}
