<ng-template class="theme-modal" #debtorModifyModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modify Debtor Details</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body center">
            <div *ngIf="isLoading">
                <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple"
                    [fullScreen]="false">
                    <br />
                    <br />
                    <br />
                    <p style="color: white"> Loading ... </p>
                </ngx-spinner>
            </div>
            <div class="form-group row">
                <div class="col-xl-5 col-md-6">
                    <label for="validationCustom1">Instalment Day</label>
                </div>
                <div class="col-md-7">
                    <input class="form-control" id="validationCustom1" type="number" min="0" required=""
                           [(ngModel)]="debtorSummaryItemModel.collectionDay">
                </div>
            </div>            
            <br />
            <div class="form-group row">
                <div class="col-xl-5 col-md-6"><label for="validationCustom1">Auto Rerun</label></div>
                <div class="col-md-7">
                    <select class="custom-select form-select" required=""
                        [(ngModel)]="debtorSummaryItemModel.collectionAutoRerun">
                        <option value="">--Select Option--</option>
                        <option value="true" [selected]="debtorSummaryItemModel.collectionAutoRerun === true">Yes
                        </option>
                        <option value="false" [selected]="debtorSummaryItemModel.collectionAutoRerun === false">No
                        </option>
                    </select>
                </div>
            </div>
            <br />
            <div class="form-group row">
                <div class="col-xl-5 col-md-6"><label for="validationCustom1">
                        Dynamic Payday</label></div>
                <div class="col-md-7">
                    <select class="custom-select form-select" required=""
                        [(ngModel)]="debtorSummaryItemModel.allowDynamicPayday">
                        <option value="">--Select Option--</option>
                        <option value="true" [selected]="debtorSummaryItemModel.allowDynamicPayday === true">Yes
                        </option>
                        <option value="false" [selected]="debtorSummaryItemModel.allowDynamicPayday === false">No
                        </option>
                    </select>
                </div>
            </div>
            <br />
            <div class="form-group row">
                <div class="col-xl-5 col-md-6"><label for="validationCustom1">
                        Early December Collection</label></div>
                <div class="col-md-7">
                    <select class="custom-select form-select" required=""
                        [(ngModel)]="debtorSummaryItemModel.earlyDecemberCollection">
                        <option value="">--Select Option--</option>
                        <option value="true" [selected]="debtorSummaryItemModel.earlyDecemberCollection === true">Yes
                        </option>
                        <option value="false" [selected]="debtorSummaryItemModel.earlyDecemberCollection === false">No
                        </option>
                    </select>
                </div>
            </div>
            <br />
            <div class="pull-right">
                <button type="button" class="btn btn-primary" (click)="modifyDebtorDetails()">Modify</button>
            </div>
        </div>
    </div>
</ng-template>