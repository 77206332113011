<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Add User</h5>
                </div>
                <div class="card-body tab2-card">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>User Details</a>
                            <div *ngIf="isLoading">
                                <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
                                    type="ball-scale-multiple" [fullScreen]="false">
                                    <br />
                                    <br />
                                    <br />
                                    <p style="color: white"> Loading ... </p>
                                </ngx-spinner>
                            </div>
                            <ng-template ngbNavContent>
                                <form [formGroup]="userForm" class="needs-validation user-add" novalida>
                                    <!-- <h4>Account Details</h4> -->
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="collectionType">
                                                        User Type</label></div>
                                                <div class="col-md-5">
                                                    <select class="custom-select form-select"
                                                        formControlName="collectionType">
                                                        <option value="">--Select User Type--</option>
                                                        <option value="MAD">Merchant Administrator</option>
                                                        <option value="USR">User</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="title">
                                                        Title</label></div>
                                                <div class="col-md-5">
                                                    <select class="custom-select form-select" formControlName="title">
                                                        <option value="">--Select Title--</option>
                                                        <option value="MR">Mr</option>
                                                        <option value="MS">Ms</option>
                                                        <option value="MRS">Mrs</option>
                                                        <option value="MISS">Miss</option>
                                                        <option value="DR">Dr.</option>
                                                        <option value="PROF">Prof.</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="firstname">
                                                        First Name</label></div>
                                                <div class="col-md-5"><input class="form-control" id="firstname"
                                                        type="text" formControlName="firstname"></div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="lastname">
                                                        Last Name</label></div>
                                                <div class="col-md-5"><input class="form-control" id="lastname"
                                                        type="text" formControlName="lastname"></div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="identityNumber">
                                                        Identity Number</label></div>
                                                <div class="col-md-5"><input class="form-control" id="identityNumber"
                                                        type="text" formControlName="identityNumber"></div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="contactNumber">
                                                        Contact Number</label></div>
                                                <div class="col-md-5"><input class="form-control" id="contactNumber"
                                                        type="text" formControlName="contactNumber"></div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="mobile">
                                                        Mobile</label></div>
                                                <div class="col-md-5"><input class="form-control" id="mobile"
                                                        type="text" formControlName="mobile"></div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="email">
                                                        Email</label></div>
                                                <div class="col-md-5"><input class="form-control" id="email"
                                                        type="text" formControlName="email"></div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="address">
                                                        Address</label></div>
                                                <div class="col-md-5"><input class="form-control" id="address"
                                                        type="text" formControlName="address"></div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>Bank Account Details</a>
                            <ng-template ngbNavContent>
                                <form [formGroup]="userForm" class="needs-validation user-add" novalida>
                                    <div class="col-sm-12">
                                        <div class="form-group row">
                                            <div class="col-xl-3 col-md-4"><label for="bankName">
                                                    Bank Name</label></div>
                                            <div class="col-xl-8 col-md-7">
                                                <select class="custom-select form-select" formControlName="bankName">
                                                    <option value="">--Select Bank--</option>
                                                    <option value="ABSA">ABSA</option>
                                                    <option value="African Bank">African Bank</option>
                                                    <option value="Bidvest Bank">Bidvest Bank</option>
                                                    <option value="Capitec Bank">Capitec Bank</option>
                                                    <option value="Discovery Bank">Discovery Bank</option>
                                                    <option value="FNB">FNB</option>
                                                    <option value="Grindrod Bank">Grindrod Bank</option>
                                                    <option value="Investec Bank">Investec Bank</option>
                                                    <option value="Nedbank">Nedbank</option>
                                                    <option value="Sasfin Bank">Sasfin Bank</option>
                                                    <option value="Standard Bank">Standard Bank</option>
                                                    <option value="Tyme Bank">Tyme Bank</option>
                                                    <option value="Ubank">Ubank</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-3 col-md-4"><label for="bankAccountNumber">
                                                    Account Number</label></div>
                                            <div class="col-xl-8 col-md-7"><input class="form-control"
                                                    id="bankAccountNumber" type="text"
                                                    formControlName="bankAccountNumber"></div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-3 col-md-4"><label for="bankAccountType">
                                                    Account Type</label></div>
                                            <div class="col-xl-8 col-md-7">
                                                <select class="custom-select form-select"
                                                    formControlName="bankAccountType">
                                                    <option value="">--Select Account Type--</option>
                                                    <option value="1">Cheque</option>
                                                    <option value="2">Savings</option>
                                                    <option value="3">Transmission</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-xl-3 col-md-4"><label for="bankBranchCode">
                                                    Branch Code</label></div>
                                            <div class="col-xl-8 col-md-7"><input class="form-control"
                                                    id="bankBranchCode" type="text" formControlName="bankBranchCode">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink>Permission</a>
                            <ng-template ngbNavContent>
                                <form [formGroup]="userForm" class="needs-validation user-add" novalidate="">
                                    <div class="permission-block">
                                        <div class="attribute-blocks">
                                            <h5 class="f-w-600 mb-3">Transaction Permission</h5>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="authSettle">
                                                        Settlement Options</label></div>
                                                <div class="col-xl-8 col-md-7">
                                                    <select class="custom-select form-select"
                                                        formControlName="authSettle">
                                                        <option value="">--Select Permission Type--</option>
                                                        <option value="false">Authorise only (Manual settlement
                                                            required)</option>
                                                        <option value="true">Authorise and Settle automatically</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                    <div class="pull-right">
                        <button type="button" class="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->