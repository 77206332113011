import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { MerchantModel } from 'src/app/core/models/merchant/merchant.model';
import { MerchantState, MerchantStateModel } from 'src/app/core/store/state/merchant.state';
import { NavService, Menu } from '../../service/nav.service';
import { StorageService } from '../../../core/services/storage.service';
import { AuthService } from 'src/app/core/services';
import { MasqueradeState } from 'src/app/core/store/state/masquerade.state';
import { UsersState } from 'src/app/core/store/state/users.state';
import { AuthState } from 'src/app/core/store/state/auth.state';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit, OnDestroy {

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;

  public merchant: MerchantModel;
  public title: string;
  public merchantName: string;
  public merchantCompanyName: string;

  public superAdmin: boolean = false;

	public merchantAdmin: boolean = false;
	public normalUser: boolean = false;

  public subscription: Subscription = new Subscription();

  @Select(AuthState.authRole) authRole$: Observable<string>;
  @Select(MerchantState.selectedMerchant) selectedMerchant$: Observable<MerchantStateModel>;
  @Select(MasqueradeState.masquerading) masquerading$: Observable<boolean>;
  @Select(UsersState.selectedUserScope) selectedUserScope$: Observable<{ hasEcommerce: boolean; hasRecurring: boolean }>;

  constructor(private router: Router, public navServices: NavService,
    private _authService: AuthService, private cdr: ChangeDetectorRef) {
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url)
              this.setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
              if (subItems.path === event.url)
                this.setNavActive(subItems)
              if (!subItems.children) return false
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems)
              })
            })
          })
        }
      })
    });

    this.navServices.homeItem.subscribe(item => {
      this.merchantName = item;
      if (this.merchantName) {
        this.superAdmin = false;
        this.merchantAdmin = true;
        this.normalUser = false;
      }
    });

    this.subscription.add(this.masquerading$.subscribe(data => {
      if (!data) {
        this.superAdmin = true;
        this.merchantAdmin = false;
        this.normalUser = false;
      }
    }));

    this.subscription.add(this.authRole$.subscribe(data => {
      if (data) {
        if (data == 'SAD') {
          this.superAdmin = true;
        } else if (data == 'MAD') {
          this.merchantAdmin = true;
        } else {
          this.normalUser = true;
        }
      }
    }));

    if (this.merchantAdmin) {
      this.subscription.add(this.selectedMerchant$.subscribe(data => {
        this.merchant = data.selectedMerchant;
        this.merchantName = this.merchant.merchantName;
        this.navServices.updateMenuItems('MAD', this.merchant.enableEcommerce, this.merchant.enableRecurring);
      }));
    } else if (this.superAdmin) {
      this.navServices.updateMenuItems('SAD', false, false);
    } else {
      this.subscription.add(this.selectedUserScope$.subscribe((data) => {
        this.navServices.updateMenuItems('USR', data.hasEcommerce, data.hasRecurring);
      }));
    }
  }

  ngOnInit(): void {
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
