import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, Observable } from 'rxjs';
import { UnsettledEcommerceSummaryModel } from 'src/app/core/models/settlement/unsettled-ecommerce-summary.model';
import { SettlementService } from 'src/app/core/services';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-unsettled-ecommerce',
  templateUrl: './unsettled-ecommerce.component.html',
  styleUrls: ['./unsettled-ecommerce.component.scss']
})
export class UnsettledEcommerceComponent implements OnInit, OnDestroy {

  public searchCriteriaForm: UntypedFormGroup;
  public date: { year: number, month: number };
  public active = 1;
  public isLoading: boolean = false;

  public tableItem$: any[];
  public subscription: Subscription;
  public searchText;
  total$: Observable<number>;

  public unsettledEcommerceSummaries: UnsettledEcommerceSummaryModel[] = [];

  constructor(private formBuilder: UntypedFormBuilder, public service: TableService,
    private _settlementService: SettlementService,
    private _router: Router,
    private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {
    let currentDate = new Date(); // Assuming currentDate is defined somewhere
    let defaultDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

    this.getUnsettledEcommerceReport(defaultDate, defaultDate);
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  async getUnsettledEcommerceReport(startDate: string, endDate: string) {
    this.isLoading = true;
    this.spinner.show();

    this._settlementService.getUnsettledEcommerce(startDate, endDate).subscribe(data => {
      this.unsettledEcommerceSummaries = data;
      this.isLoading = false;
      this.spinner.hide();
    })
  }

  navigateToUnsettledEcommerceDetail(unsettledEcommerceSummaryModel: UnsettledEcommerceSummaryModel) {
    const navigationExtras: NavigationExtras = {
      state: { data: unsettledEcommerceSummaryModel }
    };

    this._router.navigate(['/admin/unsettled-ecommerce-detail'], navigationExtras)
      .catch(error => console.log('Navigation error:', error));
  }

  ngOnDestroy(): void {
  }

  search() {

  }

}
