<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <form [formGroup]="searchCriteriaForm" class="needs-validation user-add" novalidate="">
                <div class="permission-block">
                    <div class="attribute-blocks">
                        <!-- <h5 class="f-w-400 mb-2">Date Range</h5> -->
                        <div class="row mb-3">
                            <div class="col-xl-2 col-sm-4">
                                <h5>Start Date</h5>
                                <div class="input-group input-grp-p">
                                    <input class="datepicker-here form-control digits" ngbDatepicker
                                        formControlName="start_date" #f="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn calendar" (click)="f.toggle()"
                                            type="button"><i class="fa fa-calendar"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-sm-4">
                                <h5>End Date</h5>
                                <div class="input-group input-grp-p">
                                    <input class="datepicker-here form-control digits" ngbDatepicker
                                        formControlName="end_date" #c="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn calendar" (click)="c.toggle()"
                                            type="button"><i class="fa fa-calendar"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row mb-3">
                            <div class="col-xl-2 col-sm-4">
                                <h5>Collection Batch Number</h5>
                                <input class="form-control" id="validationCustom0" type="text" formControlName="collectionBatchNumber"
                                    required="">
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="pull-right">
                    <button type="button" class="btn btn-primary">Search</button>
                </div>
            </form>
        </div>
        <div class="card-body tab2-card">
            <br />
            <br />
            <div id="batchCollectionScheduleReport" class="category-table custom-datatable transcation-datatable">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" sortable="collectionBatchNumber">Collection Batch Number</th>
                                <th scope="col" sortable="transactionCount">Transaction Count</th>
                                <th scope="col" sortable="collectionBatchRunDate">Collection Batch Run Date</th>
                                <th scope="col" sortable="batchStatus">Batch Status</th>
                                <th scope="col" sortable="batchTotal">Batch Total</th>
                                <th scope="col">View Summary</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of recurringCollectionScheduleItems">
                                <td>{{ item.collectionBatchNumber }}</td>
                                <td>{{ item.transactionCount }}</td>
                                <td>{{ item.collectionBatchRunDate | date:'yyyy-MM-dd' }}</td>
                                <td>{{ item.batchStatus }}</td>
                                <td>R{{ item.batchTotal | number:'1.2-2' }}</td>
                                <td>
                                    <a href="javascript:void(0)" (click)="navigateToBatchCollectionSummary(item?.collectionBatchNumber)"><i class='fa fa-tasks f-12'></i></a>&nbsp;
                                </td>     
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-center p-2">
                        <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                        [pageSize]="service.pageSize" [maxSize]="10">
                        </ngb-pagination>
                    </div>           
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->