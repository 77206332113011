<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Card Transaction</h5>
        </div>
        <div class="card-body tab2-card">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>Send Payment Link</a>
                    <div *ngIf="isLoading">
                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple"
                            [fullScreen]="false">
                            <br />
                            <br />
                            <br />
                            <p style="color: white"> Loading ... </p>
                        </ngx-spinner>
                    </div>
                    <ng-template ngbNavContent>
                        <form [formGroup]="cardTransactionForm" class="needs-validation" novalidate=""
                            *ngIf="!isSubmitted">
                            <h4></h4>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom0"><span>*</span>
                                                Transaction Reference</label>
                                        </div>
                                        <div class="col-md-4">
                                            <input class="form-control" formControlName="transactionReference"
                                                id="transactionReference" type="text" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Transaction Amount</label>
                                        </div>
                                        <div class="col-md-4">
                                            <input class="form-control" formControlName="transactionAmount"
                                                id="transactionAmount" type="number" min="1" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Card Holder Name</label>
                                        </div>
                                        <div class="col-md-4">
                                            <input class="form-control" formControlName="cardHolderName"
                                                id="cardHolderName" type="text" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Mobile</label>
                                        </div>
                                        <div class="col-md-4">
                                            <input class="form-control" formControlName="mobile" id="mobile"
                                                type="number" min="0" required="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label for="validationCustom1"><span>*</span>
                                                Email</label>
                                        </div>
                                        <div class="col-md-4">
                                            <input class="form-control" formControlName="email" id="email" type="email"
                                                required="">
                                        </div>
                                    </div>
                                    <!-- <div class="form-group row">
                                        <div class="col-xl-3 col-md-4">
                                            <label>Recurring Transaction?</label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox-primary-1" type="checkbox"
                                                    formControlName="recurring" data-original-title="" title="">
                                                <label for="checkbox-primary-1">Yes</label>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </form>
                        <div class="pull-right">
                            <button type="button" class="btn btn-primary"
                                (click)="initiateCardTransaction()">Submit</button>
                        </div>
                    </ng-template>
                </li>
                <!-- <li [ngbNavItem]="2">
                    <a ngbNavLink>Refund</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="transactionResultForm" class="needs-validation" novalidate="">
                            <div class="row">
                                <div class="col-sm-5">
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5"><label for="validationCustom3">Search for a
                                                transaction
                                                by Bank Reference:</label></div>
                                        <div class="col-md-6"><input class="form-control"
                                                formControlName="bankReference" id="validationCustom3" type="text"
                                                required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Bank Reference.</div>
                                    </div>
                                </div>
                                <div class="col-sm-5">
                                    <button type="button" class="btn btn-primary" (click)="retrieveCardTransactionResult()">Search</button>
                                </div>

                            </div>
                        </form>
                        <form [formGroup]="refundTransactionForm" class="needs-validation" novalidate="" *ngIf="showTransactionResult">
                            <div class="row">
                                <div class="col-sm-5">
                                    <div class="row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom3">Merchant Name:</label></div>
                                        <div class="col-md-6">
                                            <h5>{{ cardTransactionResult.merchantName }}</h5>
                                        </div>           
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom3">User:</label></div>
                                        <div class="col-md-6">
                                            <h5>{{ cardTransactionResult.user }}</h5>
                                        </div>           
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom3">Bank Reference:</label></div>
                                        <div class="col-md-6">
                                            <h5>{{ cardTransactionResult.bankReference }}</h5>
                                        </div>           
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom3">Transaction Reference:</label></div>
                                        <div class="col-md-6">
                                            <h5>{{ cardTransactionResult.transactionReference }}</h5>
                                        </div>           
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom3">Transaction Date:</label></div>
                                        <div class="col-md-6">
                                            <h5>{{ cardTransactionResult.transactionDate | date: 'yyyy-MM-dd h:mm:ss a' }}</h5>
                                        </div>           
                                    </div>            
                                    <div class="row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom3">Card Number:</label></div>
                                        <div class="col-md-6">
                                            <h5>{{ cardTransactionResult.cardNumber }}</h5>
                                        </div>           
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-4 col-md-5">
                                            <label for="validationCustom3">Transaction Amount:</label></div>
                                        <div class="col-md-6">
                                            <h5>{{ cardTransactionResult.transactionAmount | number:'1.2-2' }}</h5>
                                        </div>           
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5"><label for="validationCustom3">Amount to Refund:</label></div>
                                        <div class="col-md-6"><input class="form-control"
                                                formControlName="amountToRefund" id="validationCustom3" type="number" min="0"
                                                required="">
                                        </div>
                                        <div class="valid-feedback">Please Provide a Bank Reference.</div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-4 col-md-5"><label for="validationCustom3"></label></div>
                                        <div class="col-md-6">
                                            <button type="button" class="btn btn-primary" (click)="refundCardTranaction()">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </ng-template>
                </li> -->
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->