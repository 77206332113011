import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { config } from '../app.config';
import { BatchCollectionScheduleRequest } from '../models/report/batch-collection-schedule-request.model';
import { BatchCollectionScheduleReport } from '../models/report/batch-collection-schedule.model';
import { BatchCollectionSummaryRequest } from '../models/report/batch-collection-summary-request.model';
import { BatchCollectionSummary } from '../models/report/batch-collection-summary.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(@Inject(DOCUMENT) private document: any,
  private http: HttpClient) { }

  public getBatchCollectionScheduleReport(batchCollectionScheduleRequest: BatchCollectionScheduleRequest): Observable<BatchCollectionScheduleReport> {
    return new Observable(subscriber => {
      this.http.post<BatchCollectionScheduleReport>(config.C2PAPI + '/reports/recurring-collection-schedule', batchCollectionScheduleRequest)
        .pipe(
          map(response => response || [])
        )
        .subscribe({
          next: (data: BatchCollectionScheduleReport) => {
            subscriber.next(data);
            subscriber.complete(); 
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }
        }
        );
    });
  }

  public getBatchCollectionSummaryReport(batchCollectionSummaryRequest: BatchCollectionSummaryRequest): Observable<BatchCollectionSummary> {
    return new Observable(subscriber => {
      this.http.post<BatchCollectionSummary>(config.C2PAPI + '/reports/recurring-collection-summary', batchCollectionSummaryRequest)
        .pipe(
          map(response => response || [])
        )
        .subscribe({
          next: (data: BatchCollectionSummary) => {
            subscriber.next(data);
            subscriber.complete();  // Ensure the observable completes
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          }
        }
        );
    });
  }
}
