import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "src/app/shared/shared.module";
import { BillingRoutingModule } from "./billing-routing.module";
import { BillingSubscriptionComponent } from "./billing-subscription/billing-subscription.component";
import { BillingInvoiceComponent } from './billing-invoice/billing-invoice.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BillingInvoiceDetailComponent } from './billing-invoice-detail/billing-invoice-detail.component';

@NgModule({
    declarations: [BillingSubscriptionComponent, BillingInvoiceComponent, BillingInvoiceDetailComponent],
    imports: [
      CommonModule,
      BillingRoutingModule,
      SharedModule,
      NgbModule,
      FormsModule,
      ReactiveFormsModule,
    ]
  })

  export class BillingModule { }
  