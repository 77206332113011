<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>POS Terminal and SIM Card Details</h5>
                </div>
                <div class="card-body tab2-card">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>Terminal Details</a>
                            <ng-template ngbNavContent>
                                <form [formGroup]="posTerminalForm" class="needs-validation user-add" novalida>
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="validationCustom0">
                                                        Terminal Name:</label>
                                                </div>
                                                <div class="col-md-7"><input class="form-control" id="validationCustom0"
                                                        type="text" required=""></div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="validationCustom0">
                                                        Terminal Serial Number:</label>
                                                </div>
                                                <div class="col-md-7"><input class="form-control" id="validationCustom0"
                                                        type="text" required=""></div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="validationCustom1">
                                                        Terminal IMEI Number:</label></div>
                                                <div class="col-md-7"><input class="form-control" id="validationCustom1"
                                                        type="text" required=""></div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4">
                                                    <label>Terminal Status:</label>
                                                </div>
                                                <div class="col-md-7">
                                                    <div class="checkbox checkbox-primary">
                                                        <input id="checkbox-primary-1" type="checkbox"
                                                            formControlName="status" data-original-title="" title="">
                                                        <label for="checkbox-primary-1">Active</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>SIM Details</a>
                            <ng-template ngbNavContent>
                                <form [formGroup]="simCardForm" class="needs-validation" novalidate="">
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4">
                                                    <label for="validationCustom3">SIM IMEI:</label>
                                                </div>
                                                <div class="col-md-7"><input class="form-control"
                                                        formControlName="simIMEI" id="validationCustom3"
                                                        type="text" required="">
                                                </div>
                                                <div class="valid-feedback">Please Provide a Bank Reference.</div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label
                                                        for="validationCustom0">
                                                        SIM Carrier</label></div>
                                                <div class="col-md-7">
                                                    <select class="custom-select form-select" required="">
                                                        <option value="">--Select Carrier--</option>
                                                        <option value="1">Cell C</option>
                                                        <option value="3">MTN</option>
                                                        <option value="4">Telkom</option>
                                                        <option value="5">Vodacom</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-xl-3 col-md-4"><label for="validationCustom3"></label>
                                                </div>
                                                <div class="col-md-7">
                                                    <button type="button" class="btn btn-primary">Add</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->