import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { content } from './shared/routes/content-routes';
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { LoginComponent } from './components/auth/login/login.component';
import { CustomerPaymentFormComponent } from './components/customer/customer-payment-form/customer-payment-form.component';
import { CustomerPeachPaymentFormComponent } from './components/customer/customer-peach-payment-form/customer-peach-payment-form.component';
import { CustomerPaymentStatusComponent } from './components/customer/customer-payment-status/customer-payment-status.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CustomerCardPaymentFormComponent } from './components/customer/customer-card-payment-form/customer-card-payment-form.component';
import { CustomerCardPaymentStatusComponent } from './components/customer/customer-card-payment-status/customer-card-payment-status.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/default',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: content,
    canActivate: [AuthGuard],
  },
  {
    path: 'auth/login',
    component: LoginComponent,
  },
  {
    path: 'card/process-payment',
    component: CustomerPaymentFormComponent,
  },
  {
    path: 'card/payment-form',
    component: CustomerPeachPaymentFormComponent,
  },
  {
    path: 'card/payment-form-status',
    component: CustomerPaymentStatusComponent,
  },
  {
    path: 'card/customer-payment',
    component: CustomerCardPaymentFormComponent,
  },
  {
    path: 'card/customer-payment-status',
    component: CustomerCardPaymentStatusComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
