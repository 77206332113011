import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { MerchantModel } from 'src/app/core/models/merchant/merchant.model';
import { MerchantService } from 'src/app/core/services';

@Component({
  selector: 'app-update-merchant',
  templateUrl: './update-merchant.component.html',
  styleUrls: ['./update-merchant.component.scss']
})
export class UpdateMerchantComponent {
  public merchantDetailsForm: UntypedFormGroup;
  public merchantRates: UntypedFormGroup;
  public bankSetup: UntypedFormGroup;
  public gatewaySetup: UntypedFormGroup;
  public collectionsSettings: UntypedFormGroup;
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public modelFooter: NgbDateStruct;
  public active = 1;

  public isLoading: boolean = false;

  collectionRunTime = { hour: 13, minute: 30 };
  collectionResubmissionRunTime = { hour: 14, minute: 30 };
  meridian = true;

  public merchantModel: MerchantModel;

  constructor(private formBuilder: UntypedFormBuilder, private calendar: NgbCalendar,
    public merchantService: MerchantService,
    private _router: Router,
    private spinner: NgxSpinnerService) {
    const receivedMerchant = this._router.getCurrentNavigation()?.extras.state?.data;
    if (receivedMerchant) {
      this.merchantModel = receivedMerchant;
      this.createMerchantForm(receivedMerchant);
      this.createMerchantRatesForm(receivedMerchant);
      this.createMerchantBankSetupForm(receivedMerchant);
      this.createMerchantGatewayForm(receivedMerchant);
      this.createMerchantCollectionSettingsForm(receivedMerchant);
    }
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  createMerchantForm(merchantModel: MerchantModel) {
    this.merchantDetailsForm = this.formBuilder.group({
      merchantName: [merchantModel.merchantName],
      merchantVat: [merchantModel.merchantVat],
      merchantRegistration: [merchantModel.merchantRegistration],
      merchantAddress: [merchantModel.merchantAddress],
      accountContactName: [merchantModel.accountContactName],
      accountContactNumber: [merchantModel.accountContactNumber],
      accountContactMobile: [merchantModel.accountContactMobile],
      accountContactEmail: [merchantModel.accountContactEmail],
      merchantBankMid: [merchantModel.merchantBankMid],
      merchantSetupType: [merchantModel.merchantSetupType],
      merchantSettleUsersDirectly: [merchantModel.merchantSettleUsersDirectly],
      merchantRequiresBatchSettlementReport: [merchantModel.merchantRequiresBatchSettlementReport],
      merchantSetupFeePaid: [merchantModel.merchantSetupFeePaid],
      merchantSettlementsSuspended: [merchantModel.merchantSettlementsSuspended],
      merchantPaysByDebit: [merchantModel.merchantPaysByDebit],
      merchantSendSmsOnline: [merchantModel.merchantSendSmsOnline],
      bankName: [merchantModel.settlementBankAccount?.bankName || ''], // Default values or use a property if available
      accountNumber: [merchantModel.settlementBankAccount?.accountNumber || ''],
      accountType: [merchantModel.settlementBankAccount?.accountType || ''],
      branchCode: [merchantModel.settlementBankAccount?.branchCode || ''],
      title: [merchantModel.rootUser?.title || ''],
      firstname: [merchantModel.rootUser?.firstname || ''],
      lastname: [merchantModel.rootUser?.lastname || ''],
      emailAddress: [merchantModel.rootUser?.emailAddress || ''],
      identityNumber: [merchantModel.rootUser?.identityNumber || ''],
      contactNumber1: [merchantModel.rootUser?.contactNumber1 || ''],
      code: ['']
    });

  }

  createMerchantRatesForm(merchantModel: MerchantModel) {
    this.merchantRates = this.formBuilder.group({
      setupFee: [merchantModel.merchantFeeRates?.setupFee || 0],
      subscriptionFee: [merchantModel.merchantFeeRates?.subscriptionFee || 0],
      posRentalFee: [merchantModel.merchantFeeRates?.posRentalFee || 0],
      smsFee: [merchantModel.merchantFeeRates?.smsFee || 0],
      userFee: [merchantModel.merchantFeeRates?.userFee || 0],
      tokenisationFee: [merchantModel.merchantFeeRates?.tokenisationFee || 0],
      refundFee: [merchantModel.merchantFeeRates?.refundFee || 0],
      riskScreeningFee: [merchantModel.merchantFeeRates?.riskScreeningFee || 0],
      recurringTransactionFee: [merchantModel.merchantFeeRates?.recurringTransactionFee || 0],
      authFee: [merchantModel.merchantFeeRates?.authorisationFee || 0], // Assuming this property exists
      visaCreditCardEcommerceRate: [merchantModel.merchantFeeRates?.visaCreditCardEcommerceRate || 0],
      visaDebitCardEcommerceRate: [merchantModel.merchantFeeRates?.visaDebitCardEcommerceRate || 0],
      mastercardCreditCardEcommerceRate: [merchantModel.merchantFeeRates?.mastercardCreditCardEcommerceRate || 0],
      mastercardDebitCardEcommerceRate: [merchantModel.merchantFeeRates?.mastercardDebitCardEcommerceRate || 0],
      amexCardEcommerceRate: [merchantModel.merchantFeeRates?.amexCardEcommerceRate || 0],
      dinersCardEcommerceRate: [merchantModel.merchantFeeRates?.dinersCardEcommerceRate || 0],
      visaCreditCardPosRate: [merchantModel.merchantFeeRates?.visaCreditCardPosRate || 0],
      visaDebitCardPosRate: [merchantModel.merchantFeeRates?.visaDebitCardPosRate || 0],
      mastercardCreditCardPosRate: [merchantModel.merchantFeeRates?.mastercardCreditCardPosRate || 0],
      mastercardDebitCardPosRate: [merchantModel.merchantFeeRates?.mastercardDebitCardPosRate || 0],
      amexCardPosRate: [merchantModel.merchantFeeRates?.amexCardPosRate || 0],
      dinersCardPosRate: [merchantModel.merchantFeeRates?.dinersCardPosRate || 0],
    });
  }

  createMerchantBankSetupForm(merchantModel: MerchantModel) {
    this.bankSetup = this.formBuilder.group({
      bankMerchantId: [merchantModel.ecommerceBankSetup?.bankMerchantId || ''],
      merchantType: [merchantModel.ecommerceBankSetup?.merchantType || 'TPPP'],
      merchantShortname: [merchantModel.ecommerceBankSetup?.merchantShortname || ''],
      subMerchantIdentifier: [merchantModel.ecommerceBankSetup?.subMerchantIdentifier || ''],
      mccCode: [merchantModel.ecommerceBankSetup?.mccCode || ''],
      posBankMerchantId: [merchantModel.posBankSetup?.bankMerchantId || ''],
      posMerchantType: [merchantModel.posBankSetup?.merchantType || 'ISO'],
      posMerchantShortname: [merchantModel.posBankSetup?.merchantShortname || ''],
      posSubMerchantIdentifier: [merchantModel.posBankSetup?.subMerchantIdentifier || ''],
      posMccCode: [merchantModel.posBankSetup?.mccCode || '']
    });
  }

  createMerchantGatewayForm(merchantModel: MerchantModel) {
    this.gatewaySetup = this.formBuilder.group({
      gateway: [merchantModel.merchantEcommerceGateway?.gateway || ''],
      gatewayUrl: [merchantModel.merchantEcommerceGateway?.gatewayUrl || ''],
      gatewayKey: [merchantModel.merchantEcommerceGateway?.gatewayKey || ''],
      gatewayToken: [merchantModel.merchantEcommerceGateway?.gatewayToken || ''],
      recurringGateway: [merchantModel.merchantRecurringGateway?.gateway || 'PEP'],
      recurringGatewayUrl: [merchantModel.merchantRecurringGateway?.gatewayUrl || ''],
      recurringGatewayKey: [merchantModel.merchantRecurringGateway?.gatewayKey || ''],
      recurringGatewayToken: [merchantModel.merchantRecurringGateway?.gatewayToken || ''],
    });
  }

  createMerchantCollectionSettingsForm(merchantModel: MerchantModel) {
    const settings = merchantModel.merchantCollectionSettings;

    if (settings) {
      this.collectionsSettings = this.formBuilder.group({
        collectionRunTime: [this.convertTimeStringToObject(settings.collectionRunTime) || this.collectionRunTime],
        collectionResubmissionRunTime: [this.convertTimeStringToObject(settings.collectionResubmissionRunTime) || this.collectionResubmissionRunTime],
        collectionFrequency: [settings.collectionFrequency || ''],
        collectionDay: [settings.collectionDay || null],
        collectionAutoRun: [settings.collectionAutoRun || false],
        collectionRerunAttempts: [settings.collectionRerunAttempts || ''],
        collectionAllowDynamicPayday: [settings.collectionAllowDynamicPayday || false],
        earlyDecemberCollection: [settings.earlyDecemberCollection || false],
        earlyDecemberDay: [settings.earlyDecemberDay || null]
      });

      this.collectionRunTime = this.convertTimeStringToObject(settings.collectionRunTime);
      this.collectionResubmissionRunTime = this.convertTimeStringToObject(settings.collectionResubmissionRunTime);
    }
  }

  convertTimeStringToObject(timeString: string): { hour: number; minute: number } | null {
    // Check for "N/A" or any other invalid format
    if (!timeString || timeString === "N/A") {
      return null; // or return a default value, like { hour: 0, minute: 0 }
    }

    const [hours, minutes] = timeString.split(':').map(Number);

    // Check if parsed values are valid
    if (isNaN(hours) || isNaN(minutes)) {
      return null; // Return null for invalid formats
    }

    return { hour: hours, minute: minutes };
  }

  private getUpdatedMerchant(): MerchantModel {
    const updatedMerchant: MerchantModel = new MerchantModel();

    // Iterate over form controls to find changed values in merchantDetailsForm
    for (const controlName in this.merchantDetailsForm.controls) {
      if (this.merchantDetailsForm.controls.hasOwnProperty(controlName)) {
        const currentValue = this.merchantDetailsForm.get(controlName)?.value;
        const originalValue = this.merchantModel[controlName];

        if (currentValue !== originalValue) {
          updatedMerchant[controlName] = currentValue; // Set only changed fields
        }
      }
    }

    // Iterate over form controls for merchantRates
    for (const controlName in this.merchantRates.controls) {
      if (this.merchantRates.controls.hasOwnProperty(controlName)) {
        const currentValue = this.merchantRates.get(controlName)?.value;
        const originalValue = this.merchantModel.merchantFeeRates[controlName];

        if (currentValue !== originalValue) {
          updatedMerchant.merchantFeeRates = updatedMerchant.merchantFeeRates || null;
          updatedMerchant.merchantFeeRates[controlName] = currentValue; // Set only changed fields
        }
      }
    }

    // Iterate over form controls for bankSetup
    for (const controlName in this.bankSetup.controls) {
      if (this.bankSetup.controls.hasOwnProperty(controlName)) {
        const currentValue = this.bankSetup.get(controlName)?.value;
        const originalValue = this.merchantModel.ecommerceBankSetup[controlName];

        if (currentValue !== originalValue) {
          updatedMerchant.ecommerceBankSetup = updatedMerchant.ecommerceBankSetup || null;
          updatedMerchant.ecommerceBankSetup[controlName] = currentValue; // Set only changed fields
        }
      }
    }

    // Iterate over form controls for gatewaySetup
    for (const controlName in this.gatewaySetup.controls) {
      if (this.gatewaySetup.controls.hasOwnProperty(controlName)) {
        const currentValue = this.gatewaySetup.get(controlName)?.value;
        const originalValue = this.merchantModel.merchantEcommerceGateway[controlName];

        if (currentValue !== originalValue) {
          updatedMerchant.merchantEcommerceGateway = updatedMerchant.merchantEcommerceGateway || null;
          updatedMerchant.merchantEcommerceGateway[controlName] = currentValue; // Set only changed fields
        }
      }
    }

    // Iterate over form controls for gatewaySetup
    for (const controlName in this.gatewaySetup.controls) {
      if (this.gatewaySetup.controls.hasOwnProperty(controlName)) {
        const currentValue = this.gatewaySetup.get(controlName)?.value;
        const originalValue = this.merchantModel.merchantEcommerceGateway[controlName];

        if (currentValue !== originalValue) {
          updatedMerchant.merchantEcommerceGateway = updatedMerchant.merchantEcommerceGateway || null;
          updatedMerchant.merchantEcommerceGateway[controlName] = currentValue; // Set only changed fields
        }
      }
    }

    // Iterate over form controls for collectionsSettings
    for (const controlName in this.collectionsSettings.controls) {
      if (this.collectionsSettings.controls.hasOwnProperty(controlName)) {
        const currentValue = this.collectionsSettings.get(controlName)?.value;
        const originalValue = this.merchantModel.merchantCollectionSettings[controlName];

        if (currentValue !== originalValue) {
          updatedMerchant.merchantCollectionSettings = updatedMerchant.merchantCollectionSettings || null;
          updatedMerchant.merchantCollectionSettings[controlName] = currentValue; // Set only changed fields
        }
      }
    }

    return updatedMerchant; // Return the updated model
  }

  updateMerchant() {
    // const updatedMerchant = this.getUpdatedMerchant();

    // // Send updatedMerchant to API only if it has any changes
    // if (Object.keys(updatedMerchant).length > 0) {
    //   this.merchantService.updateMerchant(updatedMerchant).subscribe(response => {
    //     // Handle response, e.g., show success message
    //   });
    // } else {
    //   console.log('No changes detected.');
    // }
  }

}
