<div class="invoice">
    <div class="invoice-title">
        <h1>TAX INVOICE {{ billingInvoiceDetail.invoiceDetails.invoiceNumberPrefix }}{{ billingInvoiceDetail.invoiceDetails.invoiceNumber }}</h1>
    </div>
    <div class="info">
        <p><strong>{{ billingInvoiceDetail.invoiceDetails.merchantDetails.companyName }}</strong></p>
        <p><strong>{{ billingInvoiceDetail.invoiceDetails.merchantDetails.merchantContactPerson.fullname }}</strong></p>
        <p><strong>Email:</strong> {{ billingInvoiceDetail.invoiceDetails.merchantDetails.email }}</p>
        <p><strong>Address:</strong> {{ billingInvoiceDetail.invoiceDetails.merchantDetails.address }}</p>
        <p><strong>Date:</strong> {{ billingInvoiceDetail.invoiceDetails.endDate | date:'dd MMM yyyy' }}</p>
        <p><strong>Amount Owing:</strong> R{{ billingInvoiceDetail.invoiceDetails.total | number:'1.2-2' }}</p>
    </div>
    <div class="company-info">
        <!-- <div class="company-logo">
            <img src="path/to/your/logo.png" alt="Company Logo">
        </div> -->
        <p><strong>Debibridge (Pty) Ltd</strong></p>
        <p><strong>Reg No.</strong> 2022/722151/07</p>
        <p><strong>Address:</strong> 41 Sandstone Drive</p>
        <p>Somerset West</p>
        <p>Cape Town</p>
        <p>7130</p>
    </div>
    <br/>
    <table>
        <thead>
            <tr>
                <th>Description</th>
                <th>Qty</th>
                <th>Unit</th>
                <th>Amt Excl</th>
                <th>VAT</th>
                <th>Total</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of billingInvoiceDetail.invoiceDetails.additionalItems">
                <td>{{ item.billableItemName }}</td>
                <td>{{ item.itemQty | number:'1.2-2' }}</td>
                <td>{{ item.itemAmount }}</td>
                <td>{{ item.itemSubTotal | number:'1.2-2' }}</td>
                <td>{{ item.itemVAT | number:'1.2-2' }}</td>
                <td>{{ item.itemTotal | number:'1.2-2' }}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="3"><strong>Totals:</strong></td>
                <td>R{{ billingInvoiceDetail.invoiceDetails.subTotal | number:'1.2-2' }}</td>
                <td>R{{ billingInvoiceDetail.invoiceDetails.vat | number:'1.2-2' }}</td>
                <td>R{{ billingInvoiceDetail.invoiceDetails.total | number:'1.2-2' }}</td>
            </tr>
        </tfoot>
    </table>
    <div class="bank-details">
        <h4>Banking Details</h4>
        <p>Account Name: Debibridge</p>
        <p>Bank Name: ABSA</p>
        <p>Branch Name: Somerset West</p>
        <p>Account Number: 4105923048</p>
        <p>Branch Code: 632005</p>
    </div>
    <div class="invoice-note">
        <p><strong>Please note:</strong></p>
        <p>Your banking account will be automatically debited with the reference '<strong>DEBIBRIDGE</strong>' on the 1st of each month or the next working day, should the 1st fall on a non-working day. Any bounced debits will attract a fee of R50.00+VAT which will be added to subsequent invoices.
        </p>
    </div>
    <div class="terms-conditions">
        <p><strong>Terms and Conditions</strong></p>
        <p>Any bounced debits will attract a fee of R50.00 which will be added to subsequent invoices.</p>
    </div>
</div>