import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { PaymentFormResponseModel } from 'src/app/core/models/recurring/payment-form-response.model';
import { RecurringService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-customer-peach-payment-form',
  templateUrl: './customer-peach-payment-form.component.html',
  styleUrls: ['./customer-peach-payment-form.component.scss']
})
export class CustomerPeachPaymentFormComponent implements OnInit, OnDestroy {

  public actionLink: string = environment.portal + "/card/payment-form-status";
  public isLoading: boolean = false;
  public isSubmitted: boolean = false;
  public subscription: Subscription;
  public paymentFormRequestIdentifierParam: string;

  constructor(private route: ActivatedRoute, private spinner: NgxSpinnerService,
    private recurringService: RecurringService) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.paymentFormRequestIdentifierParam = params.get('paymentFormRequestIdentifier');
      if (this.paymentFormRequestIdentifierParam != null) {
        this.loadPaymentForm(this.paymentFormRequestIdentifierParam);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadPaymentForm(paymentFormRequestIdentifier: string) {
    this.isSubmitted = true;
    this.isLoading = true;

    this.spinner.show();

    this.recurringService.getPaymentFormIdentifier(paymentFormRequestIdentifier).subscribe({
      next: (data: PaymentFormResponseModel) => {
        this.loadPaymentWidgetsScript(data.paymentFormIdentifier);
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
      }
    })
  }

  loadPaymentWidgetsScript(paymentFormIdentifier: string) {
    const wpwlOptions = {
      style: "plain",
      labels: '{submit: "Tokenise Now"}',
      showCVVHint: true,
      brandDetection: true,
      onReady: () => {
        $(".wpwl-group-cardNumber").after($(".wpwl-group-brand").detach());
        $(".wpwl-group-cvv").after($(".wpwl-group-cardHolder").detach());
        var visa = $(".wpwl-brand:first").clone().removeAttr("class").attr("class", "wpwl-brand-card wpwl-brand-custom wpwl-brand-VISA");
        var master = $(visa).clone().removeClass("wpwl-brand-VISA").addClass("wpwl-brand-MASTER");
        $(".wpwl-brand:first").after($(master)).after($(visa));
        //@ts-ignore
        var imageUrl = "https://eu-test.oppwa.com/v1/static/" + wpwl.cacheVersion + "/img/brand.png";
        $(".wpwl-brand-custom").css("background-image", "url(" + imageUrl + ")");
      },
      onChangeBrand: (e) => {
        $(".wpwl-brand-custom").css("opacity", "0.3");
        $(".wpwl-brand-" + e).css("opacity", "1");
      }
    };

    // Set wpwlOptions as a global variable
    window['wpwlOptions'] = wpwlOptions;

    // Load payment widgets script
    const script = document.createElement('script');
    script.src = 'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=' + paymentFormIdentifier;
    script.onload = () => {
      console.log('Payment widgets script loaded successfully.');
    };
    script.onerror = (error) => {
      console.error('Failed to load payment widgets script:', error);
    };
    document.head.appendChild(script);
  }

}