import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { AuthenticationRequestModel } from 'src/app/core/models/auth/authentication-request.model';
import { LogInAction } from 'src/app/core/store/actions/auth.action';
import { GetMerchantAction } from 'src/app/core/store/actions/merchant.action';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: UntypedFormGroup;
  public registerForm: UntypedFormGroup;
  public active = 1;

  constructor(private formBuilder: UntypedFormBuilder, private store: Store) {
    this.createLoginForm();
  }

  owlcarousel = [
    {
      title: "Welcome to Multikart",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Multikart",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Multikart",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    }
  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      userName: [''],
      password: [''],
    })
  }

  get f() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    
  }

  onSubmit() {
    let authRequest = new AuthenticationRequestModel();
    authRequest.username = this.f.userName.value;
    authRequest.password = this.f.password.value;

    this.store.dispatch(new LogInAction(authRequest));
  }

  retrieveMerchant() {
    this.store.dispatch(new GetMerchantAction());
  }

}
