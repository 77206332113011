import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { UserListModel } from "../../models/user/user-list.model";
import { UserModel } from "../../models/user/user.model";
import { UserService } from "../../services";
import { GetUsersAction, SelectUsersAction } from "../actions/user.action";

export class UsersStateModel {
    public users: UserListModel;
  }
  
  @State<UsersStateModel>({
    name: 'users',
    defaults: {
      users: null
    }
  })
  
  @Injectable()
  export class UsersState {
  
      constructor(private _userService: UserService,
      private _router: Router){
    }
  
    @Selector()
    public static selectedUsersByMerchant(state: UsersStateModel) {
      return state.users;
    }
  
    @Action(SelectUsersAction)
    public selectUsersAction(ctx: StateContext<UsersStateModel>, action: SelectUsersAction) {
      ctx.patchState({ users: action.data});
    }
  
    @Action(GetUsersAction)
    public getUsersAction(ctx: StateContext<UsersStateModel>, action: GetUsersAction) {
      return this._userService.getUsers(action.requestUserSearchParameters).subscribe(data => {
        ctx.patchState({ users: data});
      })  
    }

    @Action(GetUsersAction)
    public getUsersByMerchantAction(ctx: StateContext<UsersStateModel>, action: GetUsersAction) {
      return this._userService.getUsersByMerchant().subscribe(data => {
        ctx.patchState({ users: data});
      })  
    }
  
  }