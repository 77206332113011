import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { data } from "jquery";
import { NavService } from "src/app/shared/service/nav.service";
import { AuthenticationResponseModel } from "../../models/auth/authentication-response.model";
import { AuthService } from "../../services";
import { LoggedInAction, LogInAction } from "../actions/auth.action";

export class AuthStateModel {
  public isLoggedIn: boolean;
  public authenticationResponse: AuthenticationResponseModel;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    isLoggedIn: false,
    authenticationResponse: null
  }
})

@Injectable()
export class AuthState {

  constructor(private _authService: AuthService,
    private navServices: NavService,
    private _router: Router,
    private _store: Store,) {
  }

  @Selector()
  public static userLoggedIn(state: AuthStateModel) {
    return state;
  }

  @Action(LoggedInAction)
  public loggedInAction(ctx: StateContext<AuthStateModel>, action: LoggedInAction) {
    return this._authService.isUserLoggedIn().subscribe(data => {
      ctx.patchState({ isLoggedIn: action.data });
    })
  }

  @Action(LogInAction)
  public loginAction(ctx: StateContext<AuthStateModel>, action: LogInAction) {
    return this._authService.login(action.authRequest).subscribe({
      next: (data) => {
        ctx.patchState({ isLoggedIn: true });
        ctx.patchState({ authenticationResponse: data });
        this.navServices.updateMenuItems(data.role, this._authService.enableEcommerce(), this._authService.enableRecurring());
        this._router.navigate(['/dashboard/default']);
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }
}
