import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './reports.component';
import { NgChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist'
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardTransactionReportComponent } from './card-transaction-report/card-transaction-report.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BatchCollectionScheduleComponent } from './batch-collection-schedule/batch-collection-schedule.component';
import { BatchCollectionSummaryComponent } from './batch-collection-summary/batch-collection-summary.component';
import { RecurringTransactionReportComponent } from './recurring-transaction-report/recurring-transaction-report.component';

@NgModule({
  declarations: [ReportsComponent, CardTransactionReportComponent, RecurringTransactionReportComponent, BatchCollectionScheduleComponent, BatchCollectionSummaryComponent],
  imports: [
    CommonModule,
    NgChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ChartistModule,
    ReportsRoutingModule,
    SharedModule,
    NgbModule
  ]
})
export class ReportsModule { }
