import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { NavService } from "src/app/shared/service/nav.service";
import { AuthenticationResponseModel } from "../../models/auth/authentication-response.model";
import { AuthService, StorageService } from "../../services";
import { CancelMasqueradeAction, MasqueradeAction, MasqueradingAction } from "../actions/auth.action";

export class MasqueradeStateModel {
    public masquerading: boolean;
    public masqueradeResponse: AuthenticationResponseModel;
}

@State<MasqueradeStateModel>({
    name: 'masquerade',
    defaults: {
        masquerading: false,
        masqueradeResponse: null
    }
})

@Injectable()
export class MasqueradeState {

    constructor(private _authService: AuthService,
        private navServices: NavService,
        private storageService: StorageService,
        private _storageService: StorageService,
        private _router: Router) {
    }

    @Selector()
    public static masquerading(state: MasqueradeStateModel) {
        return state.masquerading;
    }

    @Action(MasqueradingAction)
    public setMasquerading(ctx: StateContext<MasqueradeStateModel>, action: MasqueradingAction) {
        return this._authService.isMasquerading().subscribe(data => {
            ctx.patchState({ masquerading: action.masquerading });
        })
    }

    @Action(MasqueradeAction)
    public performMasquerade(ctx: StateContext<MasqueradeStateModel>, action: MasqueradeAction) {
        return this._authService.masquerade(action.masqueradeRequestModel).subscribe({
            next: (data) => {
                ctx.patchState({
                    masquerading: true,
                    masqueradeResponse: data
                });

                const scope = data.scope ? data.scope.split('|') : [];
                const enableEcommerce = scope.includes('ECOM');
                const enableRecurring = scope.includes('RECU');

                this.navServices.updateMenuItems(
                    data.role,
                    enableEcommerce,
                    enableRecurring
                );

                this.navServices.updateHomeTitle(this._storageService.get("merchantName"));

                if (data.role === 'MAD' || data.role === 'USR') {
                    const ecommerceEnabled = enableEcommerce === true;
                    const targetRoute = ecommerceEnabled ? '/transaction-processing/process-transactions' : '/transaction-processing/recurring-transactions';
                    this._router.navigate([targetRoute]);
                } else {
                    this._router.navigate(['/admin/create-merchant']);
                }
            },
            error: (err) => {
                console.error('Masquerade error:', err);
                // Handle error case, e.g., show a notification to the user
            }
        });
    }

    @Action(CancelMasqueradeAction)
    public cancelMasquerade(ctx: StateContext<MasqueradeStateModel>) {
        const success = this._authService.cancelMasquerade();

        if (success) {
            // Update the masquerading state in the store
            ctx.patchState({ masquerading: false });

            // Update the menu items for Super Admin role after canceling masquerade
            this.navServices.updateMenuItems('SAD', false, false);

            // Navigate to the super admin home page
            this._router.navigate(['/admin/create-merchant']);
        } else {
            console.error('Failed to cancel masquerade');
        }
    }
}
