import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { config } from '../app.config';
import { BillingInvoiceDetailsModel } from '../models/billing-invoice/billing-invoice-details.model';
import { BillingInvoiceModel } from '../models/billing-invoice/billing-invoice.model';
import { SelectBillingInvoiceDetailAction } from '../store/actions/billing-invoice.action';

@Injectable({
  providedIn: 'root'
})
export class BillingInvoiceService {

  constructor(@Inject(DOCUMENT) private document: any,
    private http: HttpClient,
    private store: Store) { }

  public getBillingInvoices(billingId: number): Observable<BillingInvoiceModel> {
    return new Observable(subscriber => {
      this.http.get<BillingInvoiceModel>(config.C2PAPI + '/billing/invoice?billingId=' + billingId)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: BillingInvoiceModel) => {
            if ((data == null) || (data == undefined))
              return;
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }

  public getBillingInvoiceDetails(invoiceNumber: number): Observable<BillingInvoiceDetailsModel> {
    return new Observable(subscriber => {
      this.http.get<BillingInvoiceDetailsModel>(config.C2PAPI + '/billing/invoice_details?invoiceNumber=' + invoiceNumber)
        .pipe(
          map(response => {
            return response;
          })
        )
        .subscribe({
          next: (data: BillingInvoiceDetailsModel) => {
            if ((data == null) || (data == undefined))
              return;

            this.store.dispatch(new SelectBillingInvoiceDetailAction(data));
            subscriber.next(data);
          },
          error: (errorResponse: any) => {
          }
        }
        );
    });
  }
}
