<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5> Batch Templates</h5>
        </div>
        <div class="card-body">
            <!-- HTML Template -->
            <a href="assets/templates/debtorRecurringTemplate.xlsx" download="debtorRecurringTemplate.xlsx">Create Debtor and Recurring Transaction</a>
            <!-- HTML Template -->
            <br />
            <br />
            <a href="assets/templates/variableRecurringTemplate.xlsx" download="variableRecurringTemplate.xlsx">Variable Value Recurring Transactions</a>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->