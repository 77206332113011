import { AuthenticationRequestModel } from "../../models/auth/authentication-request.model";
import { AuthenticationResponseModel } from "../../models/auth/authentication-response.model";

export class LoggedInAction {
    static readonly type = '[Auth] Logged In Successfully';
    constructor(public data : boolean) {}
 }

 export class LogInAction {
    static readonly type = '[Auth] Log In';
    constructor(public authRequest : AuthenticationRequestModel) {}
 }