<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <form [formGroup]="searchCriteriaForm" class="needs-validation user-add" novalidate="">
                <div class="permission-block">
                    <div class="attribute-blocks">
                        <!-- <h5 class="f-w-400 mb-2">Date Range</h5> -->
                        <div class="row mb-3">
                            <div class="col-xl-2 col-sm-4">
                                <h5>Start Date</h5>
                                <div class="input-group input-grp-p">
                                    <input class="datepicker-here form-control digits" ngbDatepicker
                                        formControlName="start_date" #f="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn calendar" (click)="f.toggle()" type="button"><i
                                                class="fa fa-calendar"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-sm-4">
                                <h5>End Date</h5>
                                <div class="input-group input-grp-p">
                                    <input class="datepicker-here form-control digits" ngbDatepicker
                                        formControlName="end_date" #c="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn calendar" (click)="c.toggle()" type="button"><i
                                                class="fa fa-calendar"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-xl-3 col-sm-4">
                                <h5>Collection Batch Number</h5>
                                <input class="form-control" id="validationCustom0" type="text" formControlName="collectionBatchNumber"
                                    required="">
                            </div>
                            <div class="col-xl-2 col-sm-4">
                                <h5>Debtor Name</h5>
                                <input class="form-control" id="validationCustom0" type="text" formControlName="debtorName"
                                    required="">
                            </div>
                            <div class="col-xl-2 col-sm-4">
                                <h5>Debtor Surname</h5>
                                <input class="form-control" id="validationCustom0" type="text" formControlName="debtorSurname"
                                    required="">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-xl-3 col-sm-4">
                                <h5>Transaction Reference</h5>
                                <input class="form-control" id="validationCustom0" type="text" formControlName="transactionReference"
                                    required="">
                            </div>
                            <div class="col-xl-2 col-sm-4">
                                <h5>Status</h5>
                                <select class="custom-select form-select" formControlName="status">
                                    <option value="">-- Select Status --</option>
                                    <option value="PENDING">PENDING</option>
                                    <option value="SUCCESS">SUCCESS</option>
                                    <option value="SUBMITTED">SUBMITTED</option>
                                    <option value="FAILED">FAILED</option>
                                    <option value="CANCELLED">CANCELLED</option>
                                  </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pull-right">
                    <button type="button" class="btn btn-primary" (click)="search()">Search</button>
                </div>
            </form>
        </div>
        <div class="card-body tab2-card">
            <div *ngIf="isLoading">
                <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple"
                    [fullScreen]="false">
                    <br />
                    <br />
                    <br />
                    <p style="color: white"> Loading ... </p>
                </ngx-spinner>
            </div>
            <br />
            <br />
            <div id="collectionScheduleReport" class="category-table custom-datatable transcation-datatable">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col" sortable="user">User</th>
                                <th scope="col" sortable="debtorName">Debtor Name</th>
                                <th scope="col" sortable="debtorSurname">Debtor Surname</th>
                                <th scope="col" sortable="collectionBatchNumber">Collection Batch Number</th>
                                <th scope="col" sortable="transactionReference">Transaction Reference</th>
                                <th scope="col" sortable="transactionAmount">Transaction Amount</th>
                                <th scope="col" sortable="collectionDate">Collection Date</th>
                                <th scope="col" sortable="status">Status</th>
                                <th scope="col" sortable="rerunAttempt">Rerun Attempt</th>
                                <th scope="col" sortable="active">Active</th>
                                <th scope="col">Cancel</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of batchCollectionSummaryItems">
                                <td>{{ item.user }}</td>
                                <td>{{ item.debtorName }}</td>
                                <td>{{ item.debtorSurname }}</td>
                                <td>{{ item.collectionBatchNumber }}</td>
                                <td>{{ item.transactionReference }}</td>
                                <td>R{{ item.transactionAmount | number:'1.2-2' }}</td>
                                <td>{{ item.collectionDate | date:'yyyy-MM-dd' }}</td>
                                <td>{{ item.status }}</td>
                                <td>{{ item.rerunAttempt }}</td>
                                <td>{{ item.active ? 'Yes' : 'No' }}</td>
                                <td>
                                    <a href="javascript:void(0)"><i class='fa fa-times f-12'></i></a>&nbsp;
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-center p-2">
                        <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                        [pageSize]="service.pageSize" [maxSize]="10">
                        </ngb-pagination>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->