<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Transactions</h5>
                </div>
                <div class="card-body tab2-card">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <div *ngIf="isLoading">
                                <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple"
                                    [fullScreen]="false">
                                    <br />
                                    <br />
                                    <br />
                                    <p style="color: white"> Loading ... </p>
                                </ngx-spinner>
                            </div>
                            <a ngbNavLink>Search Criteria</a>
                            <ng-template ngbNavContent>
                                <form [formGroup]="searchCriteriaForm" class="needs-validation user-add" novalidate="">
                                    <div class="permission-block">
                                        <div class="attribute-blocks">
                                            <h5 class="f-w-600 mb-3">Date Range</h5>
                                            <div class="row mb-3">
                                                <div class="col-xl-4 col-sm-6">
                                                    <div class="input-group input-grp-p">
                                                        <input class="datepicker-here form-control digits" ngbDatepicker
                                                            formControlName="start_date" #f="ngbDatepicker">
                                                        <div class="input-group-append">
                                                            <button class="btn calendar" (click)="f.toggle()"
                                                                type="button"><i class="fa fa-calendar"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-sm-6">
                                                    <div class="input-group input-grp-p">
                                                        <input class="datepicker-here form-control digits" ngbDatepicker
                                                            formControlName="end_date" #c="ngbDatepicker">
                                                        <div class="input-group-append">
                                                            <button class="btn calendar" (click)="c.toggle()"
                                                                type="button"><i class="fa fa-calendar"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="pull-right">
                                    <button type="button" class="btn btn-primary" (click)="search()">Search</button>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>Report</a>
                            <ng-template ngbNavContent>
                                <!-- <div class="btn-popup pull-right">
                                    <a class="btn btn-primary" (click)="search()">Refresh</a>
                                </div> -->
                                <div id="transactionReport" class="category-table custom-datatable transcation-datatable">
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col" sortable="merchantName" (sort)="onSort($event)">
                                                        Merchant Name
                                                    </th>
                                                    <th scope="col" sortable="user" (sort)="onSort($event)">User</th>
                                                    <th scope="col" sortable="transactionReference" (sort)="onSort($event)">
                                                        Transaction Reference</th>
                                                    <th scope="col" sortable="amount" (sort)="onSort($event)">Amount</th>
                                                    <th scope="col" sortable="batch" (sort)="onSort($event)">Batch</th>
                                                    <th scope="col" sortable="transactionDate" (sort)="onSort($event)">
                                                        Transaction Date</th>
                                                    <th scope="col" sortable="cardBrand" (sort)="onSort($event)">
                                                        Card Brand</th>
                                                    <th scope="col" sortable="transactionType" (sort)="onSort($event)">
                                                        Transaction Type</th>
                                                    <th scope="col" sortable="transactionStatus" (sort)="onSort($event)">
                                                        Transaction Status</th>
                                                    <th scope="col" sortable="transactionDescription" (sort)="onSort($event)">
                                                        Transaction Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of cardPaymentTransactions">
                                                    <td>{{ item.merchant }}</td>
                                                    <td>{{ item.user }}</td>
                                                    <td>{{ item.transactionReference }}</td>
                                                    <td>R{{ item.amount | number:'1.2-2' }}</td>
                                                    <td>{{ item.batch }}</td>
                                                    <td>{{ item.transactionDate | date:'yyyy-MM-dd hh:mm:ss a' }}</td>
                                                    <td>{{ item.cardBrand }}</td>
                                                    <td>
                                                        <ng-container *ngIf="item.transactionType === 'Authorisation'">
                                                            <span class="badge badge-danger">{{ item.transactionType }}</span>
                                                        </ng-container>
                                                        <ng-container *ngIf="item.transactionType === 'Reversal'">
                                                            <span class="badge badge-primary">{{ item.transactionType }}</span>
                                                        </ng-container>
                                                        <ng-container *ngIf="item.transactionType === 'Settlement'">
                                                            <span class="badge badge-success">{{ item.transactionType }}</span>
                                                        </ng-container>
                                                    </td>
                                                    <td>{{ item.transactionStatus }}</td>
                                                    <td>{{ item.transactionDescription }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="d-flex justify-content-center p-2">
                                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                            [pageSize]="service.pageSize" [maxSize]="10">
                                            </ngb-pagination>
                                        </div> 
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
                <!-- <div class="card-body tab2-card">
                    <div *ngIf="isLoading">
                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple"
                            [fullScreen]="false">
                            <br />
                            <br />
                            <br />
                            <p style="color: white"> Loading ... </p>
                        </ngx-spinner>
                    </div>
                    <form [formGroup]="searchCriteriaForm" class="needs-validation user-add" novalidate="">
                        <div class="permission-block">
                            <div class="attribute-blocks">
                                <h5 class="f-w-600 mb-3">Date Range</h5>
                                <div class="row mb-3">
                                    <div class="col-xl-4 col-sm-6">
                                        <div class="input-group input-grp-p">
                                            <input class="datepicker-here form-control digits" ngbDatepicker
                                                formControlName="start_date" #f="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn calendar" (click)="f.toggle()" type="button"><i
                                                        class="fa fa-calendar"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-sm-6">
                                        <div class="input-group input-grp-p">
                                            <input class="datepicker-here form-control digits" ngbDatepicker
                                                formControlName="end_date" #c="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn calendar" (click)="c.toggle()" type="button"><i
                                                        class="fa fa-calendar"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-sm-4">
                                        <button type="button" class="btn btn-primary" (click)="search()">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <br />
                    <br />
                    <div id="transactionReport" class="category-table custom-datatable transcation-datatable">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" sortable="merchantName" (sort)="onSort($event)">
                                            Merchant Name
                                        </th>
                                        <th scope="col" sortable="user" (sort)="onSort($event)">User</th>
                                        <th scope="col" sortable="transactionReference" (sort)="onSort($event)">
                                            Transaction Reference</th>
                                        <th scope="col" sortable="amount" (sort)="onSort($event)">Amount</th>
                                        <th scope="col" sortable="batch" (sort)="onSort($event)">Batch</th>
                                        <th scope="col" sortable="transactionDate" (sort)="onSort($event)">
                                            Transaction Date</th>
                                        <th scope="col" sortable="cardBrand" (sort)="onSort($event)">
                                            Card Brand</th>
                                        <th scope="col" sortable="transactionType" (sort)="onSort($event)">
                                            Transaction Type</th>
                                        <th scope="col" sortable="transactionStatus" (sort)="onSort($event)">
                                            Transaction Status</th>
                                        <th scope="col" sortable="transactionDescription" (sort)="onSort($event)">
                                            Transaction Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of cardPaymentTransactions">
                                        <td>{{ item.merchant }}</td>
                                        <td>{{ item.user }}</td>
                                        <td>{{ item.transactionReference }}</td>
                                        <td>R{{ item.amount | number:'1.2-2' }}</td>
                                        <td>{{ item.batch }}</td>
                                        <td>{{ item.transactionDate | date:'yyyy-MM-dd hh:mm:ss a' }}</td>
                                        <td>{{ item.cardBrand }}</td>
                                        <td>
                                            <ng-container *ngIf="item.transactionType === 'Authorisation'">
                                                <span class="badge badge-danger">{{ item.transactionType }}</span>
                                            </ng-container>
                                            <ng-container *ngIf="item.transactionType === 'Reversal'">
                                                <span class="badge badge-primary">{{ item.transactionType }}</span>
                                            </ng-container>
                                            <ng-container *ngIf="item.transactionType === 'Settlement'">
                                                <span class="badge badge-success">{{ item.transactionType }}</span>
                                            </ng-container>
                                        </td>
                                        <td>{{ item.transactionStatus }}</td>
                                        <td>{{ item.transactionDescription }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center p-2">
                                <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                [pageSize]="service.pageSize" [maxSize]="10">
                                </ngb-pagination>
                            </div> 
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->