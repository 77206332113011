<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Confirm Settlements</h5>
                </div>
                <div class="card-body tab2-card">
                    <div class="btn-popup pull-right">
                        <a class="btn btn-primary">Generate Settlements</a>
                    </div>
                    <div id="transactionReport" class="category-table custom-datatable transcation-datatable">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" sortable="merchantName" (sort)="onSort($event)">
                                            Channel
                                        </th>
                                        <th scope="col" sortable="lastPaid" (sort)="onSort($event)">Total Transaction
                                            Value</th>
                                        <th scope="col" sortable="visaTransactions" (sort)="onSort($event)">Nett
                                            Settlement Value
                                        </th>
                                        <th scope="col" sortable="visaFees" (sort)="onSort($event)">
                                            Merchant Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Ecommerce</td>
                                        <td>R2 489.28</td>
                                        <td>R1 489.28</td>
                                        <td>5</td>
                                    </tr>
                                    <tr>
                                        <td>POS</td>
                                        <td>R2 489.28</td>
                                        <td>R1 489.28</td>
                                        <td>2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center p-2">
                                <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                    [pageSize]="service.pageSize" [maxSize]="10">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->