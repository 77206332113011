import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DebtorSummaryItemModel } from 'src/app/core/models/recurring/debtor-summary-item.model';
import { ModifyDebtorDetailRequest } from 'src/app/core/models/recurring/modify-debtor-detail-request.model';
import { ModifyDebtorDetailResponse } from 'src/app/core/models/recurring/modify-debtor-detail-response.model';
import { RecurringService } from 'src/app/core/services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-debtor-modify-modal',
  templateUrl: './debtor-modify-modal.component.html',
  styleUrls: ['./debtor-modify-modal.component.scss']
})
export class DebtorModifyModalComponent implements OnInit, OnDestroy {

  @ViewChild("debtorModifyModal", { static: false }) DebtorModifyModalModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  modal: NgbModalRef;

  public debtorSummaryItemModel: DebtorSummaryItemModel;

  public originalDebtorSummaryItemModel: any;
  public modifyDebtorDetailResponse: ModifyDebtorDetailResponse;

  public isLoading: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal, private _recurringService: RecurringService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.originalDebtorSummaryItemModel = { ...this.debtorSummaryItemModel };
  }

  async openModal(debtorSummaryItemModel: DebtorSummaryItemModel) {
    this.modalOpen = true;
    this.modal = this.modalService.open(this.DebtorModifyModalModal, {
      ariaLabelledBy: 'Debtor-Modify-Modal',
      centered: true,
      size: 'size',
      windowClass: 'debtor-modify-modal'
    });

    this.modal.result.then((result) => {
      `Result ${result}`
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    this.debtorSummaryItemModel = debtorSummaryItemModel;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  modifyDebtorDetails() {
    this.isLoading = true;
    this.spinner.show();
    const { requestIdentifier, collectionDay, collectionAutoRerun, allowDynamicPayday, earlyDecemberCollection } = this.debtorSummaryItemModel;
    const changes: ModifyDebtorDetailRequest = {
      requestIdentifier,
      ...(collectionDay !== this.originalDebtorSummaryItemModel.instalmentDay && { collectionDay }),
      ...(collectionAutoRerun !== this.originalDebtorSummaryItemModel.collectionAutoRerun && { collectionAutoRerun }),
      ...(allowDynamicPayday !== this.originalDebtorSummaryItemModel.allowDynamicPayday && { allowDynamicPayday }),
      ...(earlyDecemberCollection !== this.originalDebtorSummaryItemModel.allowEarlyDecemberCollection && { earlyDecemberCollection })
    };

    const { requestIdentifier: _, ...modifiedFields } = changes;

    if (Object.keys(modifiedFields).length > 0) { // Ensure there are changes
      this._recurringService.modifyDebtorRegistration(changes).subscribe(
        data => {
          this.modifyDebtorDetailResponse = data;
          this.isLoading = false;
          this.spinner.hide();
          Swal.fire({
            icon: "success",
            title: "Debtor Details Updated!",
            text: "The debtor details have been successfully updated.."
          });
          this.modal.close('Close');
        },
        error => {
          console.error('Error:', error);
          this.isLoading = false;
          this.spinner.hide();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Debtor details update failed!"
          });
        }
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Data remains unchanged.",
        text: "No changes detected!"
      });
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

}
