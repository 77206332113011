import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { CancelMasqueradeAction } from 'src/app/core/store/actions/auth.action';
import { MasqueradeState } from 'src/app/core/store/state/masquerade.state';
import { NavService } from '../../service/nav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile : boolean;
  public isMasquerading : boolean = false;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  public subscription: Subscription = new Subscription();

  @Select(MasqueradeState.masquerading) masquerading$: Observable<boolean>;
  constructor(public navServices: NavService, private store: Store) { 
    this.subscription.add(this.masquerading$.subscribe(data => {
      this.isMasquerading = data;
    }));
  }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  ngOnInit() {  }

  cancelMasquerade() {
    this.store.dispatch(new CancelMasqueradeAction());
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
