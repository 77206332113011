import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BillingInvoiceDetailComponent } from "./billing-invoice-detail/billing-invoice-detail.component";
import { BillingInvoiceComponent } from "./billing-invoice/billing-invoice.component";
import { BillingSubscriptionComponent } from "./billing-subscription/billing-subscription.component";

const routes: Routes = [
    {
      path: '',
      children: [
        {
          path: 'billing-subscription',
          component: BillingSubscriptionComponent,
          data: {
            title: "Billing Subscriptions",
            breadcrumb: "Billing Subscriptions"
          }
        },
        {
          path: 'billing-invoice',
          component: BillingInvoiceComponent,
          data: {
            title: "Billing Invoice",
            breadcrumb: "Billing Invoice"
          }
        },
        {
          path: 'billing-invoice-detail',
          component: BillingInvoiceDetailComponent,
          data: {
            title: "Invoice Detail",
            breadcrumb: "Invoice Detail"
          }
        }
      ]
    }
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class BillingRoutingModule { }
  