<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Users</h5>
        </div>
        <div class="card-body">
            <div class="btn-popup pull-right">
                <a class="btn btn-primary" [routerLink]="'/users/create-user'">Create User</a>
            </div>
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div *ngIf="isLoading">
                    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff"
                        type="ball-scale-multiple" [fullScreen]="false">
                        <br />
                        <br />
                        <br />
                        <p style="color: white"> Loading ... </p>
                    </ngx-spinner>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <!-- <th scope="col">Edit</th> -->
                                <th scope="col" sortable="firstName" (sort)="onSort($event)">First Name</th>
                                <th scope="col" sortable="lastName" (sort)="onSort($event)">Last Name</th>
                                <th scope="col" sortable="merchant" (sort)="onSort($event)">Merchant Name</th>
                                <th scope="col" sortable="contactNumber" (sort)="onSort($event)">Mobile</th>
                                <th scope="col" sortable="emailAddress" (sort)="onSort($event)">Email</th>
                                <th scope="col" sortable="accountLocked" (sort)="onSort($event)">Account Locked</th>
                                <th scope="col" sortable="authSettle" (sort)="onSort($event)">Auth & Settle</th>
                                <th scope="col" sortable="userType" (sort)="onSort($event)">Role</th>
                                <th scope="col">Masquerade</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of userList">
                                <!-- <td>
                                    <a href="javascript:void(0)"><i class='fa fa-edit f-12'></i></a>&nbsp;
                                </td> -->
                                <td>
                                    {{item.firstname}}
                                </td>
                                <td>{{item.lastname}}</td>
                                <td>{{item.merchant}}</td>
                                <td>{{item.contactNumber}}</td>
                                <td>{{item.emailAddress}}</td>
                                <td>{{ item.accountLocked ? 'Yes' : 'No' }}</td>
                                <td>{{ item.authSettle ? 'Yes' : 'No' }}</td>                                
                                <td>{{item.userType}}</td>
                                <td>
                                    <a *ngIf="item.userType !== 'Integrator' && item.userType !== 'System Administrator'" href="javascript:void(0)" (click)="masqueradeUser(item.emailAddress, item.merchant)"><i class="fa fa-sign-in f-12"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    <div class="d-flex justify-content-center p-2">
                        <ngb-pagination
                          [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->