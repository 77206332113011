import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddBankSettlementComponent } from './bank-settlement/add-bank-settlement/add-bank-settlement.component';
import { ListBankStatementComponent } from './bank-settlement/list-bank-statement/list-bank-statement.component';
import { ChargebacksComponent } from './chargebacks/chargebacks.component';
import { CreateMerchantComponent } from './merchants/create-merchant/create-merchant.component';
import { MerchantListComponent } from './merchants/merchant-list/merchant-list.component';
import { UpdateMerchantComponent } from './merchants/update-merchant/update-merchant.component';
import { AddPosTerminalComponent } from './pos-terminal/add-pos-terminal/add-pos-terminal.component';
import { ListPosTerminalComponent } from './pos-terminal/list-pos-terminal/list-pos-terminal.component';
import { ReconSettlementReportComponent } from './reports/recon-settlement-report/recon-settlement-report.component';
import { RunBillingComponent } from './run-billing/run-billing.component';
import { DailySettlementComponent } from './settlements/daily-settlement/daily-settlement.component';
import { UnsettledEcommerceDetailComponent } from './settlements/unsettled-ecommerce-detail/unsettled-ecommerce-detail.component';
import { UnsettledEcommerceComponent } from './settlements/unsettled-ecommerce/unsettled-ecommerce.component';
import { UnsettledPosComponent } from './settlements/unsettled-pos/unsettled-pos.component';
import { UnsettledTransactionsComponent } from './settlements/unsettled-transactions/unsettled-transactions.component';
import { UnpaidsComponent } from './unpaids/unpaids.component';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { UserListComponent } from './users/user-list/user-list.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'create-merchant',
                component: CreateMerchantComponent,
                data: {
                    title: "Create Merchant",
                    breadcrumb: "Create Merchant"
                }
            },
            {
                path: 'merchant-list',
                component: MerchantListComponent,
                data: {
                    title: "Merchants",
                    breadcrumb: "Merchants"
                }
            },
            {
                path: 'create-user',
                component: CreateUserComponent,
                data: {
                    title: "Users",
                    breadcrumb: "Users"
                }
            },
            {
                path: 'user-list',
                component: UserListComponent,
                data: {
                    title: "Users",
                    breadcrumb: "Users"
                }
            },
            {
                path: 'update-merchant',
                component: UpdateMerchantComponent,
                data: {
                    title: "Update Merchant",
                    breadcrumb: "Update Merchant"
                }
            },
            {
                path: 'recon-settlements-report',
                component: ReconSettlementReportComponent,
                data: {
                    title: "Recon Settlements Report",
                    breadcrumb: "Recon Settlements Report"
                }
            },
            {
                path: 'unpaids-management',
                component: UnpaidsComponent,
                data: {
                    title: "Unpaids Management",
                    breadcrumb: "Unpaids Management"
                }
            },
            {
                path: 'subscriptions-and-billings',
                component: RunBillingComponent,
                data: {
                    title: "Subscriptions and Billings",
                    breadcrumb: "Subscriptions and Billings"
                }
            },
            {
                path: 'add-bank-settlement',
                component: AddBankSettlementComponent,
                data: {
                    title: "Bank Settlement Management",
                    breadcrumb: "Add Bank Settlement"
                }
            },
            {
                path: 'list-bank-settlement',
                component: ListBankStatementComponent,
                data: {
                    title: "Bank Settlement Management",
                    breadcrumb: "List Bank Settlement"
                }
            },
            {
                path: 'chargebacks',
                component: ChargebacksComponent,
                data: {
                    title: "Chargeback Management",
                    breadcrumb: "Chargeback Management"
                }
            },
            {
                path: 'add-pos-terminal',
                component: AddPosTerminalComponent,
                data: {
                    title: "POS Terminal Management",
                    breadcrumb: "Add POS Terminal"
                }
            },
            {
                path: 'list-pos-terminal',
                component: ListPosTerminalComponent,
                data: {
                    title: "POS Terminal Management",
                    breadcrumb: "List POS Terminals"
                }
            },
            {
                path: 'daily-settlement',
                component: DailySettlementComponent,
                data: {
                    title: "Daily Settlements",
                    breadcrumb: "Daily Settlements Summary"
                }
            },
            {
                path: 'unsettled-transactions',
                component: UnsettledTransactionsComponent,
                data: {
                    title: "Settlements",
                    breadcrumb: "Unsettled Transactions"
                }
            },
            {
                path: 'unsettled-ecommerce',
                component: UnsettledEcommerceComponent,
                data: {
                    title: "Ecommerce Settlements",
                    breadcrumb: "Unsettled Ecommerce Summary"
                }
            },
            {
                path: 'unsettled-ecommerce-detail',
                component: UnsettledEcommerceDetailComponent,
                data: {
                    title: "Ecommerce Settlements",
                    breadcrumb: "Unsettled Ecommerce Transactions"
                }
            },
            {
                path: 'unsettled-pos',
                component: UnsettledPosComponent,
                data: {
                    title: "Settlements",
                    breadcrumb: "Unsettled POS Transactions"
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule { }
