import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { RequestTransactionReportParameters } from 'src/app/core/models/common/request-parameters.model';
import { TransactionItemModel } from 'src/app/core/models/transaction/transaction-item.model';
import { TransactionReportModel } from 'src/app/core/models/transaction/transaction.model';
import { TransactionService } from 'src/app/core/services';
import { GetTransactionReportAction, SelectTransactionReportAction } from 'src/app/core/store/actions/transaction.action';
import { TransactionState } from 'src/app/core/store/state/transaction.state';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { TRANSACTIONDB, TransactionsDB } from 'src/app/shared/tables/transactions';

@Component({
  selector: 'app-transaction-report',
  templateUrl: './transaction-report.component.html',
  styleUrls: ['./transaction-report.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class TransactionReportComponent implements OnInit, OnDestroy {

  public searchCriteriaForm: UntypedFormGroup;
  public date: { year: number, month: number };
  public active = 1;

  public transactionReport: TransactionReportModel;
  public transactions: TransactionItemModel[] = [];
  public tableItem$: Observable<TransactionItemModel[]>;
  public subscription: Subscription;
  public searchText;
  total$: Observable<number>;

  @Select(TransactionState.selectedTransactionReport) selectedTransactionReport$: Observable<TransactionReportModel>;

  constructor(private formBuilder: UntypedFormBuilder, public service: TableService, private store: Store,
    private _transactionService: TransactionService) {
    this.createSearchCriteriaFormorm();

    // this.subscription = new Subscription();
    // this.subscription.add(this.selectedTransactionReport$.subscribe(data => {
    //   this.transactionReport = data;
    //   this.transactions$ = service.tableItem$;
    //   this.total$ = service.total$;
    //   this.service.setUserData(this.transactionReport.transactionDetails);
    // }));

  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  createSearchCriteriaFormorm() {
    this.searchCriteriaForm = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
    })
  }

  retrieveTransactionReport() {
    let requestTransactionReportParameters: RequestTransactionReportParameters = {
      startDate: '2016-01-01',
      endDate: '2024-06-10',
      searchTerm: '',
      searchType: 'all',
      batchNumber: '',
      settlements: false,
      limit: 200,
      offset: 0,
      sort: "desc",
      sortField: "transactionDate"
    }

    this.store.dispatch(new GetTransactionReportAction(requestTransactionReportParameters));
  }

  get searchRecurringReport() {
    return this.searchCriteriaForm.controls;
  }

  search() {
    const recurringReport = this.searchRecurringReport;
    let startDate = recurringReport.start_date.value;
    let endDate = recurringReport.end_date.value;

    let requestTransactionReportParameters: RequestTransactionReportParameters = {
      startDate: "" + startDate.year + "-" + startDate.month.toString().padStart(2, '0') + "-" + startDate.day.toString().padStart(2, '0'),
      endDate: "" + endDate.year + "-" + endDate.month.toString().padStart(2, '0') + "-" + endDate.day.toString().padStart(2, '0'),
      searchTerm: '',
      searchType: 'all',
      batchNumber: '',
      settlements: false,
      limit: 200,
      offset: 0,
      sort: "desc",
      sortField: "transactionDate"
    }

    this.store.dispatch(new GetTransactionReportAction(requestTransactionReportParameters));
  }

  ngOnInit() {
    // this.retrieveTransactionReport();
    var currentDate = new Date();

    let defaultDate = "" + currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1).toString().padStart(2, '0') + "-" + currentDate.getDate().toString().padStart(2, '0');

    let requestTransactionReportParameters: RequestTransactionReportParameters = {
      startDate: '2024-05-01',
      endDate: defaultDate,
      searchTerm: '',
      searchType: 'all',
      batchNumber: '',
      settlements: false,
      limit: 200,
      offset: 0,
      sort: "desc",
      sortField: "transactionDate"
    }

    this.getTransactionReport(requestTransactionReportParameters);
  }

  async getTransactionReport(requestTransactionReportParameters: RequestTransactionReportParameters) {
    this._transactionService.getTransactionReport(requestTransactionReportParameters).subscribe(data => {
      this.transactionReport = data;
      this.transactions = this.transactionReport.transactionDetails;
      this.tableItem$ = this.service.tableItem$;
      this.total$ = this.service.total$;
      this.service.setUserData(this.transactions);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
