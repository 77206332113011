import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { MerchantModel } from 'src/app/core/models/merchant/merchant.model';
import { UserResponseModel } from 'src/app/core/models/user/user-response.model';
import { UserModel } from 'src/app/core/models/user/user.model';
import { MerchantService, UserService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  public userForm: UntypedFormGroup;
  public permissionForm: UntypedFormGroup;
  public active = 1;

  public userResponseModel: UserResponseModel;

  public isLoading: boolean = false;
  public isSubmitted: boolean = false;

  public merchants: MerchantModel[] = [];

  public subscription: Subscription = new Subscription();

  constructor(private formBuilder: UntypedFormBuilder, 
    private _merchantService: MerchantService,
    private userService: UserService,
    private spinner: NgxSpinnerService) {
    this.createUserForm();
    this.createPermissionForm();
  }

  createUserForm() {
    this.userForm = this.formBuilder.group({
      merchant: new FormControl(""),
      userType: new FormControl(""),
      title: new FormControl(""),
      firstname: new FormControl(""),
      lastname: new FormControl(""),
      identityNumber: new FormControl(""),
      contactNumber: new FormControl(""),
      mobile: new FormControl(""),
      email: new FormControl(""),
      bankName: new FormControl(""),
      bankAccountNumber: new FormControl(""),
      bankAccountType: new FormControl(""),
      bankBranchCode: new FormControl(""),
      authSettle: new FormControl(""),
      address: new FormControl("")
    })
  }

  get userRequestDetails() {
    return this.userForm.controls;
  }

  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    })
  }

  ngOnInit() {
    this.isLoading = true;
    this.spinner.show();
    this._merchantService.getMerchants().subscribe(data => {
      this.merchants = data;
      this.isLoading = false;
      this.spinner.hide();
    });
  }

  createUser() {
    if (this.userForm.get('userType').value == 'MAD' ||
      this.userForm.get('userType').value == 'USR') {
      this.createMerchantUser();
    } else if (this.userForm.get('userType').value == 'SAD') {
      this.createSuperAdmin();
    } else {
      this.createIntegrator();
    }
  }

  createMerchantUser() {
    this.isSubmitted = true;
    this.isLoading = true;

    this.spinner.show();

    const userModel = new UserModel();
    const userRequest = this.userRequestDetails;

    userModel.userType = userRequest.userType?.value ?? null;
    userModel.merchant = userRequest.merchant?.value ?? null;
    userModel.title = userRequest.title?.value ?? null;
    userModel.firstName = userRequest.firstname?.value ?? '';
    userModel.lastName = userRequest.lastname?.value ?? '';
    userModel.identityNumber = userRequest.identityNumber?.value ?? '';
    userModel.contactNumber = userRequest.contactNumber?.value ?? '';
    userModel.mobile = userRequest.mobile?.value ?? '';
    userModel.email = userRequest.email?.value ?? '';

    if (this.userForm.get('bankName')?.value) {
      userModel.userBankRequest = {
        bankName: userRequest.bankName?.value ?? '',
        bankAccountNumber: userRequest.bankAccountNumber?.value ?? '',
        bankAccountType: userRequest.bankAccountType?.value ?? '',
        bankBranchCode: userRequest.bankBranchCode?.value ?? ''
      };
    } else {
      userModel.userBankRequest = null;
    }

    userModel.accountLocked = false;
    userModel.authSettle = userRequest.authSettle.value === 'true';;
    userModel.waiveUserCharge = false;
    userModel.settlementsSuspended = false;
    userModel.address = userRequest.address.value;
    userModel.portalLink = environment.commercehubPortal;

    this.userService.createUser(userModel).subscribe({
      next: (data: UserResponseModel) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();

        if (data.success) {
          Swal.fire({
            icon: "success",
            title: "User Created Successfully!",
            text: data.message
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data.message
          });
        }
      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorResponse
        });
      }
    });
  }

  createIntegrator() {
    this.isSubmitted = true;
    this.isLoading = true;

    this.spinner.show();

    const userModel = new UserModel();
    const userRequest = this.userRequestDetails;

    userModel.merchant = userRequest.merchant?.value ?? '';
    userModel.title = userRequest.title?.value ?? '';
    userModel.firstName = userRequest.firstname?.value ?? '';
    userModel.lastName = userRequest.lastname?.value ?? '';
    userModel.identityNumber = userRequest.identityNumber?.value ?? 'N/A'
    userModel.contactNumber = userRequest.contactNumber?.value ?? '';
    userModel.mobile = userRequest.mobile?.value ?? '';
    userModel.email = userRequest.email?.value ?? '';
    userModel.address = userRequest.address?.value ?? '';    
    userModel.portalLink = environment.commercehubPortal;

    this.userService.createIntegrator(userModel).subscribe({
      next: (data: UserResponseModel) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();

        if (data.success) {
          Swal.fire({
            icon: "success",
            title: "Integrator Created Successfully!",
            text: data.message
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data.message
          });
        }
      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorResponse
        });
      }
    });
  }

  createSuperAdmin() {
    this.isSubmitted = true;
    this.isLoading = true;

    this.spinner.show();

    const userModel = new UserModel();
    const userRequest = this.userRequestDetails;

    userModel.userType = userRequest.userType.value;
    userModel.title = userRequest.title.value;
    userModel.firstName = userRequest.firstname.value;
    userModel.lastName = userRequest.lastname.value;
    userModel.identityNumber = userRequest.identityNumber.value;
    userModel.contactNumber = userRequest.contactNumber.value;
    userModel.mobile = userRequest.mobile.value;
    userModel.email = userRequest.email.value;
    userModel.active = true;
    userModel.userBankRequest = {
      bankName: userRequest.bankName.value,
      bankAccountNumber: userRequest.bankAccountNumber.value,
      bankAccountType: userRequest.bankAccountType.value,
      bankBranchCode: userRequest.bankBranchCode.value
    };
    userModel.accountLocked = false;
    userModel.authSettle = userRequest.authSettle.value;
    userModel.waiveUserCharge = false;
    userModel.settlementsSuspended = false;
    userModel.address = userRequest.address.value;
    userModel.portalLink = environment.commercehubPortal;

    this.userService.createUser(userModel).subscribe({
      next: (data: UserResponseModel) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();

        if (data.success) {
          Swal.fire({
            icon: "success",
            title: "User Created Successfully!",
            text: data.message
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data.message
          });
        }
      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorResponse
        });
      }
    });
  }

  clearUserForm() {
    this.userForm.setValue({
      collectionType: "",
      title: "",
      firstname: "",
      lastname: "",
      identityNumber: "",
      contactNumber: "",
      mobile: "",
      email: "",
      bankName: "",
      bankAccountNumber: "",
      bankAccountType: "",
      bankBranchCode: "",
      authSettle: ""
    });
  }

}
