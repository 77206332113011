import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	public superAdmin: boolean = false;

	public merchantAdmin: boolean = false;
	public normalUser: boolean = false;

	public enableEcommerce: boolean = false;
	public enableRecurring: boolean = false;

	public items = new BehaviorSubject<Menu[]>(this.getMenuItems());

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	getMenuItems(): Menu[] {
		return [
			...(this.superAdmin ? [
				{
					title: 'Admin',
					icon: 'users',
					type: 'sub',
					active: false,
					children: [
						{
							title: 'Chargebacks',
							type: 'sub',
							children: [
								{ path: '/admin/chargebacks', title: 'Chargeback Management', type: 'link' },
							],
						},
						{
							title: 'Merchant Management',
							type: 'sub',
							children: [
								{ path: '/admin/create-merchant', title: 'Create Merchant', type: 'link' },
								{ path: '/admin/merchant-list', title: 'Merchants List', type: 'link' },
								// { path: '/admin/update-merchant', title: 'Update Merchant', type: 'link' },
							],
						},
						// {
						// 	title: 'Billings',
						// 	type: 'sub',
						// 	children: [
						// 		{ path: '/admin/subscriptions-and-billings', title: 'Run Billing', type: 'link' },
						// 	],
						// },
						{
							title: 'Settlements',
							type: 'sub',
							children: [
								{ path: '/admin/unsettled-transactions', title: 'Unsettled Transactions', type: 'link' },
								{ path: '/admin/unsettled-ecommerce', title: 'Unsettled Ecommerce', type: 'link' },
								{ path: '/admin/daily-settlement', title: 'Daily Summary', type: 'link' },
							],
						},
						{
							title: 'Unpaids Management',
							type: 'sub',
							children: [
								{ path: '/admin/unpaids-management', title: 'Unpaids List', type: 'link' },
							],
						},
					],
				},
			] : []),
			...(this.merchantAdmin ? [
				{
					title: 'Merchant Details',
					icon: 'settings',
					type: 'sub',
					children: [
						{ path: '/settings/account', title: 'Merchant Administration', type: 'link' },
					],
				},
				{
					title: 'User Management',
					icon: 'user-plus',
					type: 'sub',
					active: false,
					children: [
						{ path: '/users/create-user', title: 'Create User', type: 'link' },
						{ path: '/users/list-user', title: 'User Administration', type: 'link' }
						// { path: '/users/user-deactivations', title: 'User Deactivations', type: 'link' },
					],
				},
			] : []),
			...(this.merchantAdmin || this.normalUser ? [
				{
					path: '/dashboard/default',
					title: 'Dashboard',
					icon: 'home',
					type: 'link',
					badgeType: 'primary',
					active: false,
				},
				{
					path: '/pages/file-templates',
					title: 'Templates',
					icon: 'clipboard',
					type: 'link',
					badgeType: 'primary',
					active: false,
				},
				{
					title: 'Transaction Processing',
					icon: 'chrome',
					type: 'sub',
					active: false,
					children: [
						...(this.enableEcommerce ? [{ path: '/transaction-processing/process-transactions', title: 'Card Transactions', type: 'link' }] : []),
						...(this.enableRecurring ? [{ path: '/transaction-processing/recurring-transactions', title: 'Recurring Transactions', type: 'link' }] : [])
					],
				},
				...(this.enableRecurring ? [{
					title: 'Debtor Management',
					icon: 'sliders',
					type: 'sub',
					active: false,
					children: [
						{ path: '/debtor/administration', title: 'Debtor Administration', type: 'link' },
					],
				}] : [])
			] : []),
			{
				title: 'Reports',
				icon: 'file-text',
				type: 'sub',
				active: false,
				children: [
					{ path: '/reports/batch-collection-schedule', title: 'Batch Collection Schedule', type: 'link' },
					{ path: '/reports/batch-collection-summary', title: 'Batch Collection Summary', type: 'link' },
					{ path: '/reports/transaction-report', title: 'E-commerce Transactions', type: 'link' },
					{ path: '/reports/recurring-transaction-report', title: 'POS Transactions', type: 'link' },
					{ path: '/reports/recurring-transaction-report', title: 'Recurring Transactions', type: 'link' },
				],
			},
		];
	}


	updateMenuItems(role: string, enableEcommerce: string, enableRecurring: string): void {
		this.superAdmin = role === 'SAD';
		this.merchantAdmin = role === 'MAD';
		this.normalUser = role === 'USR';
		this.enableEcommerce = (enableEcommerce ?? 'false') === 'true';
		this.enableRecurring = (enableRecurring ?? 'false') === 'true';

		this.items.next(this.getMenuItems());
	}
}
