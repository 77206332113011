import { MerchantModel } from "../../models/merchant/merchant.model";

export class SelectMerchantAction {
   static readonly type = '[Merchant] Select';
   constructor(public data: MerchantModel) { }
}

export class GetMerchantAction {
   static readonly type = '[Merchant] Get';
   constructor() { }
}

export class ScopeMerchantAction {
   static readonly type = '[Merchant] Has Ecommerce and/or Recurring';
   constructor(public hasEcommerce: boolean, public hasRecurring: boolean) { }
}
