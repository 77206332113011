import { AuthenticationRequestModel } from "../../models/auth/authentication-request.model";
import { AuthenticationResponseModel } from "../../models/auth/authentication-response.model";
import { MasqueradeRequestModel } from "../../models/auth/masquerade-request.model";

export class LoggedInAction {
   static readonly type = '[Auth] Logged In Successfully';
   constructor(public data: boolean) { }
}

export class LogInAction {
   static readonly type = '[Auth] Log In';
   constructor(public authRequest: AuthenticationRequestModel) { }
}

export class MasqueradingAction {
   static readonly type = '[Auth] Masquerading';
   constructor(public masquerading: boolean) { }
}

export class MasqueradeAction {
   static readonly type = '[Auth] Masquerade';
   constructor(public masqueradeRequestModel: MasqueradeRequestModel) { }
}

export class CancelMasqueradeAction {
   static readonly type = '[Auth] Cancel Masquerade';
   constructor() { }
}
