import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { config } from '../app.config';
import { BatchUploadResponse } from '../models/batch/batch-upload-response.model';

@Injectable({
  providedIn: 'root'
})
export class BatchService {

  constructor(@Inject(DOCUMENT) private document: any,
    private http: HttpClient) { }

  public uploadDebtorRecurringBatch(file: File): Observable<BatchUploadResponse> {
    const formData = new FormData();
    formData.append('file', file);

    return new Observable(subscriber => {
      this.http.post<BatchUploadResponse>(`${config.C2PAPI}/batch/recurring/debtor`, formData)
        .pipe(
          map(response => response)
        )
        .subscribe({
          next: (data: BatchUploadResponse) => {
            if (data != null) {
              subscriber.next(data);
            }
          },
          error: (errorResponse: any) => {
            subscriber.error(errorResponse);
          },
          complete: () => subscriber.complete()
        });
    });
  }
}
