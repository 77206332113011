import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, Observable } from 'rxjs';
import { BatchCollectionSummaryRequest } from 'src/app/core/models/report/batch-collection-summary-request.model';
import { BatchCollectionSummary, BatchCollectionSummaryItem } from 'src/app/core/models/report/batch-collection-summary.model';
import { RecurringService, ReportService } from 'src/app/core/services';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';

@Component({
  selector: 'app-batch-collection-summary',
  templateUrl: './batch-collection-summary.component.html',
  styleUrls: ['./batch-collection-summary.component.scss']
})
export class BatchCollectionSummaryComponent implements OnInit, OnDestroy {

  public isLoading: boolean = false;
  public searchCriteriaForm: UntypedFormGroup;
  public date: { year: number, month: number };

  public batchCollectionSummaryItems: BatchCollectionSummaryItem[] = [];
  public tableItem$: Observable<BatchCollectionSummary>;
  public batchCollectionSummaryReport$: Observable<BatchCollectionSummary[]>;
  public subscription: Subscription = new Subscription();
  total$: Observable<number>;
  public collectionBatchNumber: string;

  constructor(public service: TableService, private formBuilder: UntypedFormBuilder,
    private store: Store, private spinner: NgxSpinnerService, private _router: Router,
    private _reportService: ReportService) {
    this.createSearchCriteriaForm();

    const receivedBatchNumber = this._router.getCurrentNavigation()?.extras.state?.data;
    if (receivedBatchNumber) {
      this.collectionBatchNumber = receivedBatchNumber;
    }

    this.batchCollectionSummaryReport$ = service.tableItem$;
    this.total$ = service.total$;
  }

  createSearchCriteriaForm() {
    this.searchCriteriaForm = this.formBuilder.group({
      start_date: [''],
      end_date: [''],
      collectionBatchNumber: [''],
      debtorName: [''],
      debtorSurname: [''],
      transactionReference: [''],
      status: ['']
    })
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  get searchBatchCollectionSummary() {
    return this.searchCriteriaForm.controls;
  }

  search() {
    const searchForm = this.searchBatchCollectionSummary;
    let startDate = searchForm.start_date.value || null;
    let endDate = searchForm.end_date.value || null;
    let collectionBatchNumber = searchForm.collectionBatchNumber.value || null;
    let debtorName = searchForm.debtorName.value || null;
    let debtorSurname = searchForm.debtorSurname.value || null;
    let transactionReference = searchForm.transactionReference.value || null;
    let status = searchForm.status.value || null;

    let startDateString = startDate ?
      `${startDate.year}-${startDate.month.toString().padStart(2, '0')}-${startDate.day.toString().padStart(2, '0')}` :
      null;

    let endDateString = endDate ?
      `${endDate.year}-${endDate.month.toString().padStart(2, '0')}-${endDate.day.toString().padStart(2, '0')}` :
      null;

    let batchCollectionSummaryRequest: BatchCollectionSummaryRequest = {
      startDate: startDateString,
      endDate: endDateString,
      collectionBatchNumber: collectionBatchNumber,
      debtorName: debtorName,
      debtorSurname: debtorSurname,
      transactionReference: transactionReference,
      status: status
    }

    this.getBatchCollectionSummaryReport(batchCollectionSummaryRequest);
  }

  ngOnInit(): void {
    let batchCollectionSummaryRequest: BatchCollectionSummaryRequest = {}

    if (this.collectionBatchNumber) {
      batchCollectionSummaryRequest.collectionBatchNumber = this.collectionBatchNumber;
    }

    this.getBatchCollectionSummaryReport(batchCollectionSummaryRequest);
  }

  async getBatchCollectionSummaryReport(batchCollectionSummaryRequest: BatchCollectionSummaryRequest) {
    this.isLoading = true;
    this.spinner.show();
    this._reportService.getBatchCollectionSummaryReport(batchCollectionSummaryRequest).subscribe(data => {
      this.batchCollectionSummaryItems = data.recurringCollectionScheduleSummaryItems;
      this.isLoading = false;
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.service.reset();
    }
  }

}
