<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Merchant Name</h5>
                </div>
                <div class="card-body tab2-card">
                    <div class="btn-popup pull-right">
                        <a class="btn btn-primary">Select All (Y)</a>
                    </div>
                    <div id="transactionReport" class="category-table custom-datatable transcation-datatable">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" sortable="merchantName" (sort)="onSort($event)">
                                            Transaction Date
                                        </th>
                                        <th scope="col" sortable="lastPaid" (sort)="onSort($event)">Transaction Reference</th>
                                        <th scope="col" sortable="visaTransactions" (sort)="onSort($event)">Bank Reference
                                        </th>
                                        <th scope="col" sortable="visaFees" (sort)="onSort($event)">
                                            Transaction Amount</th>
                                        <th scope="col" sortable="mastercardTransactions" (sort)="onSort($event)">
                                            Bank Batch Number
                                        </th>
                                        <th scope="col" sortable="mastercardFees" (sort)="onSort($event)">
                                            Settlement Indicator</th>
                                        <th scope="col">Settle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of unsettledEcommerceTransactionResponses">
                                        <td>{{ item.transactionDate | date:'yyyy-MM-dd' }}</td>
                                        <td>{{ item.transactionReference }}</td>
                                        <td>{{ item.bankReference }}</td>
                                        <td>R{{ item.transactionAmount | number:'1.2-2' }}</td>
                                        <td>{{ item.bankBatchNumber || 'N/A' }}</td>
                                        <td>
                                            <span class='badge' [ngClass]="{
                                                'badge-success': item.settlementIndicator === 'Y',
                                                'badge-info': item.settlementIndicator === 'N',
                                                'badge-grey': !item.settlementIndicator
                                            }">
                                                {{ item.settlementIndicator === 'Y' ? item.settlementIndicator : (item.settlementIndicator === 'N' ? item.settlementIndicator : '-') }}
                                            </span>
                                        </td>                                                                                  
                                        <td *ngIf="item.settlementIndicator !== null">
                                            <input type="checkbox" name="settle" (change)="onSelect(item.bankReference)">
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="3" style="text-align: center;"><strong>Total:</strong></td>
                                        <td><strong>R{{ unsettledEcommerceSummaryModel.transactionAmount | number:'1.2-2' }}</strong></td>
                                        <td colspan="3"></td>
                                    </tr>
                                </tfoot>
                            </table>
                            <div class="d-flex justify-content-center p-2">
                                <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                    [pageSize]="service.pageSize" [maxSize]="10">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="btn-popup pull-right">
                        <a class="btn btn-primary" (click)="settleMerchant()">Settle Merchant</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->