import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { DebtorSummaryTransactionParameters } from 'src/app/core/models/common/request-parameters.model';
import { DebtorSummaryItemModel } from 'src/app/core/models/recurring/debtor-summary-item.model';
import { DebtorSummaryTransactionsModel } from 'src/app/core/models/recurring/debtor-summary-transactions.model';
import { InitiateRecurringTransactionRequestModel } from 'src/app/core/models/recurring/initiate-recurring-transaction-request.model';
import { InitiateRecurringTransactionResponseModel } from 'src/app/core/models/recurring/initiate-recurring-transaction-response.model';
import { RecurringCardTransactionRequestModel } from 'src/app/core/models/recurring/recurring-card-transaction-request.model';
import { RecurringCardTransactionResponseModel } from 'src/app/core/models/recurring/recurring-card-transaction-response.model';
import { BatchService, RecurringService } from 'src/app/core/services';
import { InvoiceDB } from 'src/app/shared/tables/invoice';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recurring-transactions',
  templateUrl: './recurring-transactions.component.html',
  styleUrls: ['./recurring-transactions.component.scss']
})
export class RecurringTransactionsComponent implements OnInit, OnDestroy {

  public recurringCardTransactionForm: UntypedFormGroup;
  public restrictionForm: UntypedFormGroup;
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public modelFooter: NgbDateStruct;
  public active = 1;

  public tableItem$: Observable<InvoiceDB[]>;
  public debtorSummaryTransactions: DebtorSummaryTransactionsModel;
  public debtorSummaries$: Observable<DebtorSummaryItemModel[]>;
  public debtorAdminSummaries: DebtorSummaryItemModel[] = [];
  public subscription: Subscription = new Subscription();
  public searchText;
  total$: Observable<number>;

  public isLoading: boolean = false;
  public isSubmitted: boolean = false;
  public requestIdentifier: string;
  public selectedCollectionFrequency: string = 'Monthly';

  files: File[] = [];
  selectedFile: File | null = null;

  constructor(private formBuilder: UntypedFormBuilder, private _router: Router,
    private store: Store, public recurringService: RecurringService, private spinner: NgxSpinnerService,
    private batchService: BatchService) {
    this.subscription = new Subscription();

    this.initiateRecurringTransactionForm();
    this.createRestrictionForm();
  }

  ngOnInit(): void {
    //this.reloadCurrentRoute();
  }

  reloadCurrentRoute() {
    let currentUrl = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });
  }

  initiateRecurringTransactionForm() {
    this.recurringCardTransactionForm = this.formBuilder.group({
      debtorName: [''],
      debtorSurname: [''],
      debtorContactNumber: [''],
      debtorEmailAddress: [''],
      debtorIdentifier: [''],
      collectionType: [''],
      collectionFrequency: [''],
      instalmentDay: [''],
      instalmentAmount: [''],
      instalmentCount: [''],
      collectionAutoRerun: [''],
      dynamicPayday: [''],
      earlyDecemberCollection: [''],
    });
  }

  get recurringCardTx() {
    return this.recurringCardTransactionForm.controls;
  }

  createRestrictionForm() {
    this.restrictionForm = this.formBuilder.group({
      products: [''],
      category: [''],
      min: [''],
      max: ['']
    })
  }

  initiateRecurringTransaction() {
    this.isSubmitted = true;
    this.isLoading = true;

    this.spinner.show();

    const initiateRecurringTransactionRequestModel = new InitiateRecurringTransactionRequestModel();
    const recurringCardTx = this.recurringCardTx;

    initiateRecurringTransactionRequestModel.debtorName = recurringCardTx.debtorName.value;
    initiateRecurringTransactionRequestModel.debtorSurname = recurringCardTx.debtorSurname.value;
    initiateRecurringTransactionRequestModel.debtorContactNumber = "" + recurringCardTx.debtorContactNumber.value;
    initiateRecurringTransactionRequestModel.debtorEmailAddress = recurringCardTx.debtorEmailAddress.value;
    initiateRecurringTransactionRequestModel.debtorIdentifier = recurringCardTx.debtorIdentifier.value;
    initiateRecurringTransactionRequestModel.collectionType = recurringCardTx.collectionType.value;
    initiateRecurringTransactionRequestModel.collectionFrequency = recurringCardTx.collectionFrequency.value;
    initiateRecurringTransactionRequestModel.instalmentDay = parseInt(recurringCardTx.instalmentDay.value);
    initiateRecurringTransactionRequestModel.instalmentAmount = parseFloat(recurringCardTx.instalmentAmount.value);
    initiateRecurringTransactionRequestModel.instalmentCount = parseInt(recurringCardTx.instalmentCount.value);
    initiateRecurringTransactionRequestModel.collectionAutoRerun = recurringCardTx.collectionAutoRerun.value || false;
    initiateRecurringTransactionRequestModel.allowDynamicPayday = recurringCardTx.dynamicPayday.value || false;
    initiateRecurringTransactionRequestModel.earlyDecemberCollection = recurringCardTx.earlyDecemberCollection.value || false;;
    initiateRecurringTransactionRequestModel.paymentLinkBaseUrl = environment.portal;

    this.recurringService.initiateRecurringTransaction(initiateRecurringTransactionRequestModel).subscribe({
      next: (data: InitiateRecurringTransactionResponseModel) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.requestIdentifier = data.requestIdentifier;
        this.clearRecurringForm();
        this.spinner.hide();
        Swal.fire({
          icon: "success",
          title: "A Recurring Transaction has been initiated!",
          text: "An email has been sent to the customer."
        });
      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorResponse
        });
      }
    });
  }

  clearRecurringForm() {
    this.recurringCardTransactionForm.setValue({
      debtorName: "",
      debtorSurname: "",
      debtorContactNumber: "",
      debtorEmailAddress: "",
      debtorIdentifier: "",
      collectionType: "",
      collectionFrequency: "",
      instalmentDay: "",
      instalmentAmount: "",
      instalmentCount: "",
      collectionAutoRerun: "",
      dynamicPayday: "",
      earlyDecemberCollection: ""
    });
  }

  processRecurringTransaction(requestIdentifier: string) {
    let recurringCardTransactionRequestModel: RecurringCardTransactionRequestModel = {
      requestIdentifier: requestIdentifier,
      amount: 100
    }

    this.recurringService.processRecurringTransaction(recurringCardTransactionRequestModel).subscribe({
      next: (data: RecurringCardTransactionResponseModel) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
        Swal.fire({
          icon: "success",
          title: "Transaction Successful!",
          text: "Request successfully processed by Click2Pay Payment Engine."
        });
      },
      error: (errorResponse: any) => {
        this.isLoading = false;
        this.isSubmitted = false;
        this.spinner.hide();
        Swal.fire({
          icon: "error",
          title: "Transaction Failed!",
          text: errorResponse
        });
      }
    })
  }

  // Handle file selection from the ngx-dropzone
  onSelect(event: any): void {
    this.files.push(...event.addedFiles); // Add files to the list
    this.selectedFile = this.files[0]; // Pick the first file as the selected file
  }

  // Handle file removal
  onRemove(file: File): void {
    this.files = this.files.filter(f => f !== file); // Remove the file from the list
    if (this.selectedFile === file) {
      this.selectedFile = null; // Clear selected file if it is removed
    }
  }

  // Upload the selected file
  uploadFile(): void {
    if (!this.selectedFile) {
      console.error('No file selected!');
      return;
    }

    this.batchService.uploadDebtorRecurringBatch(this.selectedFile).subscribe({
      next: (response) => {
        console.log('Upload successful:', response);
        alert('File uploaded successfully!');
        this.files = []; // Clear files after successful upload
        this.selectedFile = null;
      },
      error: (err) => {
        console.error('Upload failed:', err);
        alert('File upload failed. Please try again.');
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
