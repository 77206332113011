import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { MerchantModel } from 'src/app/core/models/merchant/merchant.model';
import { GetMerchantAction } from 'src/app/core/store/actions/merchant.action';
import { MerchantState } from 'src/app/core/store/state/merchant.state';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  public subscription: Subscription = new Subscription();

  public merchant: MerchantModel;
  public active = 1;

  @Select(MerchantState.selectedMerchant) selectedMerchant$: Observable<MerchantModel>;
  constructor(private store: Store) {
    this.subscription.add(this.subscription = this.selectedMerchant$.subscribe(data => {
      this.merchant = data;
    }));
  }

  ngOnInit() { }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  dayOrdinal(d) {
    if (d > 3 && d < 21) return d + 'th'; 
    switch (d % 10) {
      case 1:  return d + "st";
      case 2:  return d + "nd";
      case 3:  return d + "rd";
      default: return d + "th";
    }
  }

}
