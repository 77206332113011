<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Recon Settlements Report</h5>
                </div>
                <div class="card-body tab2-card">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>Search Criteria</a>
                            <ng-template ngbNavContent>
                                <form [formGroup]="searchCriteriaForm" class="needs-validation user-add" novalidate="">
                                    <div class="permission-block">
                                        <div class="attribute-blocks">
                                            <h5 class="f-w-600 mb-3">Date Range</h5>
                                            <div class="row mb-3">
                                                <div class="col-xl-4 col-sm-6">
                                                    <div class="input-group input-grp-p">
                                                        <input class="datepicker-here form-control digits" ngbDatepicker
                                                            formControlName="start_date" #f="ngbDatepicker">
                                                        <div class="input-group-append">
                                                            <button class="btn calendar" (click)="f.toggle()"
                                                                type="button"><i class="fa fa-calendar"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-sm-6">
                                                    <div class="input-group input-grp-p">
                                                        <input class="datepicker-here form-control digits" ngbDatepicker
                                                            formControlName="end_date" #c="ngbDatepicker">
                                                        <div class="input-group-append">
                                                            <button class="btn calendar" (click)="c.toggle()"
                                                                type="button"><i class="fa fa-calendar"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="pull-right">
                                    <button type="button" class="btn btn-primary" (click)="search()">Search</button>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>Report</a>
                            <ng-template ngbNavContent>
                                <div class="btn-popup pull-right">
                                    <a class="btn btn-primary">Refresh</a>
                                </div>
                                <div id="transactionReport" class="category-table custom-datatable transcation-datatable">
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col" sortable="reconDate" (sort)="onSort($event)">
                                                        Date
                                                    </th>
                                                    <th scope="col" sortable="totalAmount" (sort)="onSort($event)">Total Amount</th>
                                                    <th scope="col" sortable="totalPayable" (sort)="onSort($event)">Total Payable</th>
                                                    <th scope="col" sortable="totalDepositedByBank" (sort)="onSort($event)">
                                                        Total Deposited by Bank</th>
                                                    <th scope="col" sortable="totalGrossMargin" (sort)="onSort($event)">TOtal Gross Margin</th>
                                                    <th scope="col" sortable="totalNett"
                                                        (sort)="onSort($event)">Total Nett</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>2024-05-03</td>
                                                    <td>R0.00</td>
                                                    <td>R0.00</td>
                                                    <td>R0.00</td>
                                                    <td>R0.00</td>
                                                    <td>R0.00</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="d-flex justify-content-center p-2">
                                            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                                                [pageSize]="service.pageSize" [maxSize]="10">
                                            </ngb-pagination>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->